import React from 'react'
import DummyHeader from '../DummyHeader'

const FirstSection = ({ handleNextClick }: any) => {
  return (
    <>
      <DummyHeader />
      <div className="section section-custom-main">
        <div className="container-main">
          <div className="div-main">
            <div className="div-sub">
              <h1 className="div-text">
                Craft your unique organization with edeXa's Seamless
                Private Blockchain Builder – Where Creating is as Easy
                as a Click!
              </h1>
              <p className="div-desc">
                Expand rapidly, scale efficiently, and ensure
                steadfast security.
              </p>
              <button
                className="div-button"
                onClick={handleNextClick}
              >
                Create with edeXa Wizard
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="div-footer">
        <button
          className="first-footer-button"
          onClick={() =>
            window.open(
              'https://developer.edexa.network/docs/category/plugins--lego-dapps'
            )
          }
        >
          Start with SDKs /APis Documentation
        </button>
      </div>
    </>
  )
}

export default FirstSection
