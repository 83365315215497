import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootReducer } from "store/root-reducer";

const useComingSoonHook = () => {
  const navigate = useNavigate();
  const globalSetting = useSelector(
    (state: IRootReducer) => state.GlobalSettingReducer
  );

  useEffect(() => {
    if (globalSetting.comingSoon) {
      navigate("/");
    } // eslint-disable-next-line
  }, [globalSetting.comingSoon]);

  return null;
};

export default useComingSoonHook;
