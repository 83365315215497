import ConnectToChain from "components/dashboard/ConnectToChain";
import Documentation from "components/dashboard/Documentation";
import { TitleComponent } from "components/titleComponent";
import useComingSoonHook from "hooks/UseComingSoonHook";
import { Col, Row } from "reactstrap";
import "../../../styles/PeerNode/peerNode.scss";
import CloudConfigurationsListComponent from "../cloudConfigurations/CloudConfigurationsListComponent";
const CloudConfiguration = () => {
  useComingSoonHook();
  return (
    <div className="peer-node-overview-wrapper">
      {" "}
      <Row>
        <Col md="12" lg="12" xl="12" xxl="12">
          <TitleComponent title="Cloud Configurations" />
        </Col>
      </Row>
      <Row className="mb-20">
        <CloudConfigurationsListComponent />
        <Col md="12" lg="12" xl="4" xxl="4">
          <Row>
            <Col md="6" lg="6" xl="12" xxl="12">
              <ConnectToChain />
            </Col>
            <Col md="6" lg="6" xl="12" xxl="12">
              <Documentation />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default CloudConfiguration;
