/* eslint-disable react-hooks/exhaustive-deps */
import { getCPUUsageGraphApiInfo } from "actions/universeActions/myBlockChain/blockProcessAPI";
// import { HighchartsReact } from "highcharts-react-official";
// import Highcharts from "highcharts/highstock";
import useComingSoonHook from "hooks/UseComingSoonHook";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import "../../../../src/styles/pages/myblockchain/myblockchain.scss";
import "../../../styles/PeerNode/peerNodeChart.scss";
import { timingForGraphArray } from "../../../utils/constants";

const CPUUsage = () => {
  const currentDateInMilliseconds = Date.now();
  const [profile, setProfile] = useState<any>([]);
  const [selectPastTimeFilter, setSelectPastTimeFilter] = useState<any>(
    timingForGraphArray?.length > 0 && timingForGraphArray?.[0]
  );
  const [storeSelectedTimeAndItsValue, setStoreSelectedTimeAndItsValue] =
    useState<any>({});
  const [cpuUsageGraphData, setCPUUsageGraphData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const options = useSelector(
    (state: IRootReducer) => state.peerReducer.searchedPeerListForDropdown
  );

  const handleCpuUsageGraph = (timestamp: any, peerId: any) => {
    setIsLoading(true);
    getCPUUsageGraphApiInfo(timestamp, peerId.value)
      .then((response: any) => {
        setCPUUsageGraphData(response?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  const sendSelectPastTimeFilterToMyBlockChain = (
    milliSecondsAsPerSelection: number,
    valueAsPerTimeSelection: number
  ) => {
    setStoreSelectedTimeAndItsValue((prevState: any) => {
      const newState = {
        milliSecondsAsPerSelection,
        valueAsPerTimeSelection,
        startTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000,
      };

      handleCpuUsageGraph(newState, profile);

      return newState;
    });
  };

  const callFunctionTosendSelectPastTimeFilterToMyBlockChain = (
    millisecondsOfSelectedTime: any
  ) => {
    let convertToNumberMillisecondsOfSelectedTime = parseInt(
      millisecondsOfSelectedTime
    );

    if (millisecondsOfSelectedTime === 300000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 900000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 1800000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        7
      );
    } else if (millisecondsOfSelectedTime === 3600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        14
      );
    } else if (millisecondsOfSelectedTime === 7200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        28
      );
    } else if (millisecondsOfSelectedTime === 21600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        86
      );
    } else if (millisecondsOfSelectedTime === 43200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        172
      );
    } else if (millisecondsOfSelectedTime === 86400000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        345
      );
    }
  };

  const uniquePeerNames = Array.from(
    new Set(
      cpuUsageGraphData
        .map((obj: any) =>
          obj.values
            .filter((data: any) => data)
            .map((name: any) => name.peerName)
        )
        .flat()
    )
  );

  const maxValues =
    cpuUsageGraphData.flatMap((channel: any) =>
      channel?.values?.map((time: any) => new Date(time?.timestamp * 1000))
    )?.length || 0;

  const optionsData = {
    time: {
      useUTC: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    subtitle: {
      text:
        cpuUsageGraphData?.length > 0
          ? getSubtitleTextTime(cpuUsageGraphData)
          : null,
      align: "right",
      y: 10,
      style: {
        fontSize: "12px",
      },
    },
    chart: {
      zoomType: "x",
    },
    xAxis: {
      type: "datetime",
      min: cpuUsageGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[0],
      max: cpuUsageGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[maxValues - 1],
      dateTimeLabelFormats: {
        millisecond: "%H:%M:%S",
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e of %b",
      },
      title: {
        text: "Timestamp",
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 4,
          },
        },
        marker: {
          enabled: false,
        },
      },
      series: {
        pointStart: cpuUsageGraphData.flatMap((channel: any) =>
          channel?.values?.map((time: any) => time?.timestamp * 1000)
        )?.[0],
        pointInterval: 14000,
      },
    },
    // tooltip: {
    //   formatter: function (this: Highcharts.TooltipFormatterContextObject) {
    //     const dataIndex = this?.point.index;
    //     const value: any = this?.y;
    //     const timestamp = new Date(this?.point?.x).toLocaleString() || "Unknown Time Stamp";
    //     const peerName = cpuUsageGraphData.flatMap((channel: any) => channel?.values?.map((name: any) => name?.peerName))?.[dataIndex] || "Unknown Peer Name";

    //     let tooltipContent = `<b>Value:</b> ${value}<br>`;
    //     tooltipContent += `<b>Peer Name:</b> ${peerName}<br>`;
    //     tooltipContent += `<b>Timestamp:</b> ${timestamp}<br>`;

    //     return tooltipContent;
    //   }
    // },
    series: uniquePeerNames.map((peerName) => ({
      type: "line",
      name: peerName,
      data:
        cpuUsageGraphData.length > 0
          ? cpuUsageGraphData
              .flatMap((data: any) =>
                data?.values?.filter((peer: any) => peer?.peerName === peerName)
              )
              .map((val: any) => parseInt(val?.value))
          : null,
    })),
    navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };

  function getSubtitleTextTime(data: any) {
    const uniqueDates = Array.from(
      new Set(
        data.map((obj: any) => moment(obj.key * 1000).format("DD/MM/YYYY"))
      )
    );
    if (uniqueDates.length === 2) {
      return `Dates: ${uniqueDates[0]} and ${uniqueDates[1]}`;
    } else if (uniqueDates.length === 1) {
      return `Date: ${uniqueDates[0]}`;
    } else {
      return "Unknown Date";
    }
  }

  useEffect(() => {
    handleCpuUsageGraph(storeSelectedTimeAndItsValue, profile);
  }, [profile]);

  useEffect(() => {
    setProfile({ label: options[0].label });
  }, [options]);

  useComingSoonHook();
  return (
    <>
      <div
        className="backdrop-block"
        style={{ minHeight: "495px", maxHeight: "495px" }}
      >
        <Row>
          <div className="d-flex">
            <Col md="2">
              <div className="title">
                <h6 className="c-tx-primary f-600 mb-10">
                  CPU Usage &nbsp; &nbsp;
                  {isLoading && (
                    <div className="loader-center">
                      <Spinner
                        size={"sm"}
                        style={{
                          marginTop: "35%",
                          marginLeft: "50%",
                        }}
                      />
                    </div>
                  )}
                </h6>
              </div>
            </Col>
            <Col md="2">
              <div className="title extra-css">
                <Select
                  className="peer-drop-down"
                  options={timingForGraphArray}
                  value={selectPastTimeFilter}
                  onChange={(e: any) => {
                    setIsLoading(true);
                    setSelectPastTimeFilter(e);
                    callFunctionTosendSelectPastTimeFilterToMyBlockChain(
                      e.value
                    );
                  }}
                />
              </div>
            </Col>
            <Col md="3" className="ml-10">
              <FormGroup className="position-relative">
                <Select
                  className="peer-drop-down"
                  options={options}
                  value={profile}
                  onChange={(value) => {
                    setIsLoading(true);
                    setProfile(value);
                    callFunctionTosendSelectPastTimeFilterToMyBlockChain(
                      selectPastTimeFilter?.value
                    );
                    // handleCpuUsageGraph(selectPastTimeFilter, value);
                  }}
                />
              </FormGroup>
            </Col>
          </div>
        </Row>

        {cpuUsageGraphData.length > 0 && !isLoading ? (
          <Row>
            <Col
              md="12"
              style={{
                visibility: `${isLoading ? "hidden" : "visible"}`,
              }}
            >
              <div className="hide-highcharts-tag">
                {/* <HighchartsReact highcharts={Highcharts} options={optionsData} /> */}
              </div>
            </Col>
          </Row>
        ) : (
          cpuUsageGraphData.length === 0 &&
          !isLoading && (
            <p
              style={{
                marginTop: "20%",
                marginLeft: "40%",
              }}
            >
              NO DATA FOUND
            </p>
          )
        )}
      </div>
    </>
  );
};

export default CPUUsage;
