import { useEffect, FC } from "react";

interface IMetaFunction {
  meta: string;
}

const MetaFunction: FC<IMetaFunction> = ({ meta }) => {
  useEffect(() => {
    document.title = `edeXa Accounts | ${meta}`;
  }, [meta]);

  return <></>;
};
export default MetaFunction;
