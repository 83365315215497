import HomeRequest from 'components/dashboard/HomeRequest'
import TemmplateAndContracts from 'components/templateAndContacts/Template'
import Login from 'pages/auth/Login'
import Apidetails from 'pages/main/apiDetails/ApiDetails'
import { APIExplorer } from 'pages/main/apiExplore'
import { ApiList } from 'pages/main/apiList'
import { ApplicationList } from 'pages/main/applicationList'
import ChannelManagement from 'pages/main/channelManagement/ChannelManagement'
import Chat from 'pages/main/chat/chat'
import ComingSoon from 'pages/main/comingSoon/ComingSoon'
import { ContractWizard } from 'pages/main/contractWizard'
import Dashboard from 'pages/main/dashboard/Dashboard'
import EdexaUniverse from 'pages/main/edexaUniverse/EdexaUniverse'
import { FolderDetailsComponent } from 'pages/main/folderDetails'
import KycDetails from 'pages/main/kycDetails/KycDetails'
import KycListing from 'pages/main/kycListing/KycListing'
import { Logs } from 'pages/main/logs'
import MetricsMonitoring from 'pages/main/metricsMonitoring/MetricsMonitoring'
import { MyBlockChain } from 'pages/main/myBlockChain'
import NotificationPage from 'pages/main/notification/Notification'
import CloudConfiguration from 'pages/main/peerNode/CloudConfiguration'
import PeerNodeOverview from 'pages/main/peerNode/PeerNodeOverview'
import Setting from 'pages/main/peerNode/Setting'
import { Unauthorize } from 'pages/main/unauthorize'
import UnderMaintenance from 'pages/main/underMaintenance/UnderMaintenance'
import UserManagement from 'pages/main/userManagement/UserManagement'
import Webhooks from 'pages/main/webhooks/webhooks'
import WebhooksCreate from 'pages/main/webhooks/webhooksCreate'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import DashboardOutletContainer from './DashboardOutletContainer'
import { DummyCode } from 'pages/main/dummy'

export const protectedRouteV6: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        path: 'dialog',
        element: <DummyCode />
      },
      {
        path: 'apis',
        element: <ApiList />
      },
      {
        path: 'apis/apidetails/:id',
        element: <Apidetails />
      },
      {
        path: 'chat',
        element: <Chat />
      },
      {
        path: 'webhooks',
        element: <Webhooks />
      },
      {
        path: 'webhooks/create',
        element: <WebhooksCreate />
      },
      {
        path: '/application',
        element: <ApplicationList />
      },
      {
        path: 'apis/explorer/:id',
        element: <APIExplorer />
      },
      {
        path: 'user-management',
        element: <UserManagement />
      },
      {
        path: 'kyc-list',
        element: <KycListing />
      },
      {
        path: 'kyc-list/:id',
        element: <KycDetails />
      },
      {
        path: 'unauthorized',
        element: <Unauthorize />
      },
      {
        path: 'contract-wizard',
        element: <ContractWizard />
      },
      {
        path: 'notifications',
        element: <NotificationPage />
      },

      {
        path: 'coming-soon',
        element: <ComingSoon />
      },
      {
        path: 'universe',
        element: <DashboardOutletContainer />,
        children: [
          { index: true, element: <Dashboard /> },
          { path: 'dashboard', element: <Dashboard /> },

          {
            path: 'homerequest',
            element: <HomeRequest />
          },
          {
            path: 'channel-management',
            element: <ChannelManagement />
          },
          {
            path: 'template-contact',
            element: <Outlet />,
            children: [
              { index: true, element: <TemmplateAndContracts /> },
              {
                path: ':urlName',
                element: <FolderDetailsComponent />
              }
            ]
          },
          {
            path: 'blockchain',
            element: <MyBlockChain />
          },
          {
            path: 'overview',
            element: <PeerNodeOverview />
          },
          {
            path: 'metrics-monitoring',
            element: <MetricsMonitoring />
          },
          {
            path: 'cloud-configurations',
            element: <CloudConfiguration />
          },
          {
            path: 'setting',
            element: <Setting />
          },
          {
            path: 'logs',
            element: <Logs />
          },
          {
            path: 'notifications',
            element: <NotificationPage />
          }
        ]
      },

      { path: '*', element: <Navigate to={'/universe/dashboard'} /> }
    ]
  }
]

export const ComingRouteV6: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <EdexaUniverse />
      },
      {
        path: 'apis',
        element: <ApiList />
      },
      {
        path: 'apis/apidetails/:id',
        element: <Apidetails />
      },
      {
        path: '/application',
        element: <ApplicationList />
      },
      {
        path: 'apis/explorer/:id',
        element: <APIExplorer />
      },
      {
        path: 'user-management',
        element: <UserManagement />
      },
      {
        path: 'kyc-list',
        element: <KycListing />
      },
      {
        path: 'kyc-list/:id',
        element: <KycDetails />
      },
      {
        path: 'unauthorized',
        element: <Unauthorize />
      },
      {
        path: 'contract-wizard',
        element: <ContractWizard />
      },
      {
        path: 'notifications',
        element: <NotificationPage />
      },

      {
        path: 'coming-soon',
        element: <ComingSoon />
      },
      { path: '*', element: <Navigate to={'/'} /> }
    ]
  }
]
//   {
//     path: "/",
//     element: <Outlet />,
//     children: [
//       { index: true, element: <EdexaUniverse /> },
//       {
//         path: "edexauniverse",
//         element: <EdexaUniverse />
//       },
//       {
//         path: "notifications",
//         element: <NotificationPage />
//       },
//       {
//         path: "apis",
//         element: <ApiList />
//       },
//       {
//         path: "apis/apidetails/:id",
//         element: <Apidetails />
//       },
//       {
//         path: "/application",
//         element: <ApplicationList />
//       },
//       {
//         path: "apis/explorer/:id",
//         element: <APIExplorer />
//       },
//       {
//         path: "user-management",
//         element: <UserManagement />
//       },
//       {
//         path: "pricing-plans",
//         element: <PricingPlans />
//       },
//       {
//         path: "request-status",
//         element: <UniverseRequestStatus />
//       },
//       {
//         path: "kyc-list",
//         element: <KycListing />
//       },
//       {
//         path: "kyc-list/:id",
//         element: <KycDetails />
//       },
//       {
//         path: "unauthorized",
//         element: <Unauthorize />
//       },
//       {
//         path: "contract-wizard",
//         element: <ContractWizard />
//       },
//       { path: "*", element: <Navigate to={"/"} /> }
//     ]
//   }
// ];

export const publicRoutesV6: RouteObject[] = [
  {
    path: '/demo',
    element: <Outlet />,
    children: [
      { index: true, element: <Login /> },
      {
        path: 'demo',
        element: <Login />
      },
      {
        path: 'unauthorized',
        element: <Unauthorize />
      },
      { path: '*', element: <Navigate to={'/demo'} /> }
    ]
  }
]

export const underMaintanceRoutesV6: RouteObject[] = [
  {
    path: '/under-maintenance',
    element: <UnderMaintenance />
  },
  {
    path: '*',
    element: <Navigate to="/under-maintenance" />
  }
]
