export const data = {
  myApplications: [
    {
      features: [
        'Poll statistics',
        'Manage Voters, Candidates and Polls'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Poll statistics',
        'Manage Voters, Candidates and Polls'
      ],
      id: 32,
      appId: 'KEsyhg3hgpDZUEp1LUd7IoPn6',
      name: 'bVote',
      app_url: '',
      ios_url: 'https://apps.apple.com/us/app/bvote/id1552682465',
      description:
        'Through the bvote voters can vote to the candidates and can view the results of the poll.',
      shortDescription:
        'Through the bvote voters can vote to the candidates and can view the results of the poll.',
      subtitle: 'bVote subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-logo.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-08-03T08:33:18.806Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-icon.png',
      redirect_url: 'https://bvote.io-world.com/',
      order: 7,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Can store your files and documents store securely with encryption using block chain',
        'Can create a categories and store files and documents accordingly with categories',
        'Can share files to another with expiration details and as well with never expiration manner',
        'Can view the files and document at application without download it.'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['bShare', 'bSign', 'bArchive'],
      id: 11,
      appId: 'C1nNrE3OrCyS8HjIfzSyhae8W',
      name: 'banji',
      app_url:
        'https://play.google.com/store/apps/details?id=com.edexa.banjiapp',
      ios_url: 'https://apps.apple.com/us/app/banji/id1530775896',
      description:
        'Banji application is majorly used to share or store the files in a secure way. The files which can be shared / Stored are Images, Videos, audios, document files.',
      shortDescription:
        'Banji application is majorly used to share or store the files in a secure way. The files which can be shared / Stored are Images, Videos, audios, document files.',
      subtitle: '',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '1',
      type: 'app',
      createdAt: '2021-03-24T06:27:05.622Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://banjiweb.io-world.com/',
      order: 4,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain ',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Secure file upload with blockchain ',
        'validate file using blockchain'
      ],
      id: 40,
      appId: 'R5kg9gW1Dz2wTWWrzyI7GR2po',
      name: 'bStamp',
      app_url: '',
      ios_url: '',
      description:
        "bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently.  Digital data can be securely time stamped using the attestation of its hash value in blockchain transactions.  The hash value 'uniquely' identifies the document (as if it were its digital fingerprint), the transaction extends its native blockchain timestamping to the included hash value, therefore proving its existence at a given point in time.",
      shortDescription: 'bStamp',
      subtitle: 'bStamp',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-09-06T09:15:52.270Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp-icon.svg',
      redirect_url: 'https://bstampweb.io-world.com/account',
      order: 3,
      desktop_url:
        'https://www.microsoft.com/en-us/p/bstamp/9p2tc01tvxht?activetab=pivot:overviewtab#',
      extension_url:
        'https://chrome.google.com/webstore/detail/bstamp/ldgcfheomoleahgpkkiikckmhaafccpd',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      id: 1,
      appId: 'LRvwiGy61GqL5Tqayt3OEFNWx',
      name: 'bMessage',
      app_url:
        'https://play.google.com/store/apps/details?id=com.edexa.bmessage',
      ios_url: 'https://apps.apple.com/us/app/bmessage/id1577165319',
      description:
        'bMessage provides a platform to send self destructive messages, files very securely with end 2 end encryption.',
      shortDescription: 'Bmessage short description',
      subtitle: 'bMessage subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-logo.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-01-27T11:57:28.001Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-icon.png',
      redirect_url: 'https://bmessage.io-world.com/',
      order: 5,
      desktop_url: 'https://bmessage.edexa.com/',
      extension_url: '',
      document_url: null
    },
    {
      features: ['Proof of ownership', 'Resale Royalties'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['Proof of ownership', 'Resale Royalties'],
      id: 43,
      appId: 'BeFLKkwgxv5uscEcIVKVkhDJn',
      name: 'bNFT',
      app_url: 'https://nft.io.world.com/',
      ios_url: 'https://nft.io.world.com/',
      description:
        "At edeXa NFT, we're excited about a brand new type of digital good called a non-fungible token, or NFT. NFTs have exciting new properties: they’re unique, provably scarce, tradeable, and usable across multiple applications. Just like physical goods, you can do whatever you want with them! You could throw them in the trash, gift them to a friend across the world, or go sell them on an open marketplace.",
      shortDescription: 'NFT',
      subtitle: 'NFT',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-12-02T10:43:04.342Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      redirect_url: 'https://nft.io-world.com/create-nft',
      order: 2,
      desktop_url: '',
      extension_url: '',
      document_url: null
    }
  ],
  totalApplications: [
    {
      features: ['Proof of ownership', 'Resale Royalties'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['Proof of ownership', 'Resale Royalties'],
      id: 43,
      appId: 'BeFLKkwgxv5uscEcIVKVkhDJn',
      name: 'bNFT',
      app_url: 'https://nft.io.world.com/',
      ios_url: 'https://nft.io.world.com/',
      description:
        "At edeXa NFT, we're excited about a brand new type of digital good called a non-fungible token, or NFT. NFTs have exciting new properties: they’re unique, provably scarce, tradeable, and usable across multiple applications. Just like physical goods, you can do whatever you want with them! You could throw them in the trash, gift them to a friend across the world, or go sell them on an open marketplace.",
      shortDescription: 'NFT',
      subtitle: 'NFT',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-12-02T10:43:04.342Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      redirect_url: 'https://nft.io-world.com/create-nft',
      order: 2,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain ',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Secure file upload with blockchain ',
        'validate file using blockchain'
      ],
      id: 40,
      appId: 'R5kg9gW1Dz2wTWWrzyI7GR2po',
      name: 'bStamp',
      app_url: '',
      ios_url: '',
      description:
        "bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently.  Digital data can be securely time stamped using the attestation of its hash value in blockchain transactions.  The hash value 'uniquely' identifies the document (as if it were its digital fingerprint), the transaction extends its native blockchain timestamping to the included hash value, therefore proving its existence at a given point in time.",
      shortDescription: 'bStamp',
      subtitle: 'bStamp',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-09-06T09:15:52.270Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp-icon.svg',
      redirect_url: 'https://bstampweb.io-world.com/account',
      order: 3,
      desktop_url:
        'https://www.microsoft.com/en-us/p/bstamp/9p2tc01tvxht?activetab=pivot:overviewtab#',
      extension_url:
        'https://chrome.google.com/webstore/detail/bstamp/ldgcfheomoleahgpkkiikckmhaafccpd',
      document_url: null
    },
    {
      features: [
        'Can store your files and documents store securely with encryption using block chain',
        'Can create a categories and store files and documents accordingly with categories',
        'Can share files to another with expiration details and as well with never expiration manner',
        'Can view the files and document at application without download it.'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['bShare', 'bSign', 'bArchive'],
      id: 11,
      appId: 'C1nNrE3OrCyS8HjIfzSyhae8W',
      name: 'banji',
      app_url:
        'https://play.google.com/store/apps/details?id=com.edexa.banjiapp',
      ios_url: 'https://apps.apple.com/us/app/banji/id1530775896',
      description:
        'Banji application is majorly used to share or store the files in a secure way. The files which can be shared / Stored are Images, Videos, audios, document files.',
      shortDescription:
        'Banji application is majorly used to share or store the files in a secure way. The files which can be shared / Stored are Images, Videos, audios, document files.',
      subtitle: '',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '1',
      type: 'app',
      createdAt: '2021-03-24T06:27:05.622Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://banjiweb.io-world.com/',
      order: 4,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      id: 1,
      appId: 'LRvwiGy61GqL5Tqayt3OEFNWx',
      name: 'bMessage',
      app_url:
        'https://play.google.com/store/apps/details?id=com.edexa.bmessage',
      ios_url: 'https://apps.apple.com/us/app/bmessage/id1577165319',
      description:
        'bMessage provides a platform to send self destructive messages, files very securely with end 2 end encryption.',
      shortDescription: 'Bmessage short description',
      subtitle: 'bMessage subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-logo.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-01-27T11:57:28.001Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-icon.png',
      redirect_url: 'https://bmessage.io-world.com/',
      order: 5,
      desktop_url: 'https://bmessage.edexa.com/',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Poll statistics',
        'Manage Voters, Candidates and Polls'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Poll statistics',
        'Manage Voters, Candidates and Polls'
      ],
      id: 32,
      appId: 'KEsyhg3hgpDZUEp1LUd7IoPn6',
      name: 'bVote',
      app_url: '',
      ios_url: 'https://apps.apple.com/us/app/bvote/id1552682465',
      description:
        'Through the bvote voters can vote to the candidates and can view the results of the poll.',
      shortDescription:
        'Through the bvote voters can vote to the candidates and can view the results of the poll.',
      subtitle: 'bVote subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-logo.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-08-03T08:33:18.806Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-icon.png',
      redirect_url: 'https://bvote.io-world.com/',
      order: 7,
      desktop_url: '',
      extension_url: '',
      document_url: null
    }
  ],
  newApplications: [],
  SuggestedApplications: [
    {
      features: ['Proof of ownership', 'Resale Royalties'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['Proof of ownership', 'Resale Royalties'],
      id: 43,
      appId: 'BeFLKkwgxv5uscEcIVKVkhDJn',
      name: 'bNFT',
      app_url: 'https://nft.io.world.com/',
      ios_url: 'https://nft.io.world.com/',
      description:
        "At edeXa NFT, we're excited about a brand new type of digital good called a non-fungible token, or NFT. NFTs have exciting new properties: they’re unique, provably scarce, tradeable, and usable across multiple applications. Just like physical goods, you can do whatever you want with them! You could throw them in the trash, gift them to a friend across the world, or go sell them on an open marketplace.",
      shortDescription: 'NFT',
      subtitle: 'NFT',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-12-02T10:43:04.342Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      redirect_url: 'https://nft.io-world.com/create-nft',
      order: 2,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain ',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Secure file upload with blockchain ',
        'validate file using blockchain'
      ],
      id: 40,
      appId: 'R5kg9gW1Dz2wTWWrzyI7GR2po',
      name: 'bStamp',
      app_url: '',
      ios_url: '',
      description:
        "bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently.  Digital data can be securely time stamped using the attestation of its hash value in blockchain transactions.  The hash value 'uniquely' identifies the document (as if it were its digital fingerprint), the transaction extends its native blockchain timestamping to the included hash value, therefore proving its existence at a given point in time.",
      shortDescription: 'bStamp',
      subtitle: 'bStamp',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-09-06T09:15:52.270Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp-icon.svg',
      redirect_url: 'https://bstampweb.io-world.com/account',
      order: 3,
      desktop_url:
        'https://www.microsoft.com/en-us/p/bstamp/9p2tc01tvxht?activetab=pivot:overviewtab#',
      extension_url:
        'https://chrome.google.com/webstore/detail/bstamp/ldgcfheomoleahgpkkiikckmhaafccpd',
      document_url: null
    },
    {
      features: [
        'Can store your files and documents store securely with encryption using block chain',
        'Can create a categories and store files and documents accordingly with categories',
        'Can share files to another with expiration details and as well with never expiration manner',
        'Can view the files and document at application without download it.'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['bShare', 'bSign', 'bArchive'],
      id: 11,
      appId: 'C1nNrE3OrCyS8HjIfzSyhae8W',
      name: 'banji',
      app_url:
        'https://play.google.com/store/apps/details?id=com.edexa.banjiapp',
      ios_url: 'https://apps.apple.com/us/app/banji/id1530775896',
      description:
        'Banji application is majorly used to share or store the files in a secure way. The files which can be shared / Stored are Images, Videos, audios, document files.',
      shortDescription:
        'Banji application is majorly used to share or store the files in a secure way. The files which can be shared / Stored are Images, Videos, audios, document files.',
      subtitle: '',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '1',
      type: 'app',
      createdAt: '2021-03-24T06:27:05.622Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://banjiweb.io-world.com/',
      order: 4,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      id: 1,
      appId: 'LRvwiGy61GqL5Tqayt3OEFNWx',
      name: 'bMessage',
      app_url:
        'https://play.google.com/store/apps/details?id=com.edexa.bmessage',
      ios_url: 'https://apps.apple.com/us/app/bmessage/id1577165319',
      description:
        'bMessage provides a platform to send self destructive messages, files very securely with end 2 end encryption.',
      shortDescription: 'Bmessage short description',
      subtitle: 'bMessage subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-logo.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-01-27T11:57:28.001Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-icon.png',
      redirect_url: 'https://bmessage.io-world.com/',
      order: 5,
      desktop_url: 'https://bmessage.edexa.com/',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Poll statistics',
        'Manage Voters, Candidates and Polls'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorized No valid API key provided'
        },
        {
          status_code: 402,
          message: '402'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        }
      ],
      ErrorTypes: [
        "api_connection_error Failure to connect to FileSafe's API.",
        "api_error API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error Failure to properly authenticate yourself in the request.',
        'wrong_body Pass wrong body fields or missing some fields.'
      ],
      appFeatures: [
        'Poll statistics',
        'Manage Voters, Candidates and Polls'
      ],
      id: 32,
      appId: 'KEsyhg3hgpDZUEp1LUd7IoPn6',
      name: 'bVote',
      app_url: '',
      ios_url: 'https://apps.apple.com/us/app/bvote/id1552682465',
      description:
        'Through the bvote voters can vote to the candidates and can view the results of the poll.',
      shortDescription:
        'Through the bvote voters can vote to the candidates and can view the results of the poll.',
      subtitle: 'bVote subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-logo.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext.',
      suggestionApplication: '0',
      type: 'app',
      createdAt: '2021-08-03T08:33:18.806Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-icon.png',
      redirect_url: 'https://bvote.io-world.com/',
      order: 7,
      desktop_url: '',
      extension_url: '',
      document_url: null
    }
  ]
}

export const apiListing = {
  myApplications: [],
  totalApplications: [
    {
      features: ['Secure file for the long term with blockchain'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 201,
          message: 'New record created'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorised No valid API key provided'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        },
        {
          status_code: 404,
          message: 'Data not found'
        },
        {
          status_code: 405,
          message: 'Username does not exist'
        },
        {
          status_code: 409,
          message:
            'Whenever create new record it will be check is it exist same record or not'
        },
        {
          status_code: 422,
          message: 'Unprocessed request'
        },
        {
          status_code: 500,
          message: 'If any server related error occur'
        },
        {
          status_code: 507,
          message: 'Out of storage capacity'
        }
      ],
      ErrorTypes: [
        "api_connection_error = Failure to connect to FileSafe's API.",
        "api_error = API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error = Failure to properly authenticate yourself in the request.',
        'wrong_body = Pass wrong body fields or missing some fields.'
      ],
      id: 54,
      appId: 'OThUJ95uCJrABHs3PC3P9KcxC',
      name: 'banji Api',
      app_url: '',
      ios_url: '',
      description:
        'banji API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files',
      shortDescription:
        'banji API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files',
      subtitle: 'banji',
      logo: '',
      ErrorDescription: '',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2023-10-18T09:15:18.788Z',
      isAvailable: 0,
      status: 'active',
      logoIcon: null,
      redirect_url: 'https://api.banji.io-world.com',
      order: 1,
      liveUrlDomain: null,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some.',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      id: 38,
      appId: '4eosa2DmSb636wDbgAy4iVBvy',
      name: 'bStamp API',
      app_url: 'https://apibstamp.io-world.com/',
      ios_url: ' ',
      description:
        "bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently.  Digital data can be securely time stamped using the attestation of its hash value in blockchain transactions.  The hash value 'uniquely' identifies the document (as if it were its digital fingerprint), the transaction extends its native blockchain timestamping to the included hash value, therefore proving its existence at a given point in time.",
      shortDescription:
        'bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently. ',
      subtitle: 'bStamp subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2021-08-20T06:35:55.918Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp-icon.svg',
      redirect_url: 'https://apibstamp.io-world.com/',
      order: 10,
      liveUrlDomain: 'https://apibstamp.io-world.com/',
      desktop_url: ' ',
      extension_url: ' ',
      document_url:
        'https://documenter.getpostman.com/view/16352901/U16oo3rg'
    },
    {
      features: [
        'Share files with end-to-end encryption',
        'Share files along with versions'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some ',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: [
        'Share files with end-to-end encryption',
        'Share files along with versions'
      ],
      id: 29,
      appId: 'NUbNVp2iPCHGMHTETdNhzxCdi',
      name: 'bShare API',
      app_url: 'https://api.banji.io-world.com/v1/',
      ios_url: ' ',
      description:
        'bShare API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files',
      shortDescription:
        'bShare API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files.',
      subtitle: 'Banji subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2021-05-03T06:59:15.146Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://api.banji.io-world.com/v1/',
      order: 11,
      liveUrlDomain: 'https://api-edexagw.io-world.com/bshare',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    },
    {
      features: ['Secure file for the long term with blockchain'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 201,
          message: 'New record created'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorised No valid API key provided'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        },
        {
          status_code: 404,
          message: 'Data not found'
        },
        {
          status_code: 405,
          message: 'Username does not exist'
        },
        {
          status_code: 409,
          message:
            'Whenever create new record it will be check is it exist same record or not'
        },
        {
          status_code: 422,
          message: 'Unprocessed request'
        },
        {
          status_code: 500,
          message: 'If any server related error occur'
        },
        {
          status_code: 507,
          message: 'Out of storage capacity'
        }
      ],
      ErrorTypes: [
        "api_connection_error = Failure to connect to FileSafe's API.",
        "api_error = API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error = Failure to properly authenticate yourself in the request.',
        'wrong_body = Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['Secure file for the long term with blockchain'],
      id: 19,
      appId: 'jhSF4JZyom82vHZL51t4ljnrc',
      name: 'bArchive API',
      app_url: 'https://api.archived.io-world.com',
      ios_url: ' ',
      description:
        'bArchive API lets you to Secure your documents for long term. Your important documents are protected from changes and unwanted access',
      shortDescription:
        'bArchive API lets you to Secure your documents for long term. Your important documents are protected from changes and unwanted access.',
      subtitle: 'Secure your documents long term with blockchain',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '1',
      type: 'api',
      createdAt: '2021-03-24T06:58:55.000Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://api.archived.io-world.com/',
      order: 12,
      liveUrlDomain: 'https://api-edexagw.io-world.com/bshare',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    },
    {
      features: ['Proof of  ownership', 'Resale Royalties'],
      HTTPstatus: [
        {
          status_code: '200',
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some ',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: ['Proof of  ownership', 'Resale Royalties'],
      id: 44,
      appId: 'EVw6DOQWurvlEQaxBFetQQC02',
      name: 'bNFT API',
      app_url: 'https://nft-platform-service.io-world.com/',
      ios_url: 'https://nft-platform-service.io-world.com/',
      description: 'edeXa NFT APIs',
      shortDescription: 'edeXa NFT API',
      subtitle: 'edeXa NFT API',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2022-01-07T08:59:29.552Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      redirect_url: 'https://nft-platform-service.io-world.com/',
      order: 13,
      liveUrlDomain: '',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    },
    {
      features: [
        'An ERC20 token is a blockchain-based asset with similar functionality to bitcoin, ether, and bitcoin cash: it can hold value and be sent and received',
        'Secure transfer token with blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some ',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: [
        'An ERC20 token is a blockchain-based asset with similar functionality to bitcoin, ether, and bitcoin cash: it can hold value and be sent and received',
        'Secure transfer token with blockchain'
      ],
      id: 46,
      appId: 'Stp4UDgldGZq2NDPU6IpGi7PJ',
      name: 'ERC20 API',
      app_url: '',
      ios_url: ' ',
      description: 'Token Engine ERC20 API',
      shortDescription: 'Token Engine ERC20 API',
      subtitle: 'Token Engine ERC20 API',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/erc20.png',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2022-03-02T12:42:06.089Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/erc20.png',
      redirect_url: 'https://universe.io-world.com/',
      order: 14,
      liveUrlDomain: '',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    }
  ],
  newApplications: [],
  SuggestedApplications: [
    {
      features: ['Secure file for the long term with blockchain'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 201,
          message: 'New record created'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorised No valid API key provided'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        },
        {
          status_code: 404,
          message: 'Data not found'
        },
        {
          status_code: 405,
          message: 'Username does not exist'
        },
        {
          status_code: 409,
          message:
            'Whenever create new record it will be check is it exist same record or not'
        },
        {
          status_code: 422,
          message: 'Unprocessed request'
        },
        {
          status_code: 500,
          message: 'If any server related error occur'
        },
        {
          status_code: 507,
          message: 'Out of storage capacity'
        }
      ],
      ErrorTypes: [
        "api_connection_error = Failure to connect to FileSafe's API.",
        "api_error = API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error = Failure to properly authenticate yourself in the request.',
        'wrong_body = Pass wrong body fields or missing some fields.'
      ],
      id: 54,
      appId: 'OThUJ95uCJrABHs3PC3P9KcxC',
      name: 'banji Api',
      app_url: '',
      ios_url: '',
      description:
        'banji API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files',
      shortDescription:
        'banji API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files',
      subtitle: 'banji',
      logo: '',
      ErrorDescription: '',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2023-10-18T09:15:18.788Z',
      isAvailable: 1,
      status: 'active',
      logoIcon: null,
      redirect_url: 'https://api.banji.io-world.com',
      order: 1,
      liveUrlDomain: null,
      desktop_url: '',
      extension_url: '',
      document_url: null
    },
    {
      features: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some.',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: [
        'Secure file upload with blockchain',
        'validate file using blockchain'
      ],
      id: 38,
      appId: '4eosa2DmSb636wDbgAy4iVBvy',
      name: 'bStamp API',
      app_url: 'https://apibstamp.io-world.com/',
      ios_url: ' ',
      description:
        "bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently.  Digital data can be securely time stamped using the attestation of its hash value in blockchain transactions.  The hash value 'uniquely' identifies the document (as if it were its digital fingerprint), the transaction extends its native blockchain timestamping to the included hash value, therefore proving its existence at a given point in time.",
      shortDescription:
        'bStamp proves that certain data existed before a certain time.  edeXa ecosystem creates possible timestamps and then verifies them independently. ',
      subtitle: 'bStamp subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2021-08-20T06:35:55.918Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp-icon.svg',
      redirect_url: 'https://apibstamp.io-world.com/',
      order: 10,
      liveUrlDomain: 'https://apibstamp.io-world.com/',
      desktop_url: ' ',
      extension_url: ' ',
      document_url:
        'https://documenter.getpostman.com/view/16352901/U16oo3rg'
    },
    {
      features: [
        'Share files with end-to-end encryption',
        'Share files along with versions'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some ',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: [
        'Share files with end-to-end encryption',
        'Share files along with versions'
      ],
      id: 29,
      appId: 'NUbNVp2iPCHGMHTETdNhzxCdi',
      name: 'bShare API',
      app_url: 'https://api.banji.io-world.com/v1/',
      ios_url: ' ',
      description:
        'bShare API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files',
      shortDescription:
        'bShare API used to share the files in a secure way. The files which can be shared are Images, Videos, audios document files.',
      subtitle: 'Banji subtitle',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2021-05-03T06:59:15.146Z',
      isAvailable: 0,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://api.banji.io-world.com/v1/',
      order: 11,
      liveUrlDomain: 'https://api-edexagw.io-world.com/bshare',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    },
    {
      features: ['Secure file for the long term with blockchain'],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'OK Everything worked as expected.'
        },
        {
          status_code: 201,
          message: 'New record created'
        },
        {
          status_code: 400,
          message:
            'Bad Request The request was unacceptable, often due to missing a required parameter.'
        },
        {
          status_code: 401,
          message: 'Unauthorised No valid API key provided'
        },
        {
          status_code: 403,
          message:
            "Forbidden The API key doesn't have permissions to perform the request."
        },
        {
          status_code: 404,
          message: 'Data not found'
        },
        {
          status_code: 405,
          message: 'Username does not exist'
        },
        {
          status_code: 409,
          message:
            'Whenever create new record it will be check is it exist same record or not'
        },
        {
          status_code: 422,
          message: 'Unprocessed request'
        },
        {
          status_code: 500,
          message: 'If any server related error occur'
        },
        {
          status_code: 507,
          message: 'Out of storage capacity'
        }
      ],
      ErrorTypes: [
        "api_connection_error = Failure to connect to FileSafe's API.",
        "api_error = API errors cover any other type of problem (e.g., a temporary problem with FileSafe's servers), and are extremely uncommon.",
        'authentication_error = Failure to properly authenticate yourself in the request.',
        'wrong_body = Pass wrong body fields or missing some fields.'
      ],
      appFeatures: ['Secure file for the long term with blockchain'],
      id: 19,
      appId: 'jhSF4JZyom82vHZL51t4ljnrc',
      name: 'bArchive API',
      app_url: 'https://api.archived.io-world.com',
      ios_url: ' ',
      description:
        'bArchive API lets you to Secure your documents for long term. Your important documents are protected from changes and unwanted access',
      shortDescription:
        'bArchive API lets you to Secure your documents for long term. Your important documents are protected from changes and unwanted access.',
      subtitle: 'Secure your documents long term with blockchain',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '1',
      type: 'api',
      createdAt: '2021-03-24T06:58:55.000Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
      redirect_url: 'https://api.archived.io-world.com/',
      order: 12,
      liveUrlDomain: 'https://api-edexagw.io-world.com/bshare',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    },
    {
      features: ['Proof of  ownership', 'Resale Royalties'],
      HTTPstatus: [
        {
          status_code: '200',
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some ',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: ['Proof of  ownership', 'Resale Royalties'],
      id: 44,
      appId: 'EVw6DOQWurvlEQaxBFetQQC02',
      name: 'bNFT API',
      app_url: 'https://nft-platform-service.io-world.com/',
      ios_url: 'https://nft-platform-service.io-world.com/',
      description: 'edeXa NFT APIs',
      shortDescription: 'edeXa NFT API',
      subtitle: 'edeXa NFT API',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2022-01-07T08:59:29.552Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
      redirect_url: 'https://nft-platform-service.io-world.com/',
      order: 13,
      liveUrlDomain: '',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    },
    {
      features: [
        'An ERC20 token is a blockchain-based asset with similar functionality to bitcoin, ether, and bitcoin cash: it can hold value and be sent and received',
        'Secure transfer token with blockchain'
      ],
      HTTPstatus: [
        {
          status_code: 200,
          message: 'Indicates that the request has succeeded.'
        },
        {
          status_code: '201',
          message:
            'Indicates that the request has succeeded and a new resource has been created as a result.'
        },
        {
          status_code: '400',
          message:
            'Bad Request - The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.'
        },
        {
          status_code: '401',
          message:
            'Unauthorized - Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field'
        },
        {
          status_code: 403,
          message:
            'Forbidden. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.'
        },
        {
          status_code: '404',
          message:
            'Not Found - The server can not find the requested resource.'
        },
        {
          status_code: '406',
          message:
            'Not Acceptable - The server doesn’t find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.'
        },
        {
          status_code: '409',
          message:
            'Conflict - The request could not be completed due to a conflict with the current state of the resource.'
        },
        {
          status_code: '422',
          message:
            'Unprocessable Entity - The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.'
        },
        {
          status_code: '423',
          message:
            'Locked - The resource that is being accessed is locked.'
        },
        {
          status_code: '425',
          message:
            'Too Early- Indicates that the server is unwilling to risk processing a request that might be replayed.'
        },
        {
          status_code: '429',
          message:
            'Too Many Requests - The user has sent too many requests in a given amount of time (“rate limiting”).'
        },
        {
          status_code: '500',
          message:
            'Internal Server Error - The server encountered an unexpected condition that prevented it from fulfilling the request.'
        },
        {
          status_code: '503',
          message:
            'Under Maintenance - The server is not ready to handle the request.'
        }
      ],
      ErrorTypes: [
        '1xx: Informational = Communicates transfer protocol-level information.',
        '2xx: Success = Indicates that the client’s request was accepted successfully.',
        '3xx: Redirection = Indicates that the client must take some ',
        '4xx: Client Error = This category of error status codes points the finger at clients.',
        '5xx: Server Error = The server takes responsibility for these error status codes.'
      ],
      appFeatures: [
        'An ERC20 token is a blockchain-based asset with similar functionality to bitcoin, ether, and bitcoin cash: it can hold value and be sent and received',
        'Secure transfer token with blockchain'
      ],
      id: 46,
      appId: 'Stp4UDgldGZq2NDPU6IpGi7PJ',
      name: 'ERC20 API',
      app_url: '',
      ios_url: ' ',
      description: 'Token Engine ERC20 API',
      shortDescription: 'Token Engine ERC20 API',
      subtitle: 'Token Engine ERC20 API',
      logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/erc20.png',
      ErrorDescription:
        'Errors are returned using standard HTTP error code syntax. Depending on the status code, the response body may be in JSON or plaintext. ',
      suggestionApplication: '0',
      type: 'api',
      createdAt: '2022-03-02T12:42:06.089Z',
      isAvailable: 1,
      status: 'active',
      logoIcon:
        'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/erc20.png',
      redirect_url: 'https://universe.io-world.com/',
      order: 14,
      liveUrlDomain: '',
      desktop_url: ' ',
      extension_url: ' ',
      document_url: null
    }
  ]
}

export const availableApps = [
  {
    id: 41,
    appId: 'yPAwp5kqUbsZdql16dlZD7U1z',
    name: 'Account',
    app_url: '',
    ios_url: '',
    logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/edexa-blockchain-business.png',
    logoIcon:
      'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/x.svg',
    isAvailable: 1,
    redirect_url: 'https://accounts.io-world.com/',
    order: 1,
    desktop_url: '',
    extension_url: '',
    document_url: null
  },
  {
    id: 43,
    appId: 'BeFLKkwgxv5uscEcIVKVkhDJn',
    name: 'bNFT',
    app_url: 'https://nft.io.world.com/',
    ios_url: 'https://nft.io.world.com/',
    logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
    logoIcon:
      'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bnft.svg',
    isAvailable: 1,
    redirect_url: 'https://nft.io-world.com/all-nfts',
    order: 2,
    desktop_url: '',
    extension_url: '',
    document_url: null
  },
  {
    id: 40,
    appId: 'R5kg9gW1Dz2wTWWrzyI7GR2po',
    name: 'bStamp',
    app_url: '',
    ios_url: '',
    logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp.svg',
    logoIcon:
      'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bstamp-icon.svg',
    isAvailable: 1,
    redirect_url: 'https://bstampweb.io-world.com/account',
    order: 3,
    desktop_url:
      'https://www.microsoft.com/en-us/p/bstamp/9p2tc01tvxht?activetab=pivot:overviewtab#',
    extension_url:
      'https://chrome.google.com/webstore/detail/bstamp/ldgcfheomoleahgpkkiikckmhaafccpd',
    document_url: null
  },
  {
    id: 11,
    appId: 'C1nNrE3OrCyS8HjIfzSyhae8W',
    name: 'banji',
    app_url:
      'https://play.google.com/store/apps/details?id=com.edexa.banjiapp',
    ios_url: 'https://apps.apple.com/us/app/banji/id1530775896',
    logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji.svg',
    logoIcon:
      'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/banji-icon.png',
    isAvailable: 1,
    redirect_url: 'https://banjiweb.io-world.com/',
    order: 4,
    desktop_url: '',
    extension_url: '',
    document_url: null
  },
  {
    id: 1,
    appId: 'LRvwiGy61GqL5Tqayt3OEFNWx',
    name: 'bMessage',
    app_url:
      'https://play.google.com/store/apps/details?id=com.edexa.bmessage',
    ios_url: 'https://apps.apple.com/us/app/bmessage/id1577165319',
    logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-logo.svg',
    logoIcon:
      'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bmessage-icon.png',
    isAvailable: 0,
    redirect_url: 'https://bmessage.io-world.com/',
    order: 5,
    desktop_url: 'https://bmessage.edexa.com/',
    extension_url: '',
    document_url: null
  },
  {
    id: 32,
    appId: 'KEsyhg3hgpDZUEp1LUd7IoPn6',
    name: 'bVote',
    app_url: '',
    ios_url: 'https://apps.apple.com/us/app/bvote/id1552682465',
    logo: 'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-logo.svg',
    logoIcon:
      'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/bvote-icon.png',
    isAvailable: 0,
    redirect_url: 'https://bvote.io-world.com/',
    order: 7,
    desktop_url: '',
    extension_url: '',
    document_url: null
  }
]
