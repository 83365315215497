import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getBlockProcessGraphApiInfo = (parameter: any) => {
  if (parameter?.storePeerId) {
    return HTTPService.get(
      `${apiEndPoints?.universeAPI?.myBlockChain?.blockProcess?.get}?start=${
        Math.floor(parameter?.storeSelectedTimeAndItsValue?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)
      }&end=${Math.floor(parameter?.storeSelectedTimeAndItsValue?.startTime) || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&peerId=${parameter?.storePeerId || ""}&channelId=${
        parameter?.storeChannelId
      }`
    );
  } else {
    return HTTPService.get(
      `${apiEndPoints?.universeAPI?.myBlockChain?.blockProcess?.get}?start=${
        Math.floor(parameter?.storeSelectedTimeAndItsValue?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)
      }&end=${Math.floor(parameter?.storeSelectedTimeAndItsValue?.startTime) || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&channelId=${parameter?.storeChannelId}`
    );
  }
};

export const getTransactionCountGraphApiInfo = (params: any) => {
  if (params?.storePeerId) {
    return HTTPService.get(`${apiEndPoints.universeAPI.myBlockChain.transactionCount.get}?start=${
      Math.floor(params?.storeSelectedTimeAndItsValue?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)
    }&end=${params?.storeSelectedTimeAndItsValue?.startTime || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&peerId=${params?.storePeerId}&channelId=${params?.storeChannelId?.value}
        `);
  } else {
    return HTTPService.get(`${apiEndPoints.universeAPI.myBlockChain.transactionCount.get}?start=${
      Math.floor(params?.storeSelectedTimeAndItsValue?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)
    }&end=${params?.storeSelectedTimeAndItsValue?.startTime || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&channelId=${params?.storeChannelId?.value}
        `);
  }
};

export const getCPUUsageGraphApiInfo = (params: any, storePeerId?: string) => {
  if (storePeerId) {
    return HTTPService.get(`${apiEndPoints.universeAPI.myBlockChain.cpuUsage.get}?start=${
      Math.floor(params?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)
    }&end=${params?.startTime || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&peerId=${storePeerId}
        `);
  } else {
    return HTTPService.get(
      `${apiEndPoints.universeAPI.myBlockChain.cpuUsage.get}?start=${Math.floor(params?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)}&end=${
        params?.startTime || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)
      }`
    );
  }
};

export const getMemoryUsageGraphApiInfo = (params: any, storePeerId?: string) => {
  if (storePeerId) {
    return HTTPService.get(`${apiEndPoints.universeAPI.myBlockChain.memoryUsage.get}?start=${
      Math.floor(params?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)
    }&end=${params?.startTime || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&peerId=${storePeerId}
        `);
  } else {
    return HTTPService.get(
      `${apiEndPoints.universeAPI.myBlockChain.memoryUsage.get}?start=${Math.floor(params?.milliSecondsAsPerSelection) || Math.floor((Date.now() - 24 * 60 * 60 * 1000 - 300000) / 1000)}&end=${
        params?.startTime || Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)
      }`
    );
  }
};
