import moment from "moment";
import { FC, useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./lineChart.scss";
interface ILineChartComponent {
  assets?: number;
  number: number;
  color?: string;
  selectPastTimeFilter?: any;
  blockProcessGraphData?: any;
  sendFlagOfBlockProcessGraphAppearance?: any;
}
const LineChartComponent: FC<ILineChartComponent> = ({ assets, number, color, selectPastTimeFilter, blockProcessGraphData, sendFlagOfBlockProcessGraphAppearance }) => {
  // const currentDateInMilliseconds = Date.now() - 3600000 - 3600000 - 3600000;
  const currentDateInMilliseconds = Date.now();
  const [isBlockProcessGraphAppeared, setIsBlockProcessGraphAppeared] = useState(false);

  const DataSentToGraphAfterFilteration = blockProcessGraphData
    ?.filter((single: any) => {
      let timeStampConvertedToMilliSeconds = single?.timestamp * 1000;

      return timeStampConvertedToMilliSeconds >= selectPastTimeFilter && timeStampConvertedToMilliSeconds <= currentDateInMilliseconds;
    })
    .map((singleData: any) => {
      singleData.timestamp = moment.unix(singleData?.timestamp).format("HH:mm");
      return singleData;
    });
  let dataAfterTimeSort = DataSentToGraphAfterFilteration?.sort((a: any, b: any) => (a.timestamp > b.timestamp ? 1 : -1));

  const tooltipItems = (item: any) => {
    return Object.keys(item).map((key) => ({
      name: key,
      value: item[key].toString()
    }));
  };

  const CustomTooltip = ({ active, payload, label }: { active: any; payload: any; label: any }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Timestamp: ${payload[0].payload.timestamp}`}</p>
          <p className="label">{`Value: ${payload[0].payload.value}`}</p>
          <p className="label">{`Peer Name: ${payload[0].payload["Peer Name"]}`}</p>
          <p className="label">{`Le: ${payload[0].payload.le}`}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (isBlockProcessGraphAppeared) {
      sendFlagOfBlockProcessGraphAppearance(true);
    }
  }, [isBlockProcessGraphAppeared]);

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart width={500} height={300} data={dataAfterTimeSort}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          {/* @ts-ignore */}
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            yAxisId="left"
            // onAnimationEnd={() => setIsBlockProcessGraphAppeared(true)}
          />
          <Line type="monotone" dataKey="peer Name" stroke="#82ca9d" yAxisId="right" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
