import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { setLoading } from "store/loader/action";
import { getAllApplicationData } from "utils/common/helper/allApplicationData";
import "../../../styles/api/api.scss";
import "../../../styles/api_list/api_list.scss";
import { AllApplicationList } from "../applications";

const ApplicationList = () => {
  /**
   * @getAllAPIDeta
   */
  const [allApplicationData, setALLApplicationData] = useState<any>([]);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setLoading(true));
  //   getAllApplicationData().then((response: any) => {
  //     dispatch(setLoading(false));
  //     setALLApplicationData(response.data);
  //   });
  // }, [dispatch]);
  return (
    <>
      <div className="api-list-wrapper">
        <Container>
          {/**
           * @applications
           */}
          {/* ***************************************** */}
          {/**
           * @Subscribed
           */}
          {/* <SubscribedApplicationList listingData={allApplicationData} /> */}
          {/**
           * @All
           */}
          <AllApplicationList listingData={allApplicationData} />
        </Container>
      </div>
    </>
  );
};

export default ApplicationList;
