/* eslint-disable react-hooks/exhaustive-deps */
import HomeRequest from 'components/dashboard/HomeRequest'
import { PrivateBlockchain } from 'components/edexaPrivateBlockchainStepper'
import { CustomStepper } from 'components/edexaUniverseStepper/Stepper'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Container, Modal, Spinner } from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import { toastSuccess } from 'utils/commonFunctions'
import { NetworkStatusEnum } from 'utils/constants'
import { makeFullS3ObjectUrl } from 'utils/makeFullS3ObjectUrl'
import '../../../styles/home/home.scss'
const Banner = makeFullS3ObjectUrl('banner.png')

const EdexaUniverse = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector((state: any) => state.userDetails.user)
  const globalSetting = useSelector(
    (state: IRootReducer) => state.GlobalSettingReducer
  )

  const [edexaUniverseModal, setEdexaUniverseModal] = useState(false) // change to true if you want to directly open the modal // change to true if you want to directly open the modal
  const [privateBlockchainModal, setPrivateBlockchainModal] =
    useState(false) // change to true if you want to directly open the modal // change to true if you want to directly open the modal
  const [imageLoad, setImageLoad] = useState({
    img1: true,
    img2: true
  })
  // const [tokenEngineModal, setTokenEngineModal] = useState(false);

  const toggleEdexaUniverseModal = () =>
    setEdexaUniverseModal(!edexaUniverseModal)

  // useEffect(() => {
  //   if (user?.universeMaintenance) {
  //     navigate('/application')
  //   }
  // }, [user])

  // useEffect(() => {
  //   if (user?.networkStatus === 1) {
  //     if (globalSetting.comingSoon) {
  //       setEdexaUniverseModal(false)
  //     } else {
  //       navigate('/universe/dashboard')
  //     }
  //   }
  // }, [user?.networkStatus])

  // useEffect(() => {
  //   if (globalSetting.comingSoon) {
  //     setEdexaUniverseModal(false)
  //   } else {
  //     if (user?.networkStatus === 1) {
  //       if (globalSetting.comingSoon) {
  //         setEdexaUniverseModal(false)
  //       } else {
  //         navigate('/universe/dashboard')
  //       }
  //     }
  //   }
  // }, [globalSetting.comingSoon])

  return (
    <>
      {/* 2nd page */}
      {/* network request accepted or REJECTED */}
      {/* {(user?.networkStatus === NetworkStatusEnum.PENDING ||
        user?.networkStatus === NetworkStatusEnum.REJECTED ||
        user?.networkStatus === NetworkStatusEnum.INPROGRESS) && (
        <div className="home-wrapper">
          <Container>
            <div className="my-5">
              <HomeRequest
                status={
                  user?.networkStatus === NetworkStatusEnum.REJECTED
                    ? 'REJECTED'
                    : user?.networkStatus ===
                      NetworkStatusEnum.INPROGRESS
                    ? 'In-Progress'
                    : 'PENDING'
                }
                toggleEdexaUniverseModal={toggleEdexaUniverseModal}
              />
            </div>
          </Container>
        </div>
      )} */}

      {localStorage.getItem('modal') === 'true' ? (
        <div className="home-wrapper">
          <Container>
            <div className="my-5">
              <HomeRequest
                status={'PENDING'}
                toggleEdexaUniverseModal={toggleEdexaUniverseModal}
              />
            </div>
          </Container>
        </div>
      ) : (
        <>
          <div className="home-wrapper">
            {/* edexa sdk wizard menu */}
            <Container>
              <div className="banner">
                {imageLoad.img1 ? (
                  <div
                    className="img-fluid custom-banner d-flex justify-content-center align-items-center"
                    style={{ background: '#123159' }}
                  >
                    <Spinner className="text-white" />
                  </div>
                ) : null}
                <img
                  src={Banner}
                  className="img-fluid custom-banner"
                  alt="banner"
                  onLoad={() =>
                    setImageLoad((pre) => {
                      return { ...pre, img1: false }
                    })
                  }
                />
                <div className="content">
                  <div className="content-wrapper">
                    <div className="title-bl">
                      <h1 className="banner-title text-white">
                        <span className="f-300">Start with</span>
                        <span className="f-700"> SDKs / APis</span>
                      </h1>
                      <p className="c-tx-sixth">
                        Now is the time to join the EDX-Business
                        Blockchain! <br />
                        With edeXa Universe you can start your own
                        blockchain in one click Interested? ... Read
                        more at{' '}
                        <a
                          href=" https://developer.edexa.network/docs/category/plugins--lego-dapps"
                          className="font-16 text-white text-decoration-underline"
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          edexa.com
                        </a>
                      </p>
                    </div>
                    <div className="btn-block">
                      {globalSetting.comingSoon ? (
                        <button
                          className="custom-white btn-bl disabled"
                          onClick={() => toastSuccess('Coming Soon')}
                        >
                          Coming Soon
                        </button>
                      ) : (
                        <button
                          className="custom-white btn-bl"
                          onClick={() =>
                            setPrivateBlockchainModal(
                              !privateBlockchainModal
                            )
                          }
                        >
                          Get Started
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}

      {/* network request component */}

      {/* network request modal */}
      <div>
        <Modal
          isOpen={edexaUniverseModal}
          className="custom-stepper-popup"
          toggle={toggleEdexaUniverseModal}
          size="xl"
          centered
          external={
            <Link
              to="/"
              className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
              onClick={(e) => {
                e.preventDefault()
                toggleEdexaUniverseModal()
              }}
            >
              CLOSE
            </Link>
          }
        >
          <CustomStepper toggle={toggleEdexaUniverseModal} />
        </Modal>

        {/* private blockchain modal  */}
        <Modal
          isOpen={privateBlockchainModal}
          className="custom-stepper-popup"
          size="xl"
          centered
          external={
            <Link
              to="/"
              className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
              onClick={(e) => {
                e.preventDefault()
                setPrivateBlockchainModal(false)
              }}
            >
              CLOSE
            </Link>
          }
        >
          <PrivateBlockchain toggle={setPrivateBlockchainModal} />
        </Modal>
      </div>

      {/* <TokenEngineModal isOpen={tokenEngineModal} setIsOpen={setTokenEngineModal} /> */}
    </>
  )
}

export default EdexaUniverse
