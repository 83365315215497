import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

interface IData {
  applicationId: number;
  subscribe: number;
}

export const unsubscribeFromAPI = (data: IData): Promise<IUnsubscribeToAPI> => {
  return HTTPService.patch(apiEndPoints.unSubscribe, data);
};

export interface IUnsubscribeToAPI {
  message: string;
  status: number;
}

export const subscribeFromAPI = (data: IData): Promise<ISubscribeToAPI> => {
  return HTTPService.post(apiEndPoints.subscribe, data);
};

export interface ISubscribeToAPI {
  data: {
    applicationId: number;
    clientId: string;
    createdAt: string;
    id: number;
    lastBillingDate: string;
    secretKey: string;
    subscipationDate: string;
    subscribe: number;
    updatedAt: string;
  };
  message: string;
  status: number;
}
