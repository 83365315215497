import { BadgeBlock } from "components/badgeBlocks";
// import { HighchartsReact } from "highcharts-react-official";
// import Highcharts from "highcharts/highstock";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, Form, FormGroup, Row, Spinner } from "reactstrap";
import {
  setLoadDiffOfCPUUsage,
  setLoadDiffOfMemoryUsage,
  setLoadDiffPeer,
  setLoadDiffTransaction,
  setOnCLickBtnInfo,
  setSearchOfPeer,
} from "store/peerData/action";
import { IRootReducer } from "store/root-reducer";
import "../../../../src/styles/pages/myblockchain/myblockchain.scss";
import "../../../styles/PeerNode/peerNodeChart.scss";
import { timingForGraphArray } from "../../../utils/constants";

const PeerNodeChart = ({
  blockProcessGraphData,
  nameOfGraph,
  sendSelectPastTimeFilterToMyBlockChain,
  callFunctionTosendSelectPeerToMyBlockChain,
  callFunctionTosendSelectChannelToMyBlockChain,
  sendDropdownSelectionToMyBCComponent,
  isLoading,
  setIsLoading,
}: {
  blockProcessGraphData?: any;
  nameOfGraph?: string;
  sendSelectPastTimeFilterToMyBlockChain?: any;
  callFunctionTosendSelectPeerToMyBlockChain?: any;
  callFunctionTosendSelectChannelToMyBlockChain?: any;
  sendDropdownSelectionToMyBCComponent: Function;
  isLoading: boolean;
  setIsLoading: Function;
}) => {
  const currentDateInMilliseconds = Date.now();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState<any>([]);

  useEffect(() => {
    sendDropdownSelectionToMyBCComponent(profile.value);
  }, [profile]);

  const [isBlockProcessGraphAppeared, setIsBlockProcessGraphAppeared] =
    useState(false);

  const options = useSelector(
    (state: IRootReducer) => state.peerReducer.searchedPeerListForDropdown
  );

  const [selectChannel, setSelectChannel] = useState({});
  useEffect(() => {
    options.splice(0, 0, { label: "ALL" });
    setProfile({ label: options[0].label });
    if (getChannelDataFromRedux?.length > 0) {
      setSelectChannel({
        label: getChannelDataFromRedux[0].channelName,
        value: getChannelDataFromRedux[0]?.id,
      });
    }
  }, [options]);

  const [selectPastTimeFilter, setSelectPastTimeFilter] = useState<
    number | undefined | any
  >(timingForGraphArray.length > 0 && timingForGraphArray[0]);

  const callFunctionTosendSelectPastTimeFilterToMyBlockChain = (
    millisecondsOfSelectedTime: any
  ) => {
    let convertToNumberMillisecondsOfSelectedTime = parseInt(
      millisecondsOfSelectedTime
    );
    if (millisecondsOfSelectedTime === 300000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 900000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 1800000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        7
      );
    } else if (millisecondsOfSelectedTime === 3600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        14
      );
    } else if (millisecondsOfSelectedTime === 7200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        28
      );
    } else if (millisecondsOfSelectedTime === 21600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        86
      );
    } else if (millisecondsOfSelectedTime === 43200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        172
      );
    } else if (millisecondsOfSelectedTime === 86400000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        345
      );
    }
  };
  const onCLickBtnGraph = useSelector(
    (state: IRootReducer) => state.peerReducer.onCLickBtnGraphName
  );

  const noAccessOfGraphs = useSelector(
    (state: IRootReducer) => state.peerReducer.noAccess
  );

  const getChannelDataFromRedux = useSelector(
    (state: IRootReducer) => state?.channelDataReducer?.storeChannelData
  );

  const loadDiffOfPeerGraph = useSelector(
    (state: IRootReducer) => state.peerReducer.loadDiffOfPeer
  );

  const loadDiffOfTransactionGraph = useSelector(
    (state: IRootReducer) => state.peerReducer.loadDiffOfTransaction
  );

  const loadDiffOfMemoryUsageGraph = useSelector(
    (state: IRootReducer) => state.peerReducer.loadDiffOfMemoryUsage
  );

  const loadDiffOfCPUUsageGraph = useSelector(
    (state: IRootReducer) => state.peerReducer.loadDiffOfCPUUsage
  );

  const uniquePeerNames = Array.from(
    new Set(
      blockProcessGraphData
        .map((obj: any) =>
          obj.values
            .filter((data: any) => data)
            .map((name: any) => name.peerName)
        )
        .flat()
    )
  );

  const maxValues =
    blockProcessGraphData.flatMap((channel: any) =>
      channel?.values.map((time: any) => new Date(time?.timestamp * 1000))
    )?.length || 0;

  const optionsData = {
    time: {
      useUTC: false,
    },
    chart: {
      zoomType: "x",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    subtitle: {
      text:
        blockProcessGraphData?.length > 0
          ? getSubtitleTextTime(blockProcessGraphData)
          : null,
      align: "right",
      y: 10,
      style: {
        fontSize: "12px",
      },
    },
    xAxis: {
      type: "datetime",
      min: blockProcessGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[0],
      max: blockProcessGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[maxValues - 1],
      dateTimeLabelFormats: {
        millisecond: "%H:%M:%S",
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e of %b",
      },
      title: {
        text: "Timestamp",
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 4,
          },
        },
        marker: {
          enabled: false,
        },
      },
      series: {
        pointStart: blockProcessGraphData.flatMap((channel: any) =>
          channel?.values?.map((time: any) => time?.timestamp * 1000)
        )?.[0],
        pointInterval: 14000,
      },
    },
    // tooltip: {
    //   formatter: function (this: Highcharts.TooltipFormatterContextObject) {
    //     const dataIndex = this?.point.index;
    //     const value: any = this?.y;
    //     const timestamp = new Date(this?.point?.x).toLocaleString() || "Unknown Time Stamp";
    //     const peerName = blockProcessGraphData.flatMap((channel: any) => channel?.values?.map((name: any) => name.peerName))?.[dataIndex] || "Unknown Peer Name";

    //     let tooltipContent = `<b>Value:</b> ${value}<br>`;
    //     tooltipContent += `<b>Peer Name:</b> ${peerName}<br>`;
    //     tooltipContent += `<b>Timestamp:</b> ${timestamp}<br>`;

    //     return tooltipContent;
    //   }
    // },
    series: uniquePeerNames.map((peerName) => ({
      type: "line",
      name: peerName,
      data:
        blockProcessGraphData.length > 0
          ? blockProcessGraphData
              .flatMap((data: any) =>
                data?.values?.filter((peer: any) => peer?.peerName === peerName)
              )
              .map((val: any) => val?.value)
          : null,
    })),
    navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };

  function getSubtitleTextTime(data: any) {
    const uniqueDates = Array.from(
      new Set(
        data.map((obj: any) => moment(obj.key * 1000).format("DD/MM/YYYY"))
      )
    );
    if (uniqueDates.length === 2) {
      return `Dates: ${uniqueDates[0]} and ${uniqueDates[1]}`;
    } else if (uniqueDates.length === 1) {
      return `Date: ${uniqueDates[0]}`;
    } else {
      return "Unknown Date";
    }
  }

  return (
    <div
      className="backdrop-block"
      style={{
        minHeight: "495px",
        maxHeight: "495px",
      }}
    >
      <>
        <Row>
          <div className="d-flex">
            <Col md="2">
              <div className="title">
                <h6 className="c-tx-primary f-600 mb-10">
                  Block Process&nbsp;
                  {isLoading && (
                    <div className="loader-center">
                      <Spinner
                        style={{
                          marginTop: "40%",
                          marginLeft: "90%",
                        }}
                        size={"sm"}
                      />
                    </div>
                  )}
                </h6>
              </div>
            </Col>
            <Col md="2">
              <div className="title extra-css">
                <Select
                  className="peer-drop-down"
                  defaultValue={
                    timingForGraphArray?.length > 0 && {
                      label: timingForGraphArray[0].label,
                      value: timingForGraphArray[0].value,
                    }
                  }
                  options={timingForGraphArray}
                  value={selectPastTimeFilter}
                  onChange={(e: any) => {
                    if (nameOfGraph === "block-process") {
                      setSelectPastTimeFilter(e);
                      callFunctionTosendSelectPastTimeFilterToMyBlockChain(
                        e.value
                      );
                      setIsLoading(true);
                    }
                  }}
                />
              </div>
            </Col>
            <Col md="3" className="ml-10">
              <Form>
                <FormGroup>
                  <Select
                    className="peer-drop-down"
                    options={options}
                    onInputChange={(e: string) => {
                      dispatch(setSearchOfPeer(e));
                    }}
                    value={profile}
                    onChange={(value) => {
                      callFunctionTosendSelectPeerToMyBlockChain(value?.value);
                      setProfile(value);
                      setIsLoading(true);
                    }}
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col md="3" className="ml-40">
              <Select
                className="peer-drop-down"
                defaultValue={
                  getChannelDataFromRedux?.length > 0 && {
                    label: getChannelDataFromRedux[0]?.channelName,
                    value: getChannelDataFromRedux[0]?.id,
                  }
                }
                options={getChannelDataFromRedux.map((single: any) => {
                  return {
                    label: single?.channelName,
                    value: single?.id,
                  };
                })}
                value={selectChannel}
                onChange={(e: any) => {
                  if (nameOfGraph === "block-process") {
                    setSelectChannel(e);
                    callFunctionTosendSelectChannelToMyBlockChain(e.value);
                    setIsLoading(true);
                  }
                }}
              />
            </Col>
          </div>
        </Row>
      </>

      {blockProcessGraphData?.length > 0 && !isLoading ? (
        <>
          <Row>
            <Col
              md="12"
              style={{ visibility: isLoading ? "hidden" : "visible" }}
            >
              <div className="hide-highcharts-tag">
                {/* <HighchartsReact highcharts={Highcharts} options={optionsData} /> */}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        blockProcessGraphData?.length === 0 &&
        !isLoading && (
          <p
            style={{
              marginTop: "20%",
              marginLeft: "40%",
            }}
          >
            NO DATA FOUND
          </p>
        )
      )}

      {noAccessOfGraphs === false && (
        <>
          {(loadDiffOfPeerGraph === false ||
            loadDiffOfCPUUsageGraph ||
            loadDiffOfMemoryUsageGraph ||
            loadDiffOfTransactionGraph) && (
            <>
              <div
                onClick={() => {
                  if (onCLickBtnGraph === "random") {
                    dispatch(setLoadDiffOfMemoryUsage(false));
                    dispatch(setLoadDiffOfCPUUsage(false));
                    setIsBlockProcessGraphAppeared(false);
                    dispatch(setLoadDiffTransaction(false));
                    dispatch(setLoadDiffPeer(true));
                    dispatch(setOnCLickBtnInfo("blockProcess"));
                  } else if (onCLickBtnGraph === "memory") {
                  }
                }}
                className="load-diff"
              >
                <BadgeBlock
                  name="LOAD GRAPH"
                  theme={`${
                    onCLickBtnGraph === "memory" ||
                    onCLickBtnGraph === "cpu" ||
                    onCLickBtnGraph === "transaction"
                      ? "light"
                      : "dark"
                  }`}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PeerNodeChart;
