import "../../../styles/dummy/style.scss";
import { useState } from "react";
import FirstSection from "./pages/FirstSection";
import SecondSection from "./pages/SecondSection";
import ThirdSection from './pages/ThirdSection';
import FourSection from './pages/FourSection';
import FifthSection from './pages/FifthSection';

const DummyCode = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextClick = () => {
    if (currentStep === 4) return;
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <div className="background-block">
        <div className="block-90">
          {currentStep === 0 && (
            <FirstSection handleNextClick={handleNextClick} />
          )}
          {currentStep === 1 && (
            <SecondSection handleNextClick={handleNextClick} />
          )}
          {currentStep === 2 && (
            <ThirdSection handleNextClick={handleNextClick} />
          )}
          {currentStep === 3 && (
            <FourSection handleNextClick={handleNextClick} />
          )}
          {currentStep === 4 && (
            <FifthSection handleNextClick={handleNextClick} />
          )}
        </div>
      </div>
    </>
  );
};

export default DummyCode;
