import { getChannelListingAPI } from 'actions/channelListingAPI'
import { getSmartContractListAPIData } from 'actions/getSmartContractListAPI'

import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button, FormGroup, Modal } from 'reactstrap'
import { installationContractAPI } from 'utils/common/helper/installationContract'
import { getLocalStorage, toastSuccess } from 'utils/commonFunctions'

interface IAddChannelModal {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: getSmartContractListAPIData | undefined
}

const AddChannelModal: FC<IAddChannelModal> = ({
  open,
  setOpen,
  data
}) => {
  const [channelName, setChannelName] = useState<any>({})
  const [option, setOption] = useState<any[]>()

  const oldValue = getLocalStorage('channelName')
  const channelCode = oldValue?.map((obj: any) => ({
    id: 'chl_495fiiicz8',
    channelName: obj,
    totalParticipate: 0,
    channelHeight: 1014
  }))

  useEffect(() => {
    if (channelCode?.length > 0) {
      const newoption = channelCode?.map((data: any) => {
        return { label: data?.channelName, value: data?.id }
      })
      setOption(newoption)
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
    setChannelName({})
  }

  const handleSubmit = () => {
    if (data?.id) {
      installationContractAPI(data?.id, {
        channelId: channelName?.value
      }).then((res) => {
        if (res.status === 200) {
          toastSuccess(res.message)
          setOpen(false)
          setChannelName({})
        }
      })
    }
  }

  return (
    <Modal
      isOpen={open}
      className="custom-popup-channel"
      size="xs"
      centered
      external={
        <Link
          to="#"
          className="text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30 f-700"
          onClick={handleClose}
        >
          CLOSE
        </Link>
      }
    >
      <div className="erc-template-wrapper">
        <div className="title">
          <h5 className="c-tx-primary f-700 mb-20">
            {data?.name} Installation
          </h5>
        </div>
        <FormGroup className="position-relative">
          <Select
            options={option}
            value={channelName}
            placeholder="You can select channel"
            onChange={(e) => setChannelName(e)}
          />
          <p className="c-tx-ninth small mt-2">
            You can select channel
          </p>
        </FormGroup>
        <div className="btn-wrapper d-flex justify-content-end">
          <Button
            className="custom-primary new-btn"
            onClick={() => {
              toastSuccess('Installation Successfull')
              setOpen(false)
            }}
            disabled={Object.keys(channelName)?.length === 0}
          >
            Install
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddChannelModal
