import {
  getSmartContractListAPI,
  getSmartContractListAPIData
} from 'actions/getSmartContractListAPI'
import { NoFoundTemplate } from 'components/notFoundPages'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { IRootReducer } from 'store/root-reducer'
import { socketConnection } from 'utils/commonFunctions'
import { makeFullS3ObjectUrl } from 'utils/makeFullS3ObjectUrl'
import AddChannelModal from '../installationContract/AddChannelModal'
import PaginationComponent from '../pagination/PaginationComponent'
import UploadListingComponent from './UploadListingComponent'

const logo = makeFullS3ObjectUrl('zip.png')
interface IContractCreate {
  contractId: string
  contractName: string
  module: string
  status: number
}

const smartContracts = [
  {
    name: 'erc20',
    status: 1,
    id: 'cntrct_5d1n15lha5'
  },
  {
    name: 'erc721',
    status: 1,
    id: 'cntrct_53m05d77ek'
  }
]

const UploadContractTemplate = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )

  const [open, setOpen] = useState(false)
  // eslint-disable-next-line
  const [historyLoading, setHistoryLoading] = useState(false)
  const [data, setData] = useState<getSmartContractListAPIData>()
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalCount: 0
  })
  const [templateList, setTemplateList] =
    useState<any>(smartContracts)

  const getSmartContractListAction = useCallback(() => {
    dispatch(setLoading(true))
    setHistoryLoading(true)
    getSmartContractListAPI({
      limit: pagination.limit,
      page: pagination.page
    })
      .then((res) => {
        if (res.status === 200) {
          setPagination({ ...pagination, totalCount: res.data.count })
          setTemplateList(res.data.data)
        }
        setHistoryLoading(false)
        dispatch(setLoading(false))
      })
      .catch((err) => setHistoryLoading(false))
  }, [dispatch, pagination])

  // useEffect(() => {
  //   getSmartContractListAction()
  //   // eslint-disable-next-line
  // }, [pagination.page])

  // useEffect(() => {
  //   socketConnection?.on(
  //     `Contract_create_${user?.id}`,
  //     (data: IContractCreate) => {
  //       setTemplateList((res: any) => {
  //         const newArray = res.map((item) => {
  //           if (item?.id === data?.contractId) {
  //             return { ...item, status: data.status }
  //           }
  //           return item
  //         })
  //         return newArray
  //       })
  //     }
  //   )
  //   socketConnection?.on(`Contract_list_${user?.id}`, (data: any) => {
  //     if (pagination.page === 1) {
  //       getSmartContractListAction()
  //     } else {
  //       setPagination({ ...pagination, page: 1 })
  //     }
  //   })
  //   return () => {
  //     socketConnection?.off(`Contract_create_${user?.id}`)
  //     socketConnection?.off(`Contract_list_${user?.id}`)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.id])

  return (
    <>
      <div className="column_list cursor-pointer">
        {templateList.length > 0 ? (
          templateList.map(
            (data: getSmartContractListAPIData, i: number) => (
              <UploadListingComponent
                data={data}
                setData={setData}
                setOpen={setOpen}
                key={i}
              />
            )
          )
        ) : (
          <NoFoundTemplate
            message="No Found Smart Contracts"
            bageName="Smart Contract"
            logo={logo}
          />
        )}
        <AddChannelModal open={open} setOpen={setOpen} data={data} />
      </div>
      <div className="pagination-wrap mt-0">
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <PaginationComponent
              pagination={pagination}
              setPagination={setPagination}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UploadContractTemplate
