import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tour from 'reactour'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { setFirstTimePrivateBlockchain } from 'store/reactour/action'
import { IRootReducer } from 'store/root-reducer'
import { StepperStepsPropsType } from './PrivateBlockchainStepper'

export function SteppersecondTab({
  formik,
  currentStep
}: StepperStepsPropsType) {
  const [buttonData, setButton] = useState(false)

  const tourConfig = [
    {
      selector: '.first-link',
      content:
        "Organization name is pre set fantastic move! 🌐🔐 Now, let's elevate your private Blockchain experience."
    },
    {
      selector: '.second-link',
      content: (
        <div>
          <div>
            <p>
              Let's create a channel , then it's deployment time for
              your ecosystem.
            </p>
          </div>
          <div>
            <>
              <ul style={{ listStyle: 'disc' }} className="ms-3 mt-1">
                <li className="ms-3">Your Application Name</li>
                <li className="ms-3">Your Network Name</li>
                <li className="ms-3">Your Channel Name</li>
              </ul>
            </>
          </div>
          <span
            style={{ color: '#073D83', cursor: 'pointer' }}
            onClick={() => {
              setButton(true)
              dispatch(setFirstTimePrivateBlockchain(false))
            }}
          >
            With Edexa Suggestions
          </span>{' '}
          or{' '}
          <span
            style={{ color: '#073D83', cursor: 'pointer' }}
            onClick={() =>
              dispatch(setFirstTimePrivateBlockchain(false))
            }
          >
            I will add
          </span>
        </div>
      )
    }
  ]
  const dispatch = useDispatch()
  const { privateBlockchain } = useSelector(
    (state: IRootReducer) => state.firstTimeTourReducer
  )

  useEffect(() => {
    if (buttonData) {
      formik.setFieldValue('networkDetails.name', 'edexa')
      formik.setFieldValue(
        'networkDetails.missionStatement',
        'edeXa Network Mission'
      )
    }
  }, [buttonData])

  return (
    <>
      {/* Tab2 Code start */}
      <div className="flex-wrap-bl order-2 order-lg-1">
        <h5 className="tab-heading c-tx-primary f-700">
          Enter Network Details
        </h5>
        <FormGroup className="m-0">
          <Label for="exampleEmail">Organization Name</Label>
          <Input
            className="first-link"
            id="exampleEmail"
            name="enviornmentDetails.name"
            placeholder="Enter the name of this enviornment"
            type="email"
            disabled
            value="EDEXA COMMON ORG 00002"
            // value={values.enviornmentDetails.name}
            // onChange={(event) => {
            //   handleSubmit()
            //   setFieldValue(
            //     event.target.name,
            //     trimExtraSpacesFromString(event.target.value)
            //   )
            // }}
            // invalid={
            //   Boolean(errors?.enviornmentDetails?.name) &&
            //   Boolean(touched?.enviornmentDetails?.name)
            // }
          />
          {/* <FormFeedback>
              {errors?.enviornmentDetails?.name}
            </FormFeedback> */}
        </FormGroup>
        <div className="second-link">
          <FormGroup className="m-0">
            <Label for="exampleEmail">Create Your Channel</Label>
            <Input
              id="networkName"
              name="networkDetails.name"
              value={formik.values.networkDetails.name}
              onChange={(event) => {
                formik.setFieldValue(
                  event.target.name,
                  event.target.value
                )
                formik.validateField('networkDetails.name')
              }}
              invalid={
                Boolean(formik.errors?.networkDetails?.name) &&
                Boolean(formik.touched?.networkDetails?.name)
              }
              placeholder="Enter the name of Channel"
              type="text"
            />
            <FormFeedback>
              {formik.errors?.networkDetails?.name}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="m-0">
            <Label for="exampleEmail">
              Network Mission Statement
            </Label>
            <Input
              id="exampleEmail"
              name="networkDetails.missionStatement"
              value={formik.values.networkDetails.missionStatement}
              onChange={(event) => {
                formik.handleSubmit()
                formik.setFieldValue(
                  event.target.name,
                  event.target.value
                )
              }}
              invalid={
                Boolean(
                  formik.errors?.networkDetails?.missionStatement
                ) &&
                Boolean(
                  formik.touched?.networkDetails?.missionStatement
                )
              }
              placeholder="Enter the network mission statement"
              type="text"
            />
            <FormFeedback>
              {formik.errors?.networkDetails?.missionStatement}
            </FormFeedback>
          </FormGroup>
        </div>

        <Tour
          startAt={0}
          onRequestClose={() =>
            dispatch(setFirstTimePrivateBlockchain(false))
          }
          steps={tourConfig}
          highlightedMaskClassName="masked-highlight"
          isOpen={privateBlockchain}
          className={'reactour_touristGuide '}
          maskSpace={0}
          closeWithMask={false}
          rounded={0}
          accentColor="#073D83"
          disableFocusLock={false}
          disableInteraction={true}
          disableDotsNavigation={true}
          lastStepNextButton={
            <span
              onClick={() => {
                dispatch(setFirstTimePrivateBlockchain(false))
                setButton(false)
              }}
            >
              Finish
            </span>
          }
        />
        <h6>Note</h6>
        <span>
          Your private blockchain ecosystem will be setup on AWS -
          edeXa will enable soon more multi regions.
        </span>
      </div>
    </>
  )
}
