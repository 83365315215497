import { getCloudConfigurationsListAPI } from "actions/getCloudConfigurationsListAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col } from "reactstrap";
import { setLoading } from "store/loader/action";
import { toastSuccess } from "utils/commonFunctions";

const CloudConfigurationsListComponent = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [CloudConfigurationsList, setCloudConfigurationsList] = useState<any[]>(
    []
  );
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    totalCount: 0
  });
  const getCloudConfigurationsListAction = () => {
    dispatch(setLoading(true));
    getCloudConfigurationsListAPI({
      limit: pagination.limit,
      page: pagination.page
    }).then((res: any) => {
      if (res?.status === 200) {
        setCloudConfigurationsList(res?.data?.data);
        setPagination({ ...pagination, totalCount: res?.data?.count });
      }
      dispatch(setLoading(false));
    });
  };
  useEffect(() => {
    getCloudConfigurationsListAction();
    // eslint-disable-next-line
  }, []);
  return (
    <Col md="12" lg="12" xl="8" xxl="8">
      <div className="backdrop-block cloud-configuration-wrapper h-100 ">
        <div className="title">
          <h5 className="c-tx-primary f-600 mb-10">Configurations</h5>
        </div>
        <ul className="cloud-list">
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Key Store</p>
              <p className="c-tx-ninth small mt-1">Enhance the protection of Key Material</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl">
                <p className="c-tx-primary f-700">None Applied</p>
              </div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5" onClick={() => toastSuccess("Coming Soon")}>
                  Edit
                </Button>
              </div>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Backup Store</p>
              <p className="c-tx-ninth small mt-1 ">Backup your node to the cloud</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl">
                <p className="c-tx-primary f-700">None Applied</p>
              </div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5" onClick={() => toastSuccess("Coming Soon")}>
                  Edit
                </Button>
              </div>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Log Stream</p>
              <p className="c-tx-ninth small mt-1 ">Stream logs to your own management system</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl">
                <p className="c-tx-primary f-700">None Applied</p>
              </div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5" onClick={() => toastSuccess("Coming Soon")}>
                  Edit
                </Button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Col>
  );
};

export default CloudConfigurationsListComponent;
