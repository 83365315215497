import React, { FC } from 'react'
import GoogleLogin from 'react-google-login'
import { Button } from 'reactstrap'

interface IGoogleButtonLogin {
  responseGoogle: any
  label: string
}

const GoogleButtonLogin: FC<IGoogleButtonLogin> = ({
  responseGoogle,
  label
}) => {
  const url = "1087754539168-p53c06re06uv4mpdd8vc67il16ss2mfo.apps.googleusercontent.com"
  return (
    <GoogleLogin
      clientId={url || ''}
      render={(renderProps) => (
        <Button
          className="font-14 w-100 py-2 h-50px bg_white mb-3 gray-border-btn google-btn"
          type="button"
          onClick={renderProps.onClick}
        >
          <i className="me-3">
            <img
              src="https://account-files-bucket.s3.ap-south-1.amazonaws.com/accounts/assets/images/google-logo.svg"
              alt="google"
            />
          </i>
          <span className="font-16 color-light-grey fw-bold">
            {label}
          </span>
        </Button>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  )
}

export default GoogleButtonLogin
