export const data = [
  {
    content:
      '\u001b[34m2024-01-22 07:18:35.200 UTC [comm.grpc.server] 1 -> INFO 23372\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.646216ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:18:48.772 UTC [comm.grpc.server] 1 -> INFO 23373\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=957.455µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:19:35.199 UTC [comm.grpc.server] 1 -> INFO 23374\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.16464ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:19:48.736 UTC [comm.grpc.server] 1 -> INFO 23375\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.476449ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:20:35.257 UTC [comm.grpc.server] 1 -> INFO 23376\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=4.415788ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:20:48.749 UTC [comm.grpc.server] 1 -> INFO 23377\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.015237ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:21:35.259 UTC [comm.grpc.server] 1 -> INFO 23378\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.851136ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:21:48.761 UTC [comm.grpc.server] 1 -> INFO 23379\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.597111ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:22:35.244 UTC [comm.grpc.server] 1 -> INFO 2337a\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=5.444635ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:22:48.738 UTC [comm.grpc.server] 1 -> INFO 2337b\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.545691ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:23:35.276 UTC [comm.grpc.server] 1 -> INFO 2337c\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=5.529813ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:23:48.728 UTC [comm.grpc.server] 1 -> INFO 2337d\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.047086ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:24:35.306 UTC [comm.grpc.server] 1 -> INFO 2337e\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=4.209732ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:24:48.761 UTC [comm.grpc.server] 1 -> INFO 2337f\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.180293ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:25:35.366 UTC [comm.grpc.server] 1 -> INFO 23380\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=6.259472ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:25:49.324 UTC [comm.grpc.server] 1 -> INFO 23381\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.634105ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:26:35.371 UTC [comm.grpc.server] 1 -> INFO 23382\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.655895ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:26:49.309 UTC [comm.grpc.server] 1 -> INFO 23383\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.522697ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:27:35.575 UTC [comm.grpc.server] 1 -> INFO 23384\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.390829ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:27:49.316 UTC [comm.grpc.server] 1 -> INFO 23385\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.405085ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:28:35.663 UTC [comm.grpc.server] 1 -> INFO 23386\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.404542ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:28:49.321 UTC [comm.grpc.server] 1 -> INFO 23387\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.041307ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:29:35.802 UTC [comm.grpc.server] 1 -> INFO 23388\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=4.717163ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:29:49.900 UTC [comm.grpc.server] 1 -> INFO 23389\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.397019ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.312 UTC [endorser] callChaincode -> INFO 2338a\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-consent txID=440f4dfb'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.313 UTC [comm.grpc.server] 1 -> INFO 2338b\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.197179ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.335 UTC [endorser] callChaincode -> INFO 2338c\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-consent txID=e697040b'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.335 UTC [comm.grpc.server] 1 -> INFO 2338d\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.917493ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.870 UTC [endorser] callChaincode -> INFO 2338e\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-holder txID=4e1c2dda'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.870 UTC [comm.grpc.server] 1 -> INFO 2338f\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.613192ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.927 UTC [endorser] callChaincode -> INFO 23390\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-holder txID=c866b2a7'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:07.928 UTC [comm.grpc.server] 1 -> INFO 23391\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.592382ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.378 UTC [endorser] callChaincode -> INFO 23392\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-system txID=7f353f70'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.379 UTC [comm.grpc.server] 1 -> INFO 23393\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.655885ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.438 UTC [endorser] callChaincode -> INFO 23394\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-system txID=e0271d10'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.438 UTC [comm.grpc.server] 1 -> INFO 23395\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.641554ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.872 UTC [endorser] callChaincode -> INFO 23396\u001b[0m finished chaincode: qscc duration: 0ms channel=banji txID=44384c6b'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.872 UTC [comm.grpc.server] 1 -> INFO 23397\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.910695ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.893 UTC [endorser] callChaincode -> INFO 23398\u001b[0m finished chaincode: qscc duration: 2ms channel=banji txID=2c0700c4'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:08.894 UTC [comm.grpc.server] 1 -> INFO 23399\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=6.200246ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.375 UTC [endorser] callChaincode -> INFO 2339a\u001b[0m finished chaincode: qscc duration: 0ms channel=contractapi txID=69f9b3ae'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.375 UTC [comm.grpc.server] 1 -> INFO 2339b\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.420949ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.428 UTC [endorser] callChaincode -> INFO 2339c\u001b[0m finished chaincode: qscc duration: 0ms channel=contractapi txID=bc35f64b'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.429 UTC [comm.grpc.server] 1 -> INFO 2339d\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.066182ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.871 UTC [endorser] callChaincode -> INFO 2339e\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-verifier txID=ba9dc1ab'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.871 UTC [comm.grpc.server] 1 -> INFO 2339f\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.444985ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.934 UTC [endorser] callChaincode -> INFO 233a0\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-verifier txID=f0297168'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:09.935 UTC [comm.grpc.server] 1 -> INFO 233a1\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.671756ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:10.569 UTC [endorser] callChaincode -> INFO 233a2\u001b[0m finished chaincode: qscc duration: 0ms channel=archive-api txID=0a7b9a36'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:10.570 UTC [comm.grpc.server] 1 -> INFO 233a3\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.353771ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:10.657 UTC [endorser] callChaincode -> INFO 233a4\u001b[0m finished chaincode: qscc duration: 0ms channel=archive-api txID=2d3dddfe'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:10.658 UTC [comm.grpc.server] 1 -> INFO 233a5\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.417079ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.038 UTC [endorser] callChaincode -> INFO 233a6\u001b[0m finished chaincode: qscc duration: 3ms channel=towerconnect txID=8dd52281'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.039 UTC [comm.grpc.server] 1 -> INFO 233a7\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=4.608926ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.107 UTC [endorser] callChaincode -> INFO 233a8\u001b[0m finished chaincode: qscc duration: 1ms channel=towerconnect txID=f42dd823'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.107 UTC [comm.grpc.server] 1 -> INFO 233a9\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.518011ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.622 UTC [endorser] callChaincode -> INFO 233aa\u001b[0m finished chaincode: qscc duration: 1ms channel=breceipt txID=409839f3'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.622 UTC [comm.grpc.server] 1 -> INFO 233ab\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.466045ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.636 UTC [endorser] callChaincode -> INFO 233ac\u001b[0m finished chaincode: qscc duration: 0ms channel=breceipt txID=04ecc946'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.637 UTC [comm.grpc.server] 1 -> INFO 233ad\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.47351ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.989 UTC [endorser] callChaincode -> INFO 233ae\u001b[0m finished chaincode: qscc duration: 5ms channel=bmessage txID=2f2c22bd'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:11.990 UTC [comm.grpc.server] 1 -> INFO 233af\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=10.576787ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.034 UTC [endorser] callChaincode -> INFO 233b0\u001b[0m finished chaincode: qscc duration: 1ms channel=bmessage txID=6ffe4817'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.036 UTC [comm.grpc.server] 1 -> INFO 233b1\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=4.934128ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.040 UTC [endorser] callChaincode -> INFO 233b2\u001b[0m finished chaincode: edexa-wormhole duration: 9ms channel=master-channel txID=d92a31f3'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.041 UTC [comm.grpc.server] 1 -> INFO 233b3\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=14.686813ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.091 UTC [endorser] callChaincode -> INFO 233b4\u001b[0m finished chaincode: edexa-wormhole duration: 21ms channel=master-channel txID=8135037b'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.092 UTC [comm.grpc.server] 1 -> INFO 233b5\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=26.275155ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.834 UTC [endorser] callChaincode -> INFO 233b6\u001b[0m finished chaincode: qscc duration: 0ms channel=master-channel txID=d193937b'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.835 UTC [comm.grpc.server] 1 -> INFO 233b7\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.259748ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.875 UTC [endorser] callChaincode -> INFO 233b8\u001b[0m finished chaincode: qscc duration: 0ms channel=master-channel txID=8cea62ae'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:12.875 UTC [comm.grpc.server] 1 -> INFO 233b9\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.851185ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:14.290 UTC [gossip.privdata] StoreBlock -> INFO 233ba\u001b[0m Received block [4945] from buffer channel=master-channel'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:14.303 UTC [committer.txvalidator] Validate -> INFO 233bb\u001b[0m [master-channel] Validated block [4945] in 12ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:14.379 UTC [kvledger] CommitLegacy -> INFO 233bc\u001b[0m [master-channel] Committed block [4945] with 2 transaction(s) in 75ms (state_validation=1ms block_and_pvtdata_commit=11ms state_commit=58ms) commitHash=[47c59d3905e2be2e38be5962f30b62b9f4a8e95d0693bc721ad615e2b4d0bad2]'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:35.862 UTC [comm.grpc.server] 1 -> INFO 233bd\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.227794ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:30:49.799 UTC [comm.grpc.server] 1 -> INFO 233be\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.340446ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:31:36.249 UTC [comm.grpc.server] 1 -> INFO 233bf\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.014078ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:31:49.828 UTC [comm.grpc.server] 1 -> INFO 233c0\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=948.051µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:32:36.169 UTC [comm.grpc.server] 1 -> INFO 233c1\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.373209ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:32:49.834 UTC [comm.grpc.server] 1 -> INFO 233c2\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=824.339µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:22.236 UTC [comm.grpc.server] 1 -> INFO 233c3\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:51642 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.560586373s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:33:22.237 UTC [grpc] infof -> DEBU 233c4\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.277 UTC [comm.grpc.server] 1 -> INFO 233c5\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.040016687s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.304 UTC [comm.grpc.server] 1 -> INFO 233c6\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.654577822s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.304 UTC [comm.grpc.server] 1 -> INFO 233c7\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m18.515607886s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.304 UTC [comm.grpc.server] 1 -> INFO 233c8\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m17.226306424s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.304 UTC [comm.grpc.server] 1 -> INFO 233c9\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m19.674182425s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.304 UTC [comm.grpc.server] 1 -> INFO 233ca\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m18.178107708s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:26.304 UTC [comm.grpc.server] 1 -> INFO 233cb\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:47370 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m19.173332202s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:33:26.304 UTC [grpc] infof -> DEBU 233cc\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:36.134 UTC [comm.grpc.server] 1 -> INFO 233cd\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.791262ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:33:49.811 UTC [comm.grpc.server] 1 -> INFO 233ce\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=758.384µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:34:36.140 UTC [comm.grpc.server] 1 -> INFO 233cf\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.83082ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:34:49.831 UTC [comm.grpc.server] 1 -> INFO 233d0\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.312402ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:35:36.151 UTC [comm.grpc.server] 1 -> INFO 233d1\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.640798ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:35:49.790 UTC [comm.grpc.server] 1 -> INFO 233d2\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=873.037µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:36.177 UTC [comm.grpc.server] 1 -> INFO 233d3\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.835599ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.706 UTC [comm.grpc.server] 1 -> INFO 233d4\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.143816995s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.721 UTC [comm.grpc.server] 1 -> INFO 233d5\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.15906958s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.722 UTC [comm.grpc.server] 1 -> INFO 233d6\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.195358335s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.722 UTC [comm.grpc.server] 1 -> INFO 233d8\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.195904378s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.723 UTC [comm.grpc.server] 1 -> INFO 233da\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.160560485s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.723 UTC [comm.grpc.server] 1 -> INFO 233db\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.194484939s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.722 UTC [comm.grpc.server] 1 -> INFO 233d7\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.159014087s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.722 UTC [comm.grpc.server] 1 -> INFO 233d9\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.196558571s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:36:42.723 UTC [grpc] infof -> DEBU 233dc\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.723 UTC [comm.grpc.server] 1 -> INFO 233dd\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43454 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.237032508s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.824 UTC [comm.grpc.server] 1 -> INFO 233de\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.043044506s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:36:42.841 UTC [grpc] infof -> DEBU 233df\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.841 UTC [comm.grpc.server] 1 -> INFO 233e0\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.278698034s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.842 UTC [comm.grpc.server] 1 -> INFO 233e1\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.278775657s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.842 UTC [comm.grpc.server] 1 -> INFO 233e2\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m13.589547747s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.842 UTC [comm.grpc.server] 1 -> INFO 233e3\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.316286119s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.842 UTC [comm.grpc.server] 1 -> INFO 233e4\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.279694809s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:42.843 UTC [comm.grpc.server] 1 -> INFO 233e5\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:43462 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.061585765s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:36:49.804 UTC [comm.grpc.server] 1 -> INFO 233e6\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.309813ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:37:36.284 UTC [comm.grpc.server] 1 -> INFO 233e7\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.490681ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:37:49.810 UTC [comm.grpc.server] 1 -> INFO 233e8\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=907.374µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:38:36.445 UTC [comm.grpc.server] 1 -> INFO 233e9\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.73318ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:38:49.816 UTC [comm.grpc.server] 1 -> INFO 233ea\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.110643ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:39:36.444 UTC [comm.grpc.server] 1 -> INFO 233eb\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.492514ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:39:49.937 UTC [comm.grpc.server] 1 -> INFO 233ec\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=4.215701ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:40:02.242 UTC [comm.grpc.server] 1 -> INFO 233ed\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:41120 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.065873846s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:40:02.268 UTC [comm.grpc.server] 1 -> INFO 233ee\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:41120 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.091675663s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:40:02.269 UTC [grpc] infof -> DEBU 233ef\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:40:36.490 UTC [comm.grpc.server] 1 -> INFO 233f0\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.936306ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:40:49.936 UTC [comm.grpc.server] 1 -> INFO 233f1\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=905.151µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:41:36.467 UTC [comm.grpc.server] 1 -> INFO 233f2\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.091008ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:41:49.952 UTC [comm.grpc.server] 1 -> INFO 233f3\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=5.896656ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:42:36.478 UTC [comm.grpc.server] 1 -> INFO 233f4\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.936318ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:42:49.951 UTC [comm.grpc.server] 1 -> INFO 233f5\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=941.888µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.435 UTC [comm.grpc.server] 1 -> INFO 233f6\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.064745552s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.459 UTC [comm.grpc.server] 1 -> INFO 233f7\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.088487978s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.461 UTC [comm.grpc.server] 1 -> INFO 233f8\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.088902448s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.461 UTC [comm.grpc.server] 1 -> INFO 233fa\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.089757638s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.461 UTC [comm.grpc.server] 1 -> INFO 233f9\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.090802836s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.461 UTC [comm.grpc.server] 1 -> INFO 233fb\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.090022347s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:22.461 UTC [comm.grpc.server] 1 -> INFO 233fc\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56460 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.089221871s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:43:22.462 UTC [grpc] infof -> DEBU 233fd\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:23.011 UTC [comm.grpc.server] 1 -> INFO 233fe\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56464 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.045238595s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:23.016 UTC [comm.grpc.server] 1 -> INFO 233ff\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56464 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.049904887s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:23.017 UTC [comm.grpc.server] 1 -> INFO 23400\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56464 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.0513936s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:23.017 UTC [comm.grpc.server] 1 -> INFO 23401\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:56464 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.05034541s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:43:23.017 UTC [grpc] infof -> DEBU 23402\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:36.453 UTC [comm.grpc.server] 1 -> INFO 23403\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.48955ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:43:49.941 UTC [comm.grpc.server] 1 -> INFO 23404\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=850.848µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:44:36.451 UTC [comm.grpc.server] 1 -> INFO 23405\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.606986ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:44:49.925 UTC [comm.grpc.server] 1 -> INFO 23406\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.146664ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:45:36.481 UTC [comm.grpc.server] 1 -> INFO 23407\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.605881ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:45:49.955 UTC [comm.grpc.server] 1 -> INFO 23408\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=924.346µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:36.575 UTC [comm.grpc.server] 1 -> INFO 23409\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.729378ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:43.361 UTC [comm.grpc.server] 1 -> INFO 2340a\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:52604 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.137801712s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:43.380 UTC [comm.grpc.server] 1 -> INFO 2340b\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:52604 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.157753462s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:43.381 UTC [comm.grpc.server] 1 -> INFO 2340c\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:52604 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.147460369s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:43.381 UTC [comm.grpc.server] 1 -> INFO 2340d\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:52604 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.147467712s'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:43.381 UTC [comm.grpc.server] 1 -> INFO 2340e\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:52604 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.147632739s'
  },
  {
    content:
      '\u001b[36m2024-01-22 07:46:43.382 UTC [grpc] infof -> DEBU 2340f\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:46:49.937 UTC [comm.grpc.server] 1 -> INFO 23410\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.430552ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:47:36.702 UTC [comm.grpc.server] 1 -> INFO 23411\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.566033ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:47:49.936 UTC [comm.grpc.server] 1 -> INFO 23412\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.715546ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:48:37.192 UTC [comm.grpc.server] 1 -> INFO 23413\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.294641ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:48:49.947 UTC [comm.grpc.server] 1 -> INFO 23414\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=703.154µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:49:37.407 UTC [comm.grpc.server] 1 -> INFO 23415\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.889151ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:49:49.943 UTC [comm.grpc.server] 1 -> INFO 23416\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=899.867µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:50:37.646 UTC [comm.grpc.server] 1 -> INFO 23417\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.238156ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:50:49.954 UTC [comm.grpc.server] 1 -> INFO 23418\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.433236ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:51:37.702 UTC [comm.grpc.server] 1 -> INFO 23419\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.326687ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:51:49.946 UTC [comm.grpc.server] 1 -> INFO 2341a\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=964.236µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:52:37.731 UTC [comm.grpc.server] 1 -> INFO 2341b\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.595001ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:52:49.962 UTC [comm.grpc.server] 1 -> INFO 2341c\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.024536ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:53:37.715 UTC [comm.grpc.server] 1 -> INFO 2341d\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.127655ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:53:49.973 UTC [comm.grpc.server] 1 -> INFO 2341e\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.956879ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:54:37.731 UTC [comm.grpc.server] 1 -> INFO 2341f\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.632607ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:54:49.981 UTC [comm.grpc.server] 1 -> INFO 23420\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=983.987µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:55:37.801 UTC [comm.grpc.server] 1 -> INFO 23421\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.440316ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:55:49.964 UTC [comm.grpc.server] 1 -> INFO 23422\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.719749ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:56:37.717 UTC [comm.grpc.server] 1 -> INFO 23423\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=5.901659ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:56:50.008 UTC [comm.grpc.server] 1 -> INFO 23424\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=736.338µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:57:37.742 UTC [comm.grpc.server] 1 -> INFO 23425\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.590134ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:57:49.956 UTC [comm.grpc.server] 1 -> INFO 23426\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.130982ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:58:37.714 UTC [comm.grpc.server] 1 -> INFO 23427\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.454124ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:58:49.946 UTC [comm.grpc.server] 1 -> INFO 23428\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.276954ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:59:37.737 UTC [comm.grpc.server] 1 -> INFO 23429\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.785095ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 07:59:49.953 UTC [comm.grpc.server] 1 -> INFO 2342a\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.063831ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:07.619 UTC [endorser] callChaincode -> INFO 2342b\u001b[0m finished chaincode: qscc duration: 4ms channel=byou-consent txID=37889ca6'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:07.620 UTC [comm.grpc.server] 1 -> INFO 2342c\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=10.573402ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:08.028 UTC [endorser] callChaincode -> INFO 2342d\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-holder txID=381e642e'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:08.028 UTC [comm.grpc.server] 1 -> INFO 2342e\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.684339ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:08.672 UTC [endorser] callChaincode -> INFO 2342f\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-system txID=8a54f671'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:08.673 UTC [comm.grpc.server] 1 -> INFO 23430\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.549811ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:09.059 UTC [endorser] callChaincode -> INFO 23431\u001b[0m finished chaincode: qscc duration: 0ms channel=banji txID=09a9c7c4'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:09.059 UTC [comm.grpc.server] 1 -> INFO 23432\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.807503ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:09.798 UTC [endorser] callChaincode -> INFO 23433\u001b[0m finished chaincode: qscc duration: 0ms channel=contractapi txID=cfcc53b6'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:09.798 UTC [comm.grpc.server] 1 -> INFO 23434\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.279858ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:10.416 UTC [endorser] callChaincode -> INFO 23435\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-verifier txID=36c7b5b4'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:10.416 UTC [comm.grpc.server] 1 -> INFO 23436\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.883451ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:10.900 UTC [endorser] callChaincode -> INFO 23437\u001b[0m finished chaincode: qscc duration: 0ms channel=archive-api txID=458b392f'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:10.901 UTC [comm.grpc.server] 1 -> INFO 23438\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.481268ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:11.393 UTC [endorser] callChaincode -> INFO 23439\u001b[0m finished chaincode: qscc duration: 1ms channel=towerconnect txID=05fa6db9'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:11.393 UTC [comm.grpc.server] 1 -> INFO 2343a\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.649913ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:11.832 UTC [endorser] callChaincode -> INFO 2343b\u001b[0m finished chaincode: qscc duration: 0ms channel=breceipt txID=2bd3b0bb'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:11.832 UTC [comm.grpc.server] 1 -> INFO 2343c\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.219523ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:12.326 UTC [endorser] callChaincode -> INFO 2343d\u001b[0m finished chaincode: qscc duration: 1ms channel=bmessage txID=55d50973'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:12.327 UTC [comm.grpc.server] 1 -> INFO 2343e\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.652492ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:12.363 UTC [endorser] callChaincode -> INFO 2343f\u001b[0m finished chaincode: edexa-wormhole duration: 2ms channel=master-channel txID=e21d0aed'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:12.364 UTC [comm.grpc.server] 1 -> INFO 23440\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=6.295662ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:13.143 UTC [endorser] callChaincode -> INFO 23441\u001b[0m finished chaincode: qscc duration: 0ms channel=master-channel txID=29efa0c4'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:13.148 UTC [comm.grpc.server] 1 -> INFO 23442\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=5.696562ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:14.547 UTC [gossip.privdata] StoreBlock -> INFO 23443\u001b[0m Received block [4946] from buffer channel=master-channel'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:14.554 UTC [committer.txvalidator] Validate -> INFO 23444\u001b[0m [master-channel] Validated block [4946] in 6ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:14.648 UTC [kvledger] CommitLegacy -> INFO 23445\u001b[0m [master-channel] Committed block [4946] with 1 transaction(s) in 93ms (state_validation=0ms block_and_pvtdata_commit=7ms state_commit=79ms) commitHash=[e05774a12126edbdd45494a47af2440b5cc1cf54730f0c04edffbdb3635003fd]'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:37.725 UTC [comm.grpc.server] 1 -> INFO 23446\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.654062ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:00:49.948 UTC [comm.grpc.server] 1 -> INFO 23447\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.096453ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:01:37.713 UTC [comm.grpc.server] 1 -> INFO 23448\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.997998ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:01:49.958 UTC [comm.grpc.server] 1 -> INFO 23449\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.433105ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:02:37.765 UTC [comm.grpc.server] 1 -> INFO 2344a\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.106491ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:02:49.961 UTC [comm.grpc.server] 1 -> INFO 2344b\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=779.093µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:21.633 UTC [comm.grpc.server] 1 -> INFO 2344d\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:42342 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m14.353758204s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:21.633 UTC [comm.grpc.server] 1 -> INFO 2344c\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:42332 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m14.353581043s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:03:21.634 UTC [grpc] infof -> DEBU 2344e\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:03:21.634 UTC [grpc] infof -> DEBU 2344f\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:27.881 UTC [comm.grpc.server] 1 -> INFO 23450\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48798 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.006865243s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:27.889 UTC [comm.grpc.server] 1 -> INFO 23451\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48798 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m19.046598119s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:27.889 UTC [comm.grpc.server] 1 -> INFO 23452\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48798 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m18.522081245s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:03:27.889 UTC [grpc] infof -> DEBU 23453\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:27.890 UTC [comm.grpc.server] 1 -> INFO 23454\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48798 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m18.035772273s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:27.890 UTC [comm.grpc.server] 1 -> INFO 23455\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48798 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.904246213s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:27.890 UTC [comm.grpc.server] 1 -> INFO 23456\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48798 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m15.972302536s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:29.776 UTC [comm.grpc.server] 1 -> INFO 23457\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48808 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.363414315s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:29.856 UTC [comm.grpc.server] 1 -> INFO 23458\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48808 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m19.946782472s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:03:29.857 UTC [grpc] infof -> DEBU 23459\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:29.857 UTC [comm.grpc.server] 1 -> INFO 2345a\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48808 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m17.835801269s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:29.857 UTC [comm.grpc.server] 1 -> INFO 2345b\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:48808 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m18.799602972s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:38.140 UTC [comm.grpc.server] 1 -> INFO 2345c\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.387029ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:03:49.963 UTC [comm.grpc.server] 1 -> INFO 2345d\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.03565ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:04:38.304 UTC [comm.grpc.server] 1 -> INFO 2345e\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.89898ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:04:49.952 UTC [comm.grpc.server] 1 -> INFO 2345f\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=898.362µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:05:38.500 UTC [comm.grpc.server] 1 -> INFO 23460\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.943406ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:05:50.847 UTC [comm.grpc.server] 1 -> INFO 23461\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=913.29µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:38.526 UTC [comm.grpc.server] 1 -> INFO 23462\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=6.51631ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:42.983 UTC [comm.grpc.server] 1 -> INFO 23463\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.062806217s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.037 UTC [comm.grpc.server] 1 -> INFO 23464\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.117172165s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.039 UTC [comm.grpc.server] 1 -> INFO 23466\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.174988381s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.039 UTC [comm.grpc.server] 1 -> INFO 23465\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.11837981s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.040 UTC [comm.grpc.server] 1 -> INFO 23467\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.17485542s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.040 UTC [comm.grpc.server] 1 -> INFO 23468\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.174210077s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.040 UTC [comm.grpc.server] 1 -> INFO 23469\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.173303393s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:43.040 UTC [comm.grpc.server] 1 -> INFO 2346a\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:46422 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m16.172891889s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:06:43.040 UTC [grpc] infof -> DEBU 2346b\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:06:50.821 UTC [comm.grpc.server] 1 -> INFO 2346c\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.235538ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:07:38.519 UTC [comm.grpc.server] 1 -> INFO 2346d\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=4.510092ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:07:50.821 UTC [comm.grpc.server] 1 -> INFO 2346e\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.046925ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:08:38.625 UTC [comm.grpc.server] 1 -> INFO 2346f\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.828299ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:08:50.826 UTC [comm.grpc.server] 1 -> INFO 23470\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.092426ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:09:38.526 UTC [comm.grpc.server] 1 -> INFO 23471\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.833112ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:09:50.835 UTC [comm.grpc.server] 1 -> INFO 23472\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=785.301µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.485 UTC [comm.grpc.server] 1 -> INFO 23473\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40146 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.16006214s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.500 UTC [comm.grpc.server] 1 -> INFO 23474\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40146 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.176120817s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.500 UTC [comm.grpc.server] 1 -> INFO 23476\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40146 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.175206753s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.500 UTC [comm.grpc.server] 1 -> INFO 23475\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40146 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.17615482s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.500 UTC [comm.grpc.server] 1 -> INFO 23477\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40146 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.17669849s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:10:03.501 UTC [grpc] infof -> DEBU 23478\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.501 UTC [comm.grpc.server] 1 -> INFO 23479\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40146 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.17574476s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.543 UTC [comm.grpc.server] 1 -> INFO 2347a\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.127487431s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.578 UTC [comm.grpc.server] 1 -> INFO 2347b\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.162442623s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.580 UTC [comm.grpc.server] 1 -> INFO 2347e\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.164592427s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.580 UTC [comm.grpc.server] 1 -> INFO 2347c\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.16262627s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.580 UTC [comm.grpc.server] 1 -> INFO 2347f\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.164694595s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:10:03.581 UTC [grpc] infof -> DEBU 23480\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.580 UTC [comm.grpc.server] 1 -> INFO 2347d\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.163757935s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:03.581 UTC [comm.grpc.server] 1 -> INFO 23481\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:40156 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.164386667s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:38.660 UTC [comm.grpc.server] 1 -> INFO 23482\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.516732ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:10:50.851 UTC [comm.grpc.server] 1 -> INFO 23483\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=913.801µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:11:38.657 UTC [comm.grpc.server] 1 -> INFO 23484\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.020298ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:11:50.891 UTC [comm.grpc.server] 1 -> INFO 23485\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=832.717µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:12:38.658 UTC [comm.grpc.server] 1 -> INFO 23486\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.796017ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:12:50.854 UTC [comm.grpc.server] 1 -> INFO 23487\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.169608ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.574 UTC [comm.grpc.server] 1 -> INFO 23488\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.851540487s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.617 UTC [comm.grpc.server] 1 -> INFO 23489\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.893611025s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.623 UTC [comm.grpc.server] 1 -> INFO 2348a\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.900734197s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:13:23.623 UTC [grpc] infof -> DEBU 2348b\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.623 UTC [comm.grpc.server] 1 -> INFO 2348c\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.902530092s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.624 UTC [comm.grpc.server] 1 -> INFO 2348e\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.901850107s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.624 UTC [comm.grpc.server] 1 -> INFO 2348f\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.90348549s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:23.623 UTC [comm.grpc.server] 1 -> INFO 2348d\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:45520 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.901800642s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:38.666 UTC [comm.grpc.server] 1 -> INFO 23490\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.101889ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:13:50.879 UTC [comm.grpc.server] 1 -> INFO 23491\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=995.485µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:14:38.725 UTC [comm.grpc.server] 1 -> INFO 23492\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.976003ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:14:50.900 UTC [comm.grpc.server] 1 -> INFO 23493\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.232817ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:15:38.739 UTC [comm.grpc.server] 1 -> INFO 23494\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.481941ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:15:50.935 UTC [comm.grpc.server] 1 -> INFO 23495\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.100735ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:38.738 UTC [comm.grpc.server] 1 -> INFO 23496\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.566001ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.146 UTC [comm.grpc.server] 1 -> INFO 23497\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.085053448s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.205 UTC [comm.grpc.server] 1 -> INFO 23498\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.143211058s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:16:43.205 UTC [grpc] infof -> DEBU 23499\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.205 UTC [comm.grpc.server] 1 -> INFO 2349a\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.143499682s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.205 UTC [comm.grpc.server] 1 -> INFO 2349b\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.144397022s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.205 UTC [comm.grpc.server] 1 -> INFO 2349c\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.144736517s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.206 UTC [comm.grpc.server] 1 -> INFO 2349d\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.144931448s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.206 UTC [comm.grpc.server] 1 -> INFO 2349e\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.144241308s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.206 UTC [comm.grpc.server] 1 -> INFO 2349f\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39174 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.14415414s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.614 UTC [comm.grpc.server] 1 -> INFO 234a0\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39180 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.18355602s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.646 UTC [comm.grpc.server] 1 -> INFO 234a1\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39180 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.21531982s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:43.646 UTC [comm.grpc.server] 1 -> INFO 234a2\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39180 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.216008016s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:16:43.647 UTC [grpc] infof -> DEBU 234a3\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:16:50.917 UTC [comm.grpc.server] 1 -> INFO 234a4\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.078312ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:17:38.736 UTC [comm.grpc.server] 1 -> INFO 234a5\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.64623ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:17:51.397 UTC [comm.grpc.server] 1 -> INFO 234a6\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=920.582µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:18:38.971 UTC [comm.grpc.server] 1 -> INFO 234a7\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.831593ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:18:51.379 UTC [comm.grpc.server] 1 -> INFO 234a8\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.560022ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:19:39.149 UTC [comm.grpc.server] 1 -> INFO 234a9\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.143455ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:19:51.390 UTC [comm.grpc.server] 1 -> INFO 234aa\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.612062ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:04.006 UTC [comm.grpc.server] 1 -> INFO 234ab\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:38094 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.100341809s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:04.017 UTC [comm.grpc.server] 1 -> INFO 234ac\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:38094 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.111906831s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:04.018 UTC [comm.grpc.server] 1 -> INFO 234ad\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:38094 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.112734442s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:04.018 UTC [comm.grpc.server] 1 -> INFO 234ae\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:38094 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.111521206s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:04.018 UTC [comm.grpc.server] 1 -> INFO 234af\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:38094 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.111353004s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:20:04.018 UTC [grpc] infof -> DEBU 234b0\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:39.275 UTC [comm.grpc.server] 1 -> INFO 234b1\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=11.193485ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:20:51.407 UTC [comm.grpc.server] 1 -> INFO 234b2\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=852.439µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:21:39.281 UTC [comm.grpc.server] 1 -> INFO 234b3\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.377585ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:21:51.390 UTC [comm.grpc.server] 1 -> INFO 234b4\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=795.818µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:22:39.245 UTC [comm.grpc.server] 1 -> INFO 234b5\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=5.392423ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:22:51.415 UTC [comm.grpc.server] 1 -> INFO 234b6\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.909487ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:23:39.265 UTC [comm.grpc.server] 1 -> INFO 234b7\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=3.910082ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:23:51.404 UTC [comm.grpc.server] 1 -> INFO 234b8\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.002178ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:24:39.472 UTC [comm.grpc.server] 1 -> INFO 234b9\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.797164ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:24:51.429 UTC [comm.grpc.server] 1 -> INFO 234ba\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.723603ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:25:39.439 UTC [comm.grpc.server] 1 -> INFO 234bb\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.17264ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:25:51.668 UTC [comm.grpc.server] 1 -> INFO 234bc\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=845.177µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:26:39.461 UTC [comm.grpc.server] 1 -> INFO 234bd\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.036663ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:26:51.686 UTC [comm.grpc.server] 1 -> INFO 234be\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.396925ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:27:39.447 UTC [comm.grpc.server] 1 -> INFO 234bf\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.358591ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:27:51.652 UTC [comm.grpc.server] 1 -> INFO 234c0\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=885.695µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:28:39.554 UTC [comm.grpc.server] 1 -> INFO 234c1\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.989561ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:28:52.252 UTC [comm.grpc.server] 1 -> INFO 234c2\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.468791ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:29:39.552 UTC [comm.grpc.server] 1 -> INFO 234c3\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.609403ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:29:51.662 UTC [comm.grpc.server] 1 -> INFO 234c4\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.082274ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:07.595 UTC [endorser] callChaincode -> INFO 234c5\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-consent txID=2f07d45d'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:07.596 UTC [comm.grpc.server] 1 -> INFO 234c6\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.059838ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:08.061 UTC [endorser] callChaincode -> INFO 234c7\u001b[0m finished chaincode: qscc duration: 1ms channel=byou-holder txID=8022df8a'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:08.062 UTC [comm.grpc.server] 1 -> INFO 234c8\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.122748ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:08.811 UTC [endorser] callChaincode -> INFO 234c9\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-system txID=524f5623'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:08.811 UTC [comm.grpc.server] 1 -> INFO 234ca\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.806934ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:09.368 UTC [endorser] callChaincode -> INFO 234cb\u001b[0m finished chaincode: qscc duration: 0ms channel=banji txID=7b9744db'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:09.369 UTC [comm.grpc.server] 1 -> INFO 234cc\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.969373ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:09.946 UTC [endorser] callChaincode -> INFO 234cd\u001b[0m finished chaincode: qscc duration: 0ms channel=contractapi txID=d213a1dd'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:09.946 UTC [comm.grpc.server] 1 -> INFO 234ce\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.316372ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:10.382 UTC [endorser] callChaincode -> INFO 234cf\u001b[0m finished chaincode: qscc duration: 0ms channel=byou-verifier txID=95c4b994'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:10.383 UTC [comm.grpc.server] 1 -> INFO 234d0\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.414013ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:10.951 UTC [endorser] callChaincode -> INFO 234d1\u001b[0m finished chaincode: qscc duration: 0ms channel=archive-api txID=5560b89c'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:10.951 UTC [comm.grpc.server] 1 -> INFO 234d2\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=1.492586ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:11.291 UTC [endorser] callChaincode -> INFO 234d3\u001b[0m finished chaincode: qscc duration: 0ms channel=towerconnect txID=59c834ce'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:11.291 UTC [comm.grpc.server] 1 -> INFO 234d4\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.083981ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:11.730 UTC [endorser] callChaincode -> INFO 234d5\u001b[0m finished chaincode: qscc duration: 0ms channel=breceipt txID=4de4143d'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:11.731 UTC [comm.grpc.server] 1 -> INFO 234d6\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=4.704419ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:12.479 UTC [endorser] callChaincode -> INFO 234d7\u001b[0m finished chaincode: qscc duration: 0ms channel=bmessage txID=641afdc6'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:12.482 UTC [comm.grpc.server] 1 -> INFO 234d8\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=6.330886ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:12.542 UTC [endorser] callChaincode -> INFO 234d9\u001b[0m finished chaincode: edexa-wormhole duration: 4ms channel=master-channel txID=443f150b'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:12.542 UTC [comm.grpc.server] 1 -> INFO 234da\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=7.727588ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:13.530 UTC [endorser] callChaincode -> INFO 234db\u001b[0m finished chaincode: qscc duration: 0ms channel=master-channel txID=a6eac5b5'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:13.531 UTC [comm.grpc.server] 1 -> INFO 234dc\u001b[0m unary call completed grpc.service=protos.Endorser grpc.method=ProcessProposal grpc.peer_address=172.31.22.180:40704 grpc.peer_subject="CN=edx0001-admin,OU=client" grpc.code=OK grpc.call_duration=2.964748ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:15.278 UTC [gossip.privdata] StoreBlock -> INFO 234dd\u001b[0m Received block [4947] from buffer channel=master-channel'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:15.284 UTC [committer.txvalidator] Validate -> INFO 234de\u001b[0m [master-channel] Validated block [4947] in 6ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:15.374 UTC [kvledger] CommitLegacy -> INFO 234df\u001b[0m [master-channel] Committed block [4947] with 1 transaction(s) in 88ms (state_validation=1ms block_and_pvtdata_commit=10ms state_commit=73ms) commitHash=[7e62129a8bef0b5d47970252d753fcb8e2077334e30257731355c083d83fd6a2]'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:39.585 UTC [comm.grpc.server] 1 -> INFO 234e0\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.639966ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:30:51.654 UTC [comm.grpc.server] 1 -> INFO 234e1\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.075761ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:31:39.840 UTC [comm.grpc.server] 1 -> INFO 234e2\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.741104ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:31:51.668 UTC [comm.grpc.server] 1 -> INFO 234e3\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=852.011µs'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:32:39.833 UTC [comm.grpc.server] 1 -> INFO 234e4\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=2.324934ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:32:51.662 UTC [comm.grpc.server] 1 -> INFO 234e5\u001b[0m unary call completed grpc.service=discovery.Discovery grpc.method=Discover grpc.peer_address=172.31.31.62:54844 grpc.peer_subject="CN=fabric-common" grpc.code=OK grpc.call_duration=1.298756ms'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:33:22.265 UTC [comm.grpc.server] 1 -> INFO 234e6\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:39878 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m14.69654246s'
  },
  {
    content:
      '\u001b[36m2024-01-22 08:33:22.265 UTC [grpc] infof -> DEBU 234e7\u001b[0m transport: loopyWriter.run returning. connection error: desc = "transport is closing"'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:33:28.095 UTC [comm.grpc.server] 1 -> INFO 234e8\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:54078 grpc.peer_subject="CN=edx0001-admin,OU=client" error="context finished before block retrieved: context canceled" grpc.code=Unknown grpc.call_duration=33m20.093897268s'
  },
  {
    content:
      '\u001b[34m2024-01-22 08:33:28.100 UTC [comm.grpc.server] 1 -> INFO 234e9\u001b[0m streaming call completed grpc.service=protos.Deliver grpc.method=Deliver grpc.peer_address=172.31.22.180:54078 grpc.peer_subject="CN=ed'
  }
]
