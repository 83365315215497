import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { viewFolderAPI } from 'actions/viewFolderAPI'
import { AddTemplateFolderModal } from 'components/folder'
import { TemplateBlock } from 'components/template'
import useComingSoonHook from 'hooks/UseComingSoonHook'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { removeTemplateAPI } from 'utils/common/helper/removeTemplate'
import { toastSuccess } from 'utils/commonFunctions'

const template = [
  {
    id: 'template_vfrnzlvvhh',
    disable: false,
    description:
      'The ERC-721 introduces a standard for NFT, in other words, this type of Token is unique and can have different value than another Token from the same Smart Contract, maybe due to its age, rarity or even something else like its visual.',
    name: 'ERC 721'
  },
  {
    id: 'template_m9l10mub3y',
    disable: false,
    description:
      'ERC-20 is Ethereum Request for Comment, number 20. ERC-20 is the standard for smart contract tokens created using Ethereum.',
    name: 'ERC 20'
  }
]

const FolderDetailsComponent = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const [open, setOpen] = useState(false)
  const { urlName } = useParams()
  const location: any = useLocation()
  useComingSoonHook()
  const [templateList, setTemplateList] = useState<any[]>(template)

  const viewFolderAction = () => {
    dispatch(setLoading(true))
    viewFolderAPI(location.state?.folderId).then((res) => {
      if (res.status === 200) {
        let newArry = res.data.repositoryChaincode.map((data) => {
          return { name: data.chainCodeTemplateId.name, id: data.id }
        })
        setTemplateList(newArry)
      }
      dispatch(setLoading(false))
    })
  }

  // useEffect(() => {
  //   if (location.state?.folderId) {
  //     viewFolderAction()
  //   }

  //   // eslint-disable-next-line
  // }, [location.state?.folderId])

  return (
    <div className="channelmanagement-wrapper">
      <Row>
        <Col md="12" lg="12" xl="8">
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="cursor-pointer"
                onClick={() => history('/universe/template-contact')}
              />
            </h5>
          </div>
        </Col>
      </Row>
      <Row className="mb-20">
        <Col lg="12">
          <div className="backdrop-block cloud-configuration-wrapper h-100 mb-0">
            <div
              className={`d-flex justify-content-between align-items-center ${
                templateList.length !== 0 && 'mb-20'
              }`}
            >
              <div className="title d-flex align-items-baseline">
                <h5 className="c-tx-primary f-600">My Folder</h5>{' '}
                <span className="f-600 mx-1 font-18"> / </span>
                <h5 className="c-tx-primary f-600">{urlName}</h5>
              </div>{' '}
              <Button
                type="button"
                onClick={(e) => {
                  // setOpen(true)
                  toastSuccess('Coming Soon')
                }}
                className="custom-primary"
              >
                Add Template
              </Button>
            </div>
            <Col lg="12">
              <div className="column_list px-2">
                {
                  templateList.length > 0
                    ? templateList.map((data: any, i) => (
                        <div className="element" key={i}>
                          <TemplateBlock data={data} type="folder" />
                        </div>
                      ))
                    : null // <NoFoundTemplate message="No Template Found" bageName="Template" logo={logo} />
                }
              </div>
            </Col>
          </div>
        </Col>
      </Row>
      <AddTemplateFolderModal
        setOpen={setOpen}
        open={open}
        handleAction={viewFolderAction}
      />
    </div>
  )
}

export default FolderDetailsComponent
