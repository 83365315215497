import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThankYou } from 'components/ThankyouPage'
import { FormikProps, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { setUserInfo } from 'store/user/action'
import { addNewUniverseRequest } from 'utils/common/helper/addNewUniverseRequest'
import { INetworkDomain } from 'utils/common/helper/get-network-domains'
import { toastSuccess } from 'utils/commonFunctions'
import { validationRegex } from 'utils/constants'
import * as Yup from 'yup'
import { StepperFirstTab } from './StepperFirstTab'
import { StepperLink } from './StepperLink'
import { StepperSettingTab } from './StepperSettingTab'
import { SteppersecondTab } from './SteppersecondTab'

const Check = <FontAwesomeIcon icon={faCheck} />

export function PrivateBlockchain({ toggle }: any) {
  const dispatch = useDispatch()
  /**
   * @stepper
   * @state_manage
   */
  const [currentStep, setCurrentStep] = useState(0)
  const handleNextClick = () => {
    if (currentStep === 3) return
    setCurrentStep(currentStep + 1)
  }
  const handleBackClick = () => {
    if (currentStep === 0) return
    setCurrentStep(currentStep - 1)
  }

  /**
   * @formik
   *
   */
  const [requestButtonClicked, setRequestButtonClicked] =
    useState(false)
  const edexaUniverseValidationSchema = Yup.object().shape({
    enviornmentDetails: Yup.object({
      name: Yup.string(),
      cloudType: Yup.string()
    }),
    networkDetails: Yup.object({
      name: Yup.string()
        .max(26, 'Name should not exceed 26 characters limit')
        .min(3, 'Name must be at least 3 characters long')
        .matches(
          validationRegex.onlyLowercase,
          'Only Lower Alphabets are Allowed'
        )
        .required('Name is Required'),
      missionStatement: Yup.string().required(
        'Mission Statement is Required'
      )
    }),
    homeRegion: Yup.string(),
    providerSettings: Yup.object({
      provider: Yup.string()
    })
  })
  const edexaUniverseFormik = useFormik({
    initialValues: {
      enviornmentDetails: {
        name: 'EDEXA COMMON ORG 00002',
        cloudType: 'domain_gkli1imova'
      },
      networkDetails: {
        name: '',
        missionStatement: ''
      },
      homeRegion: '',
      providerSettings: {
        provider: 'edeXa business Blockchain'
      }
    },
    validationSchema: edexaUniverseValidationSchema,
    onSubmit: (universeValues: any) => {
      if (!requestButtonClicked) return
      handleNextClick()
      localStorage.setItem('modal', 'true')
      // const makeAPIParams = {
      //   envName: universeValues.enviornmentDetails.name,
      //   type: universeValues.enviornmentDetails.cloudType,
      //   networkName: universeValues.networkDetails.name,
      //   networkDescription:
      //     universeValues.networkDetails.missionStatement,
      //   region: universeValues.homeRegion || 'rgn_tpsvrb0li8',
      //   provider: universeValues.providerSettings.provider,
      //   algorithmType: 'raft'
      // }

      // dispatch(setLoading(true))
      // addNewUniverseRequest(makeAPIParams).then((response: any) => {
      //   setRequestButtonClicked(false)
      //   dispatch(setLoading(false))
      //   dispatch(setUserInfo(true))
      //   toastSuccess(response.message)
      //   handleNextClick()
      // })
    }
  })

  const { errors, touched, setFieldValue } = edexaUniverseFormik

  // set network domains
  const [networkDomains, setNetworkDomains] = useState<
    INetworkDomain[]
  >([])

  const [QRcode, setQRCode] = useState(false)
  const [isNested, setNestedModal] = useState(false)

  useEffect(() => {
    return () => {} // eslint-disable-next-line
  }, [QRcode])

  const hideTopCloseButton = `
  .thankYouCloseButtonHide{display: none}
  `

  return (
    <>
      {/**
       * @steps
       */}
      {currentStep !== 3 && (
        <div className="stepper-link-wrapper">
          <ul className="list-step">
            <StepperLink
              title="2FA Setup"
              status={currentStep === 0 && 'active'}
              count="1"
              complete={currentStep > 0}
            />
            <StepperLink
              title="Blockchain setup"
              status={currentStep === 1 && 'active'}
              count="2"
              complete={currentStep > 1}
            />
            <StepperLink
              title="Deploy"
              status={currentStep === 2 && 'active'}
              count="3"
              complete={currentStep > 2}
            />
          </ul>
        </div>
      )}

      {/**
       * @form
       */}
      <div className="custom-card-wrapper">
        <div className="tab-1 tab-block h-100">
          {/**
           * @left_side
           * @stepper_form
           */}

          <div
            className={`left-block h-100 ${
              currentStep === 3 && 'w-100'
            }`}
          >
            {currentStep === 0 && (
              <>
                <StepperFirstTab
                  handleNextClick={handleNextClick}
                  QRcode={QRcode}
                  setQRCode={setQRCode}
                  formik={edexaUniverseFormik}
                  currentStep={currentStep}
                  networkDomains={networkDomains}
                />
                <Modal
                  isOpen={isNested}
                  toggle={() => setNestedModal(false)}
                  centered
                >
                  <ModalBody>
                    <div className="d-flex justify-content-end">
                      <FontAwesomeIcon
                        icon={faXmark}
                        onClick={() => setNestedModal(false)}
                        className="cursor-pointer c-green r-25 position-absolute"
                      />
                    </div>
                    <h2 className="fw-600 font-18 color-black1 text-center mb-0">
                      Enter Code
                    </h2>

                    <FormGroup className="m-0">
                      <Label for="evrifyCode">
                        Verification Code
                      </Label>
                      <Input
                        id="evrifyCode"
                        name="enviornmentDetails.name"
                        placeholder="Enter the Code"
                        type="number"
                        disabled
                        value="004578"
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleNextClick()
                        edexaUniverseFormik.handleSubmit()
                        edexaUniverseFormik.setFieldTouched(
                          'networkDetails.name',
                          false
                        )
                        edexaUniverseFormik.setFieldTouched(
                          'networkDetails.missionStatement',
                          false
                        )
                        setNestedModal(false)
                        setQRCode(false)
                      }}
                    >
                      Verify
                    </Button>{' '}
                  </ModalFooter>
                </Modal>
              </>
            )}
            {currentStep === 1 && (
              <SteppersecondTab
                formik={edexaUniverseFormik}
                currentStep={currentStep}
                handleNextClick={undefined}
              />
            )}
            {currentStep === 2 && (
              <StepperSettingTab
                formik={edexaUniverseFormik}
                currentStep={currentStep}
                handleNextClick={undefined}
              />
            )}
            {/* close icon on last screen */}
            {currentStep === 3 && (
              <FontAwesomeIcon
                onClick={() => toggle()}
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 'auto',
                  cursor: 'pointer'
                }}
                icon={faTimesCircle}
              />
            )}
            {currentStep === 3 && (
              <>
                <ThankYou />
                <style>{hideTopCloseButton}</style>
              </>
            )}
            {/* buttons */}
            <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
              {currentStep !== 3 && (
                <div className="d-inline-flex">
                  <button
                    className={`ext-small ${
                      currentStep === 0
                        ? 'custom-primary  disable'
                        : 'custom-primary-outline'
                    }`}
                    onClick={() => handleBackClick()}
                    disabled={currentStep === 0}
                    type="button"
                  >
                    Back
                  </button>
                </div>
              )}

              <div className="d-inline-flex">
                {currentStep === 0 && (
                  <button
                    type="button"
                    className="custom-primary"
                    onClick={() => {
                      handleNextClick()
                    }}
                  >
                    Next
                  </button>
                )}
                {currentStep === 1 && (
                  <button
                    type="button"
                    className="custom-primary ext-small"
                    onClick={() => {
                      edexaUniverseFormik.handleSubmit()
                      handleNextClick()
                    }}
                  >
                    Next
                  </button>
                )}
                {currentStep === 2 && (
                  <button
                    type="submit"
                    className="custom-primary ext-small"
                    onClick={() => {
                      setRequestButtonClicked(true)
                      edexaUniverseFormik.handleSubmit()
                    }}
                  >
                    DEPLOY
                  </button>
                )}
              </div>
            </div>
          </div>

          {/**
           * @right_side
           * @messages
           */}
          {currentStep !== 3 && (
            <div className="right-block">
              <div className="flex-bl">
                {/* tab 1 right portion start */}
                {currentStep === 0 && (
                  <>
                    <h5 className="tab-heading c-tx-primary f-700 ">
                      Your Blockchain Orgnization
                    </h5>
                    <p className="c-tx-ninth mb-2 small">
                      Set Up the Organization is your first step:
                    </p>
                    <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            Decide on the organization structure for
                            your network
                          </p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            Define the names and roles of the
                            organizations involved.
                          </p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            Each organization may represent a
                            participant or stakeholder in your private
                            blockchain.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <p className="c-tx-ninth mb-0 small lh-18">
                      In this free plan you can only add 1
                      organization. In case you can always scale
                      later.
                    </p>
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 1 right portion end */}

                {/* tab 2 right portion start */}
                {currentStep === 1 && (
                  <>
                    <h5 className="tab-heading c-tx-primary f-700 ">
                      Network Summary
                    </h5>
                    <p className="c-tx-ninth mb-2 small lh-18">
                      A network or "consortium" is a business network
                      comprised of a group of organizations and/or
                      multiple members who have a shared business
                      goal. At this step, you are tasked with
                      supplying the following pieces of
                      configurations:
                    </p>
                    <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            Name - Identity of the consortia
                          </p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            Mission - Optional prose or legalese
                            describing business purpose, bylaws,
                            objectives, etc.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <p className="c-tx-ninth mb-0 small lh-18">
                      All operations are in this tier are off-chain,
                      and include: cloud + region whitelisting
                      membership creation, certificate management,
                      governance, and network auditing.
                    </p>
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 2 right portion end */}
                {/* tab 3 right portion start */}

                {/* tab 3 right portion end */}

                {/* tab 4 right portion start */}
                {currentStep === 2 && (
                  <>
                    {' '}
                    <h5 className="tab-heading c-tx-primary f-700 ">
                      Minimum Recommended Configuration
                    </h5>
                    <p className="c-tx-ninth mb-2 small lh-18">
                      For reliable operation of your blockchain, edeXa
                      strongly recommends a minimum of 3 orderer nodes
                      for every channel :
                    </p>
                    <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            2 or more user-provided orderer nodes +
                            system monitor node, or
                          </p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            3 or more user-provided orderer nodes
                          </p>
                        </li>
                      </ul>
                    </div>
                    {/* <p className="c-tx-ninth mb-0 small lh-18">
                      The monitor node is free and automatically a part of all
                      environments.
                    </p> */}
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 4 right portion end */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

// formik initial values
type valuesErrorsTouched = {
  enviornmentDetails: {
    name: string
    cloudType: string
  }
  networkDetails: {
    name: string
    missionStatement: string
  }
  homeRegion: string
  providerSettings: {
    provider: string
  }
}
// formik type
export type StepperStepsPropsType = {
  formik: FormikProps<valuesErrorsTouched>
  currentStep: number
  networkDomains?: INetworkDomain[]
  handleNextClick: any
}
