import {
  getChannelListingAPI,
  IgetChannelListingAPIResData
} from 'actions/channelListingAPI'
import { NoFoundData } from 'components/notFoundPages'
import Pagination1 from 'components/pagination/Pagination'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import 'rc-pagination/assets/index.css'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table } from 'reactstrap'
import { setChannelDataToStoreInRedux } from 'store/channelData/action'
import { setLoading } from 'store/loader/action'
import { IRootReducer } from 'store/root-reducer'
import {
  getLocalStorage,
  socketConnection
} from 'utils/commonFunctions'
import AddParticipatModalComponent from '../addParticipant/AddParticipatModalComponent'
import ChannelListingComponent from './ChannelListingComponent'
const ChannelManagementTableHeader = [
  'NO.',
  'NAME',
  'PARTICIPANTS',
  'CHANNEL HEIGHT'
]

export interface IPaginationConfig {
  search: string
  page: number
  limit: number
}
interface IChannel {
  channelId: string
  requestName: string
  totalParticipants: number
}
const ChannelManagementTable = () => {
  const oldValue = getLocalStorage('channelName')
  const channels = oldValue?.map((obj: any) => ({
    id: 'chl_495fiiicz8',
    channelName: obj, // Using obj.channelName from oldValue
    totalParticipate: 1,
    channelHeight: 0
  }))
  const dispatch = useDispatch()
  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )

  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [channelData, setChannelData] = useState<any[]>(channels)
  const [paginationConfig, setPaginationConfig] = useState({
    search: '',
    page: 1,
    limit: 10
  })

  const GetChannelListingAPI = useCallback(() => {
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    }
    if (paginationConfig.search) {
      params.search = paginationConfig.search
    }
    // useGetChannelListHook();
    dispatch(setLoading(true))
    getChannelListingAPI(params).then((res: any) => {
      if (res.status === 200) {
        setTotalCount(res.data?.count)
        setChannelData(res.data?.data)
        dispatch(setChannelDataToStoreInRedux(res.data?.data))
      }
      dispatch(setLoading(false))
    })
  }, [
    dispatch,
    paginationConfig.limit,
    paginationConfig.page,
    paginationConfig.search
  ])

  // useEffect(() => {
  //   // GetChannelListingAPI()
  //   // eslint-disable-next-line
  // }, [oldValue])

  // const handleSerach = (str: string) => {
  //   setPaginationConfig({ ...paginationConfig, search: str })
  // }

  // useDebouncedEffect(() => handleSerach(search), [search], 500)

  const handleSocket = (data: IChannel) => {
    setChannelData((prvState) => {
      const newState = [...prvState].map((item) => {
        if (item?.id === data?.channelId) {
          return {
            ...item,
            totalParticipate: Number(data?.totalParticipants)
          }
        }
        return item
      })
      return newState
    })
  }

  // useEffect(() => {
  //   if (user?.id) {
  //     socketConnection?.on(`Channel_create_${user?.id}`, () => {
  //       if (paginationConfig?.page === 1) {
  //         GetChannelListingAPI()
  //       } else {
  //         setPaginationConfig({ ...paginationConfig, page: 1 })
  //       }
  //     })
  //     socketConnection?.on(
  //       `Channel_participants_${user?.id}`,
  //       (data: IChannel) => {
  //         handleSocket(data)
  //       }
  //     )
  //     return () => {
  //       socketConnection.off(`Channel_create_${user?.id}`)
  //       socketConnection.off(`Channel_participants_${user?.id}`)
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [user?.id])

  return (
    <div className="backdrop-block">
      <div className="channel-wrapper">
        <div className="title d-sm-flex justify-content-between">
          <h5 className="c-tx-primary f-600">Channels</h5>
          <Input
            className="select-component"
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            type="email"
          />
        </div>
        <div className="tbb">
          <div className="ct-table channel-table">
            <Table className="position-relative">
              <thead className="table-sticky">
                <tr>
                  {ChannelManagementTableHeader.map(
                    (data: string, i: number) => (
                      <th key={i}>{data}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {channelData.length > 0 ? (
                  channelData.map((data, i: number) => (
                    <ChannelListingComponent
                      key={i}
                      data={data}
                      index={i}
                      limit={paginationConfig.limit}
                      page={paginationConfig.page}
                      setModal={setModal}
                      setId={setId}
                    />
                  ))
                ) : (
                  <NoFoundData
                    columnCount={ChannelManagementTableHeader.length}
                  />
                )}
              </tbody>
            </Table>
          </div>
          <Pagination1
            paginationConfig={paginationConfig}
            setPaginationConfig={setPaginationConfig}
            totalCount={totalCount}
          />
        </div>
      </div>
      <AddParticipatModalComponent
        modal={modal}
        setModal={setModal}
        id={id}
      />
    </div>
  )
}

export default ChannelManagementTable
