import { faFileArchive } from '@fortawesome/free-regular-svg-icons'
import {
  faFileCircleExclamation,
  faFileContract,
  faFileSignature
} from '@fortawesome/free-solid-svg-icons'

export const defaultCountryCode = 'ch'
export const toasterPosition = 'bottom-left'
export const cookieExpiresInDays = 7
export const onlyCharacterRegx = /^[a-zA-Z]+$/
export const defualtAppLogo =
  'https://account-files-bucket.s3.ap-south-1.amazonaws.com/logo/x.png'

export const OTP_REQUEST_METHOD = {
  CREATE: 'create',
  UPDATE: 'update',
  GET: 'get',
  DELETE: 'delete'
}

// default times and value
export const timingForGraphArray = [
  {
    label: '5 min',
    value: 300000
  },
  {
    label: '15 Min',
    value: 900000
  },
  {
    label: '30 Min',
    value: 1800000
  },
  {
    label: '60 Min',
    value: 3600000
  },
  {
    label: '2 Hrs',
    value: 7200000
  },
  {
    label: '6 Hrs',
    value: 21600000
  },
  {
    label: '12 Hrs',
    value: 43200000
  },
  {
    label: '1 Day',
    value: 86400000
  }
]

export const timingForMetricGraphArray = [
  {
    label: '5 min',
    value: 300000
  },
  {
    label: '15 Min',
    value: 900000
  },
  {
    label: '30 Min',
    value: 1800000
  },
  {
    label: '60 Min',
    value: 3600000
  },
  {
    label: '2 Hrs',
    value: 7200000
  },
  {
    label: '1 Day',
    value: 86400000
  }
]

export enum TokenEngineFORMEnum {
  LOADING = 'loading',
  SUCCESS = 'success',
  INITSTATE = 'init_state'
}

export const MODULE_SLUG = {
  CHANNEL: 'Channel',
  CHAINCODE: 'Chaincode',
  NETWORK: 'Network'
}

export const TEMPLATE_KEY = {
  spclAlphaNumeric: 'spclAlphaNumeric',
  dynamic: 'dynamic',
  text: 'text',
  number: 'number'
}

export const SmartContractListingEnum = {
  UPLOAD: 'Upload',
  INSTALL: 'Install'
}

export const ReactSelectCustomstyle = {
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#4c4f53' // Custom colour
  }),
  control: (base: any) => ({
    ...base,
    borderColor: '#dadce0 !important',
    height: 50,
    minHeight: 50,
    // This line disable the blue border
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#dadce0 !important'
    }
  })
}

export const universeStatus = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  INPROGREESS: 3
}

export const initialPaginationConfigs = {
  search: '',
  page: 1,
  limit: 5,
  filter: ''
}

export const dateFormat = 'DD-MM-YYYY | h:mm A'

export const items = [
  {
    mainTitle:
      'Building the future ecosystem based on <strong>trust</strong>, <strong>security</strong> and <strong>transparency</strong>',
    subTitle:
      'edeXa Universe is the advanced next-generation of networks. We make it simple for innovative businesses to adopt blockchain technology.',
    key: 0
  },
  {
    mainTitle: '<strong>Ecosystem</strong>',
    subTitle:
      'Powerful, secure and energy-efficient Business Blockchain and giving the Build tools on a decentralized base with secure foundation.',
    key: 1
  },
  {
    mainTitle: '<strong>EDX DApps</strong>',
    subTitle:
      'This new ecosystem represents the exploration from users to be able to find products they want to engage with and that address an easy way to obtain that key to their growth project.',
    key: 2
  }
]

// qr code generator config
export const qrCodeConfig = {
  ecLevel: 'M',
  size: 230,
  qrStyle: 'squares',
  fgColor: '#0d0f12',
  logoHeightWidth: 70
}

// kyc documents allowed file size
// 5 mb validation
export const KycDocumentMaxFileSize = 5 * 1024 * 1024
// 300 kb
export const KycDocumentMinFileSize = 300 * 1024

// types of the kyc documents
export const kycDocumentTypes = {
  passport: 'passport',
  identityCard: 'identity_card',
  drivingLicenece: 'driving_licence',
  other: 'other'
}
// documents number verification regular expressions
// ^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$ - indian passport validation string
// https://www.geeksforgeeks.org/how-to-validate-indian-passport-number-using-regular-expression/
export const kycDocumentsNumberVerificationRegex = {
  passport: /^(?!^0+$)[A-Z0-9]{9,9}$/, //minimum 9 and max 9, allows alphabats and numbers as per international standards
  // validate if its a number or capital letter
  // accepts capital letters, minimum 3 and max 15
  identityCard: /^[A-Z0-9]{3,15}$/,
  // https://www.geeksforgeeks.org/how-to-validate-indian-driving-license-number-using-regular-expression/
  // this regex will validate indian driving license
  // example - UP14 20160034761 :=: [State name][state code than] [license year][last 7 is license number]
  drivingLicenece:
    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  // regex to validate if its a number or an capital letter alphabet
  other: /^[A-Z0-9]{3,15}$/
}

export const validationRegex = {
  onlyCharacterNoSpaceRegex: /^[a-zA-Z][a-zA-Z ]+/,
  onlyNumberRegex: /^[0-9]*$/,
  onlyNumberAndCharRegex: /^[A-Z0-9]/,
  onlyCharacterWithLimit: /^[a-zA-z]+([\s][a-zA-Z]+)*$/,
  onlyHttpsRegex: /^https:\/\//,
  onlyLowercase: /^[a-z0-9]+$/
}

export const localStorageKeys = {
  isLoggedIN: 'isLoggedIN',
  userToken: 'userToken',
  cookieToken: 'cookieToken',
  deviceId: 'deviceId',
  networkInfo: 'networkInfo',
  universeLogs: 'universeLogs',
  authenticateToken: 'authenticateToken'
}

export const cookieKeys = {
  cryptoSecretKey: 'edexaUser',
  cookieInitial: 'edexa',
  cookieUser: 'CookieUser'
}

export const validationMessages = {
  email: {
    required: 'E-Mail is required',
    invalid: 'Enter a valid email'
  },
  password: {
    required: 'Password is required',
    matches:
      'Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character'
  },
  regFullName: {
    required: 'Full Name is required'
  },
  termsConditions: {
    required: 'Terms of serive is required'
  },
  formInvalid: 'Please fill in all the required fields.',
  invalidFile: 'Please upload only images',
  currentPassword: {
    required: 'Current password is required'
  },
  oldPassword: {
    required: 'Old password is required'
  },
  newPassword: {
    required: 'New password is required',
    notSame: 'Old password and new password cannot be same'
  },
  confirmPassword: {
    required: 'Confirm new password is required',
    mustMatch: 'New password and confirm new password mismatched'
  },
  onlyCharacters: 'Only alphabets are allowed for this field',
  onlyNumbers: 'Only numbers are allowed for this field',
  phone: {
    required: 'Phone number is required'
  },
  otp: {
    required: 'OTP number is required'
  }
}

export const rejectionReasons = [
  'Name in the Photo ID and the Name in edeXa account do not match',
  'Date of birth in the Photo id and the Date of birth in the kyc form donot match',
  'Country of issuing authority of the Photo ID is different from the nationality of the applicant',
  'Resolution of the picture is too low to confirm the information',
  'Other'
]

export const editFormDefaultValues = {
  gender: [
    { name: 'Male', checked: true },
    { name: 'Female', checked: false },
    { name: 'Other', checked: false }
  ],
  year: { min: 1950, max: new Date().getFullYear() },
  monthList: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
}

export const toasterMessages = {
  comingSoon: 'Coming Soon',
  fillAllRequireField: 'Please fill all required field.',
  chooseAttachmentFile: 'Please choose an attachment'
}

export const applicationRating = [
  { id: 38, rating: 5, review: 2000, reviewCount: 158 },
  { id: 40, rating: 5, review: 1200, reviewCount: 211 },
  { id: 11, rating: 4, review: 1800, reviewCount: 193 },
  { id: 31, rating: 5, review: 2200, reviewCount: 143 },
  { id: 32, rating: 4, review: 2000, reviewCount: 121 },
  { id: 29, rating: 5, review: 1200, reviewCount: 213 },
  { id: 19, rating: 4, review: 1800, reviewCount: 146 },
  { id: 45, rating: 4, review: 2200, reviewCount: 195 },
  { id: 44, rating: 4, review: 2100, reviewCount: 185 },
  { id: 43, rating: 4, review: 2100, reviewCount: 200 }
]

// Universe related const
export enum ChannelListViewParticipantsEnum {
  'InvitationSent' = 0,
  'InvitationAccepted' = 1,
  'InvitationRejected' = 2,
  'InvitationPending' = 3
}

// Universe: SMART contract related Status enum
export enum SmartContractListStatusEnum {
  'PENDING' = 0,
  'ACCEPTED' = 1,
  'REJECTED' = 2,
  'INPROGRESS' = 3
}

// Universe: SMART contract related Status enum
export enum NetworkStatusEnum {
  'NOTSENDREQUEST' = -1,
  'PENDING' = 0,
  'ACCEPTED' = 1,
  'REJECTED' = 2,
  'INPROGRESS' = 3
}

export enum ChatFeatureEnum {
  CREATE_SMART_CONTRACT = 'Create Smart Contract',
  AUDIT_SMART_CONTRACT = 'Audit Smart Contract',
  RESOLVE_BLOCKCHAIN_ISSUE = 'Resolve Blockchain Issue',
  RESOLVE_SMART_CONTRACT_ISSUE = ' Resolve smart Contract Issue'
}
export interface IChatFeature {
  title: string
  subTitle: string
  icon: any
  status: number
}
export const ChatFeature: IChatFeature[] = [
  {
    title: ChatFeatureEnum.CREATE_SMART_CONTRACT,
    subTitle:
      'Develop an innovative smart contract powered by edeXAInnovate',
    icon: faFileSignature,
    status: 0
  },
  {
    title: ChatFeatureEnum.AUDIT_SMART_CONTRACT,
    subTitle:
      'Enhance the accuracy and efficiency of your smart contract',
    icon: faFileContract,
    status: 1
  },
  {
    title: ChatFeatureEnum.RESOLVE_BLOCKCHAIN_ISSUE,
    subTitle:
      'Overcome blockchain challenges swiftly and effectively',
    icon: faFileCircleExclamation,
    status: 2
  },
  {
    title: ChatFeatureEnum.RESOLVE_SMART_CONTRACT_ISSUE,
    subTitle:
      'Guidance for debugging, optimizing, and ensuring the integrity of your smart contracts',
    icon: faFileArchive,
    status: 3
  }
]
