// import ConnectToChain from "component/Dashboard/ConnectToChain";
// import Documentation from "component/Dashboard/Documentation";
// import TitleComponent from "Pages/common/titleComponent";
import ConnectToChain from "components/dashboard/ConnectToChain";
import Documentation from "components/dashboard/Documentation";
import OverviewListComponent from "components/Overview/OverviewListComponent";
import { TitleComponent } from "components/titleComponent";
import useComingSoonHook from "hooks/UseComingSoonHook";
import { Col, Row } from "reactstrap";
// import useComingSoonHook from "utils/hooks/useComingSoonHook";
import "../../../styles/PeerNode/peerNode.scss";
// import OverviewListComponent from "./Overview/OverviewListComponent";

const PeerNodeOverview = () => {
  useComingSoonHook();
  return (
    <div className="peer-node-overview-wrapper">
      <TitleComponent title="Peer Node Overview" />
      <Row>
        <OverviewListComponent />
        <Col md="12" lg="12" xl="4" xxl="4">
          <Row>
            <Col md="6" lg="6" xl="12" xxl="12">
              <ConnectToChain />
            </Col>
            <Col md="6" lg="6" xl="12" xxl="12">
              <Documentation />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PeerNodeOverview;
