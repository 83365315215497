import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { getAllAPIData } from 'utils/common/helper/allAPIData'
import '../../../styles/api/api.scss'
import '../../../styles/api_list/api_list.scss'
import { AllAPIList, SubscribedAPIList } from '../apiListing'

// context to store the subscribed applications data
export const SubscribedApplicationContext = React.createContext([])
const ApiList = () => {
  /**
   * @getAllAPIDeta
   */
  const [allAPIData, setALLAPIData] = useState<any>([])

  // const dispatch = useDispatch();
  // // get the api details
  // const getAPIDetails = useCallback(() => {
  //   dispatch(setLoading(true));
  //   getAllAPIData().then((response: any) => {
  //     dispatch(setLoading(false));
  //     setALLAPIData(response.data);
  //   });
  // }, [dispatch]);

  // useEffect(() => {
  //   getAPIDetails();
  // }, [getAPIDetails]);
  return (
    <>
      <div className="api-list-wrapper">
        <Container>
          {/* listing of API's which user has subscribed */}
          {Array.isArray(allAPIData?.myApplications) &&
            allAPIData?.myApplications.length > 0 && (
              <SubscribedAPIList
                listingData={allAPIData?.myApplications}
                getAPIDetails={' '}
              />
            )}
          {/* listing of all the available api's */}
          <SubscribedApplicationContext.Provider
            value={allAPIData?.myApplications}
          >
            <AllAPIList
              listingData={allAPIData?.totalApplications}
              getAPIDetails={' '}
              myApplications={allAPIData?.myApplications}
            />
          </SubscribedApplicationContext.Provider>
        </Container>
      </div>
    </>
  )
}

export default ApiList
