import { LoadingSpinner } from "components/loader";
import React, { useState, useEffect } from "react";
import Vector from "../../../../assets/Images/Vector.svg";

const FourSection = ({ handleNextClick }: any) => {
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="section section-custom-main">
        <div className="container-main">
          <div className="div-main">
            <div className="div-sub" style={{ marginTop: "185px" }}>
              {timer > 0 ? (
                <>
                  <h1 className="div-text">
                    Deploying your ecosystem on edeXa Universe
                  </h1>
                  <p className="div-desc">
                    <LoadingSpinner size="lg" />
                  </p>
                  <p className="div-desc">
                    Timer {`00:${timer < 10 ? `0${timer}` : timer}`} countdown
                  </p>
                </>
              ) : (
                <>
                  <p className="private-blockchain-text">
                    Your own Private Blockchain organization is ready Start Your
                    journey and start Building
                  </p>
                  <img src={Vector} alt="Success" loading="lazy" />
                  <h1 className="div-text">
                    Connected to edeXa master channel
                  </h1>
                  <button className="go-to-dashboard" onClick={handleNextClick}>Go to Dashboard</button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="div-footer">
        <button className="third-footer-button">
          ✨ Great news! The magic is happening in the backend as we speak. 🚀
          Sit tight, and we'll notify you by email once this step is complete or
          just keep an eye on the counter…In the meantime, I am here for you.Ask
          me anything.
        </button>
      </div>
    </>
  );
};

export default FourSection;
