import InstallationContractTemplate from 'components/installationContract/InstallationContractTemplate'
import { UploadContractTemplate } from 'components/uploadContract'
import { FC, useCallback, useState } from 'react'
import Select from 'react-select'
import { FormGroup } from 'reactstrap'
import { SmartContractListingEnum } from 'utils/constants'

interface IContractTemplateListComponent {}
const languageOptions = [
  { value: 'Upload', label: 'Uploaded Smart Contract' },
  { value: 'Install', label: 'Installed Smart Contract' }
]
const ContractTemplateListComponent: FC<
  IContractTemplateListComponent
> = () => {
  const [template, setTemplate] = useState<{
    value: string
    label: string
  }>({
    value: 'Install',
    label: 'Installed Smart Contract'
  })

  const handleChange = useCallback((e) => {
    setTemplate(e)
  }, [])

  return (
    <div className="backdrop-block white-wrapper">
      <div className="d-sm-flex justify-content-between">
        <h5 className="c-tx-primary f-600">Smart Contracts</h5>
        <FormGroup className="select-component">
          <Select
            options={languageOptions}
            id="language"
            onChange={handleChange}
            value={template}
            placeholder="Select Language"
          />
          <p className="c-tx-ninth small">
            You can select Smart Contract
          </p>
        </FormGroup>
      </div>
      {template.value === SmartContractListingEnum.UPLOAD ? (
        <UploadContractTemplate />
      ) : (
        <InstallationContractTemplate />
      )}
    </div>
  )
}

export default ContractTemplateListComponent
