import {
  IinstallationContractListAPIResData,
  installationContractListAPI
} from 'actions/installationContractAPI'
import Documentation from 'components/dashboard/Documentation'
import LoadingSpinner from 'components/loader/LoadingSpinner'
import { NoFoundData } from 'components/notFoundPages'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import { FC, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import { getFormattedDate } from 'utils/commonFunctions'
import { useWebsocket } from 'utils/WebSocketProvider'
import ContractModal from './ContractModal'

const tableHeader = [
  'SR NO',
  'CONTRACT NAME',
  'CONTRACT LANGUAGE',
  'LAST UPDATED DATE',
  'ACTIONS'
]

interface IContractTableListComponent {}

const installedContracts = [
  {
    status: 1,
    id: 'contrChl_7s47gfh0h7',
    userId: 'user_2fijqj3j5v',
    contractId: 'cntrct_5d1n15lha5',
    channelId: 'chl_qw6tc845zk',
    reason: null,
    createdAt: '2024-01-16T04:24:40.893Z',
    deletedAt: null,
    contrChlUser: {
      name: 'Chris bell',
      email: 'chris@gmail.com',
      userId: 'user_2fijqj3j5v'
    },
    contrChlChannel: {
      channelName: 'token-engine',
      id: 'chl_qw6tc845zk'
    },
    contrChlContract: {
      name: 'erc721',
      protocol: 'test',
      language: 'node'
    }
  },
    {
      status: 1,
      id: 'contrChl_7s47gfh0h7',
      userId: 'user_2fijqj3j5v',
      contractId: 'cntrct_5d1n15lha5',
      channelId: 'chl_qw6tc845zk',
      reason: null,
      createdAt: '2024-01-16T04:25:20.893Z',
      deletedAt: null,
      contrChlUser: {
        name: 'John Doe',
        email: 'john@gmail.com',
        userId: 'user_435utjqj3j5v'
      },
      contrChlChannel: {
        channelName: 'token-engine',
        id: 'chl_dfkgjdfg48'
      },
      contrChlContract: {
        name: 'erc20',
        protocol: 'test',
        language: 'node'
      }
  }
]

const ContractTableListComponent: FC<
  IContractTableListComponent
> = () => {
  const socket = useWebsocket()
  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )
  const [search, setSearch] = useState('')
  const [historyLoading, setHistoryLoading] = useState(false)
  const [tableList, setTableList] = useState<any[]>(
    installedContracts
  )
  const [businessmodal, setBusinessmodal] = useState<any>({
    open: false,
    id: '',
    data:{}
  })
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    totalCount: 0,
    search: ''
  })

  const getSmartContractListAction = () => {
    setHistoryLoading(true)
    let params: any = {
      limit: pagination.limit,
      page: pagination.page,
      approved: true
    }
    if (pagination.search) {
      params.search = pagination.search
    }
    installationContractListAPI(params)
      .then((res: any) => {
        if (res.status === 200) {
          setPagination({ ...pagination, totalCount: res.data.count })
          if (pagination.search) {
            setTableList(res.data.data)
          } else {
            setTableList([...tableList, ...res.data.data])
          }
        }
        setHistoryLoading(false)
      })
      .catch((err) => setHistoryLoading(false))
  }

  useEffect(() => {
    if (pagination.search) {
      getSmartContractListAction()
    }
    // eslint-disable-next-line
  }, [pagination.limit, pagination.search])

  const incrementPage = () => {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    })
  }

  const handleSerach = (str: string) => {
    if (str) {
      setPagination({ ...pagination, search: str })
    } else {
      setHistoryLoading(true)
      let params: any = {
        limit: pagination.limit,
        page: pagination.page,
        approved: true
      }
      installationContractListAPI(params)
        .then((res: any) => {
          if (res.status === 200) {
            setPagination({
              ...pagination,
              totalCount: res.data.count
            })
            setTableList(res.data.data)
          }
          setHistoryLoading(false)
        })
        .catch((err) => setHistoryLoading(false))
    }
  }

  // useEffect(() => {
  //   socket?.on(`contract_installtion_${user?.id}`, (data: any) => {
  //     if (data?.status === 1) {
  //       if (pagination.page === 1) {
  //         getSmartContractListAction()
  //       } else {
  //         setPagination({ ...pagination, page: 1 })
  //       }
  //     }
  //   })
  //   return () => {
  //     socket?.off(`contract_installtion_${user?.id}`)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.id])

  // useDebouncedEffect(() => handleSerach(search), [search], 500)

  return (
    <Row className="">
      <Col
        md="12"
        lg="7"
        xl="7"
        xxl="8"
        className="align-items-center"
      >
        <div className="backdrop-block">
          <div className="title d-sm-flex justify-content-between">
            <h5 className="mb-20 c-tx-primary f-600 mb-10">
              Installed Smart Contracts
            </h5>
            <Input
              className="select-component"
              value={search}
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              type="email"
            />
          </div>
          <div className="ct-table" id="contract-table">
            <InfiniteScroll
              dataLength={tableList.length}
              next={incrementPage}
              hasMore={pagination.totalCount > tableList.length}
              loader={<LoadingSpinner size="sm" />}
              scrollableTarget="contract-table"
              endMessage={
                tableList.length > 0 && tableList.length > 4 ? (
                  <p className="text-center f-700 mt-3">
                    No more results to show.
                  </p>
                ) : null
              }
            >
              <Table className="position-relative">
                <thead className="table-sticky">
                  <tr>
                    {tableHeader.map((data: string, i: number) => (
                      <th key={i}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableList.length > 0 ? (
                    <>
                      {tableList.map(
                        (
                          data: IinstallationContractListAPIResData,
                          i
                        ) => (
                          <tr key={`contracts-${i}`}>
                            <td>{i + 1}</td>
                            <td>{data?.contrChlContract.name}</td>
                            <td>{data?.contrChlContract.language}</td>
                            <td>
                              {getFormattedDate(data?.updatedAt)}
                            </td>
                            <td>
                              <div className="btn-wrapper_block">
                                <Button
                                  className="custom-primary key w-100"
                                  onClick={() =>
                                    setBusinessmodal({
                                      open: true,
                                      id: data?.contractId,
                                      dataVal : data 
                                    })
                                  }
                                >
                                  View
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  ) : (
                    <NoFoundData columnCount={tableHeader.length} />
                  )}
                </tbody>
              </Table>
            </InfiniteScroll>
            {historyLoading ? <LoadingSpinner size="sm" /> : null}
          </div>
        </div>
      </Col>
      <Col md="12" lg="5" xl="5" xxl="4">
        <Documentation />
      </Col>
      <ContractModal
        setBusinessmodal={setBusinessmodal}
        businessmodal={businessmodal}
      />
    </Row>
  )
}

export default ContractTableListComponent
