import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { setConfirmationModalVisibility } from "../../store/confirmationModule/action";

/**
 *
 * @param {handleOnClickAction} - a function () => {}
 * @param {title} - a string
 * @returns null
 *
 * This modal will take a function and title as Input.
 * 1. If user click on yes then the function will execute and the respective action will be performed.
 * 2. The title will be shown as modal title.
 *
 * usages example -
 *
 * const apiSubscriptionToggle = () => {
 *   @todo - perform your operations here
 * }
 * <ConfirmationModal handleOnClickAction={apiSubscriptionToggle} title='Are you sure you want to unsubscribe' />
 *
 * You can also pass an state to handleOnClickAction which can contain a function, you may use state to send dynamic function as per the user action
 *
 * for example -
 *  const [addRemoveFunction, setAddRemoveFunction] = useState<any>({fnCall: apiSubscriptionToggle});
 *
 *  Now you can pass this state to ConfirmationModal component-
 *  <ConfirmationModal handleOnClickAction={addRemoveFunction} title='YOUR DYNAMIC TITLE' />
 */

const ConfirmationModal: React.FC<propTye> = ({ handleOnClickAction, title }) => {
  const dispatch = useDispatch();

  const visible = useSelector((state: IRootReducer) => state.confirmationModel);

  const { confirmationModelVisibility, confirmationId } = visible;
  return (
    <Modal isOpen={confirmationModelVisibility} toggle={() => dispatch(setConfirmationModalVisibility(false))} className="custom-popup-channel" size="sm" centered>
      <div className="erc-template-wrapper">
        <div className="title">
          <h5 className="c-tx-primary text-center f-700 mb-20">Confirmation</h5>
        </div>
        <p className="c-tx-ninth text-center mb-20">{title}</p>
        <div className="btn-wrapper text-center">
          <Button
            className="custom-primary-outline me-3 mb-3 mb-sm-0 new-btn"
            onClick={() => {
              dispatch(setConfirmationModalVisibility(false));
            }}>
            no
          </Button>
          <Button className="custom-primary new-btn" onClick={() => handleOnClickAction(confirmationId)}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

interface propTye {
  handleOnClickAction: Function;
  title: string;
}
export default ConfirmationModal;
