import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { installationContractListAPI } from 'actions/installationContractAPI'
import BadgeBlock from 'components/badgeBlocks/BadgeBlock'
import { LogsComponent } from 'components/logs'
import { NoFoundTemplate } from 'components/notFoundPages'
import PaginationComponent from 'components/pagination/PaginationComponent'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import Success from '../../assets/Images/check.png'
import Reject from '../../assets/Images/decline.png'

import { setLoading } from 'store/loader/action'
import { IRootReducer } from 'store/root-reducer'
import { socketConnection } from 'utils/commonFunctions'
import { SmartContractListStatusEnum } from 'utils/constants'
import { makeFullS3ObjectUrl } from 'utils/makeFullS3ObjectUrl'

const logo = makeFullS3ObjectUrl('zip.png')

const installedTemplates = [
  {
    status: 2,
    id: 'contrChl_28kitlvagk',
    userId: 'user_2fijqj3j5v',
    contractId: 'cntrct_5d1n15lha5',
    channelId: 'chl_495fiiicz8',
    reason: null,
    createdAt: '2024-01-19T08:13:51.765Z',
    deletedAt: null,
    contrChlUser: {
      name: 'rohan jain',
      email: 'rohan@yopmail.com',
      userId: 'user_2fijqj3j5v'
    },
    contrChlChannel: {
      channelName: 'banji',
      id: 'chl_495fiiicz8'
    },
    contrChlContract: {
      name: 'erc20',
      protocol: 'test',
      language: 'node'
    }
  },
  {
    status: 0,
    id: 'contrChl_jrlnpq5nyi',
    userId: 'user_2fijqj3j5v',
    contractId: 'cntrct_5d1n15lha5',
    channelId: 'chl_495fiiicz8',
    reason: null,
    createdAt: '2024-01-19T08:13:51.543Z',
    deletedAt: null,
    contrChlUser: {
      name: 'rohan jain',
      email: 'rohan@yopmail.com',
      userId: 'user_2fijqj3j5v'
    },
    contrChlChannel: {
      channelName: 'banji',
      id: 'chl_495fiiicz8'
    },
    contrChlContract: {
      name: 'erc721',
      protocol: 'test',
      language: 'node'
    }
  },
  {
    status: 2,
    id: 'contrChl_18bw0lgdhk',
    userId: 'user_2fijqj3j5v',
    contractId: 'cntrct_5d1n15lha5',
    channelId: 'chl_7ek3zitd2g',
    reason: null,
    createdAt: '2024-01-19T05:25:13.912Z',
    deletedAt: null,
    contrChlUser: {
      name: 'rohan jain',
      email: 'rohan@yopmail.com',
      userId: 'user_2fijqj3j5v'
    },
    contrChlChannel: {
      channelName: 'default-channel',
      id: 'chl_7ek3zitd2g'
    },
    contrChlContract: {
      name: 'erc20',
      protocol: 'test',
      language: 'node'
    }
  },
  {
    status: 0,
    id: 'contrChl_o4dbfrqus4',
    userId: 'user_2fijqj3j5v',
    contractId: 'cntrct_5d1n15lha5',
    channelId: 'chl_495fiiicz8',
    reason: null,
    createdAt: '2024-01-19T08:13:50.508Z',
    deletedAt: null,
    contrChlUser: {
      name: 'rohan jain',
      email: 'rohan@yopmail.com',
      userId: 'user_2fijqj3j5v'
    },
    contrChlChannel: {
      channelName: 'banji',
      id: 'chl_495fiiicz8'
    },
    contrChlContract: {
      name: 'erc721',
      protocol: 'test',
      language: 'node'
    }
  }

  // {
  //   status: 2,
  //   id: 'contrChl_u0vwd65wa5',
  //   userId: 'user_2fijqj3j5v',
  //   contractId: 'cntrct_5d1n15lha5',
  //   channelId: 'chl_7ek3zitd2g',
  //   reason: null,
  //   createdAt: '2024-01-19T05:25:12.934Z',
  //   deletedAt: null,
  //   contrChlUser: {
  //     name: 'rohan jain',
  //     email: 'rohan@yopmail.com',
  //     userId: 'user_2fijqj3j5v'
  //   },
  //   contrChlChannel: {
  //     channelName: 'default-channel',
  //     id: 'chl_7ek3zitd2g'
  //   },
  //   contrChlContract: {
  //     name: 'erc20',
  //     protocol: 'test',
  //     language: 'node'
  //   }
  // }
]

const InstallationContractTemplate = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )

  // eslint-disable-next-line
  const [historyLoading, setHistoryLoading] = useState(false)
  const [isState, setState] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [stepper, setStepper] = useState<any>()

  const [templateList, setTemplateList] = useState<any[]>(
    installedTemplates
  )
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalCount: 0
  })

  const getInstallationContractAction = () => {
    dispatch(setLoading(true))
    installationContractListAPI({
      limit: pagination.limit,
      page: pagination.page,
      approved: false
    })
      .then((res: any) => {
        if (res.status === 200) {
          setTemplateList(res.data.data)
          setPagination({ ...pagination, totalCount: res.data.count })
        }
        dispatch(setLoading(false))
      })
      .catch((err) => setHistoryLoading(false))
  }

  const handleStatus = (status: number) => {
    if (status === SmartContractListStatusEnum.INPROGRESS) {
      return 'In-Progress'
    } else if (status === SmartContractListStatusEnum.REJECTED) {
      return 'Rejected'
    } else {
      return 'Installed'
    }
  }

  // useEffect(() => {
  //   getInstallationContractAction()
  //   // eslint-disable-next-line
  // }, [pagination.page])

  // useEffect(() => {
  //   socketConnection?.on(
  //     `contract_installtion_${user?.id}`,
  //     (data: any) => {
  //       if (pagination.page === 1) {
  //         getInstallationContractAction()
  //       } else {
  //         setPagination({ ...pagination, page: 1 })
  //       }
  //     }
  //   )

  //   return () => {
  //     socketConnection.off(`Contract_status_${user?.id}`)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps20: any = [
    {
      name: 'installchaincode-peer0-erc20test-1-n2tbr',
      status: Success
    },
    {
      name: 'installchaincode-peer1-erc20test-1-vns7d',
      status: Success
    },
    {
      name: 'approvechaincode-peer0-erc20test-1-1-xtgqj',
      status: Reject
    },
    {
      name: 'approvechaincode-peer1-erc20test-1-1-pcnft',
      status: Reject
    },
    { name: 'commitchaincode-erc20test-1-1-755t4', status: Reject }
  ]
  const steps721: any = [
    {
      name: 'installchaincode-peer0-erc721test-1-n2tbr',
      status: Success
    },
    {
      name: 'installchaincode-peer1-erc721test-1-vns7d',
      status: Success
    },
    {
      name: 'approvechaincode-peer0-erc721test-1-1-xtgqj',
      status: Success
    },
    {
      name: 'approvechaincode-peer1-erc721test-1-1-pcnft',
      status: Success
    },
    { name: 'commitchaincode-erc721test-1-1-755t4', status: Success }
    // {
    //   name: 'invokechaincode-peer1-erc721test-1-dwtqn',
    //   status: Success
    // }
  ]

  const handleSetStatus = (stats: any) => {
    if (stats === 2) {
      setStepper(steps20)
    } else {
      setStepper(steps721)
    }
  }

  return (
    <>
      <div className="column_list">
        {templateList.length > 0 ? (
          templateList.map((data: any, i: number) => (
            <div
              key={i}
              className="element"
              onClick={() => {
                setOpenModal(true)
                handleSetStatus(data.status)
              }}
            >
              <div className="templateblock cursor-pointer">
                <div className="block-1">
                  <div className="badge-bl">
                    <BadgeBlock theme="dark" name="Smart Contract" />
                  </div>
                  <img src={logo} className="img-fluid" alt="zip" />
                </div>
                <div className="block-2">
                  <div className="bl_1 text-truncate">
                    <h6 className="c-tx-primary f-700 mb-0 text-truncate">
                      {data?.contrChlContract?.name}
                    </h6>
                    <p className="c-tx-ninth">
                      {handleStatus(data?.status)}
                    </p>
                  </div>
                  <div className="align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="4"
                      viewBox="0 0 17 4"
                    >
                      <path
                        id="Path_330"
                        data-name="Path 330"
                        d="M8.624,6.781a1.953,1.953,0,0,0-1.906-2,1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2A1.953,1.953,0,0,0,8.624,6.781Zm4.689-2a1.953,1.953,0,0,0-1.906,2,1.908,1.908,0,1,0,3.811,0A1.953,1.953,0,0,0,13.313,4.781Zm-13.189,0a1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2,1.953,1.953,0,0,0,1.906-2A1.953,1.953,0,0,0,.124,4.781Z"
                        transform="translate(1.781 -4.781)"
                        fill="#868686"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoFoundTemplate
            message="No Found Smart Contracts"
            bageName="Smart Contract"
            logo={logo}
          />
        )}
      </div>

      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        centered
        size="md"
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setOpenModal(!openModal)}
              className="cursor-pointer c-green r-25 position-absolute"
            />
          </div>
          <h2 className="fw-600 font-18 color-black1 text-center mb-0">
            Steps for Smart Contracts
          </h2>
          <br />
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Logs</th>
                </tr>
              </thead>
              <tbody>
                {stepper?.map((step: any, index: any) => (
                  <tr key={index}>
                    <td>{step.name}</td>
                    <td>
                      <img
                        width={30}
                        height={30}
                        src={step.status}
                        alt="Status Gif"
                      />
                    </td>
                    <td>
                      <span
                        onClick={() => setState(true)}
                        style={{
                          cursor: 'pointer',
                          color: '#073d83'
                        }}
                      >
                        See The logs
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>

      {isState && (
        <LogsComponent
          setFirstModal={setOpenModal}
          isState={isState}
          setUpdate={setState}
        />
      )}

      <div className="pagination-wrap mt-0">
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <PaginationComponent
              pagination={pagination}
              setPagination={setPagination}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default InstallationContractTemplate
