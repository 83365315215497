import { DELETEID, SETVISIBLE } from "./type";

const initialState = {
  confirmationModelVisibility: false,
  confirmationId: ""
};

const confirmationModelReducer = (state = initialState, action: { type: string; payload: boolean; id: string }) => {
  const newState = { ...state };

  switch (action.type) {
    case SETVISIBLE:
      newState.confirmationModelVisibility = action.payload;
      if (action.payload === false) {
        newState.confirmationId = "";
      }
      break;
    case DELETEID:
      newState.confirmationId = action.id;
      break;
  }
  return newState;
};

export default confirmationModelReducer;
