import { createFolderAPI } from "actions/createFolderAPI";
import { renameFolderAPI } from "actions/renameFolderAPI";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import { toastSuccess } from "utils/commonFunctions";

interface ICreateFolderModal {
  handleAction: () => void;
  createModal: {
    open: boolean;
    id: string;
    rename: string;
  };
  setCreateModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      id: string;
      rename: string;
    }>
  >;
}

const CreateFolderModal: FC<ICreateFolderModal> = ({ createModal, setCreateModal, handleAction }) => {
  const [folderName, setFolderName] = useState("");
  const [title, setTitle] = useState("New folders");

  const handleSubmit = () => {
    if (folderName) {
      if (createModal?.rename) {
        if (folderName !== createModal?.rename) {
          renameFolderAPI(createModal?.id, {
            name: folderName.trim()
          }).then((res) => {
            if (res.status === 200) {
              toastSuccess(res.message);
              handleAction();
            }
          });
        }
      } else {
        createFolderAPI({ name: folderName.trim() }).then((res) => {
          if (res.status === 200) {
            toastSuccess(res.message);
            handleAction();
          }
        });
      }
      setFolderName("");
      setTitle("New folder");
      setCreateModal({ open: false, id: "", rename: "" });
    }
  };

  const handleToggle = () => {
    setFolderName("");
    setTitle("New folder");
    setCreateModal({ open: false, id: "", rename: "" });
  };

  useEffect(() => {
    if (createModal?.rename) {
      setTitle("Rename");
      setFolderName(createModal?.rename);
    }
  }, [createModal?.rename]);

  return (
    <Modal
      isOpen={createModal.open}
      className="custom-popup-channel"
      size="xs"
      toggle={handleToggle}
      centered
      external={
        <Link to="#" className="fw-bold text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={handleToggle}>
          CLOSE
        </Link>
      }>
      {" "}
      <div className="erc-template-wrapper">
        <div className="title">
          <h5 className="c-tx-primary f-700 mb-20">{title}</h5>
        </div>
        <FormGroup className="">
          <Input type="text" placeholder="Folder Name" onChange={(e) => setFolderName(e.target.value)} value={folderName} onKeyDown={(e) => e.key === "Enter" && handleSubmit()} />
        </FormGroup>
        <div className="btn-wrapper text-right">
          <Button className="custom-primary new-btn" onClick={handleSubmit} disabled={Boolean(!folderName)}>
            {createModal?.rename ? "Rename" : "Create"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFolderModal;
