import { faTimesCircle as close } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreateChannelAPI } from 'actions/channelCreateAPI'
import { getChannelListingAPI } from 'actions/channelListingAPI'
import { SendOTPAPI } from 'actions/sendOtpAPI'
import {
  IUserSearchAPI,
  IUserSearchAPIData,
  UserSearchAPI
} from 'actions/userSearchAPI'
import { useFormik } from 'formik'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button, Form, FormGroup, Input, Modal } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import {
  getLocalStorage,
  handleTrim,
  toastSuccess
} from 'utils/commonFunctions'
import { makeFullS3ObjectUrl } from 'utils/makeFullS3ObjectUrl'

import * as Yup from 'yup'
import InvitationModalComponent from '../receivedInvitations/InvitationModalComponent'
import toast from 'react-hot-toast'

const User = makeFullS3ObjectUrl('user-image.png')
const closeIcon = <FontAwesomeIcon icon={close} />

interface ICreateChannelModalComponent {
  modal: boolean
  setModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface IUserInterface {
  value: string
  label: string
}

const CreateChannelModalComponent: FC<
  ICreateChannelModalComponent
> = ({ setModal, modal }) => {
  const dispatch = useDispatch()

  const [profile, setProfile] = useState<any>([])
  const [apiData, setApiData] = useState<any>()
  const [search, setSearch] = useState('')
  const organizationName = localStorage.getItem('organizationName')

  const [invitationmodal, setInvitationmodal] = useState(false)
  // eslint-disable-next-line
  const CustomOption = () => (
    <div className="d-flex align-items-center">
      {profile.map((data: any, i: any) => (
        <div
          key={i}
          className="select-map ml-10 d-inline-flex align-items-center"
        >
          <div className="user-img-wrp ">
            <img src={User} alt="dsd" width={20} />
          </div>
          <span className="mx-1 c-tx-ninth">{data.label}</span>
          <span className="icon-bl">{closeIcon}</span>
        </div>
      ))}
    </div>
  )

  const createChannelAPI = (values: any) => {
    const oldValue = getLocalStorage('channelName')
    // Merge old and new values
    const mergedValue = [...oldValue, values?.channelName]
    // Store merged value back in local storage
    localStorage.setItem('channelName', JSON.stringify(mergedValue))
    handleToggleModal(true)
    toastSuccess('Channel created successfully')
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const handleSendOTP = (resend?: boolean) => {
    SendOTPAPI(apiData, {
      requestType: 'Create',
      module: 'Channel'
    }).then((res) => {
      if (!resend) {
        if (res.status) {
          handleToggleModal()
          setInvitationmodal(!invitationmodal)
        }
      }
    })
  }

  const FomrikState = useFormik({
    initialValues: {
      channelName: '',
      participants: [],
      message: ''
    },
    validationSchema: Yup.object({
      channelName: Yup.string()
        .required('Channel Name is required')
        .matches(/^\S+$/, 'Channel Name not allow to space')
        .min(3, 'Channel Name must be at least 3 characters')
        .max(50, 'Channel Name must be at most 50 characters')
    }),
    onSubmit: (values) => createChannelAPI(values)
  })

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleBlur,
    touched
  } = FomrikState

  const handleSerach = (e: string) => {
    if (modal) {
      let params: {
        search?: string
        // module: string;
        limit: number
        page: number
      } = {
        limit: 20,
        page: 1
        // module: "Channel"
      }
      if (e) {
        params.search = e
      }
      UserSearchAPI(params).then((res: IUserSearchAPI) => {
        if (res.status === 200) {
          const newoption = res?.data?.data?.map(
            (data: IUserSearchAPIData) => {
              return {
                label: data?.name || data?.email,
                value: data?.userId
              }
            }
          )
        }
      })
    }
  }

  // userSerach use debounc
  // useDebouncedEffect(() => handleSerach(search), [search], 500);

  // filter out the userId
  const handleUserId = useCallback(
    (data) => {
      const userIds = data.map((item: any) => item.value)
      setFieldValue('participants', userIds)
    },
    [setFieldValue]
  )

  const handleToggleModal = (reset?: boolean) => {
    setModal(!modal)
    setValidation({ isDublicate: false, isValidate: false })
    if (reset) {
      setProfile([])
      FomrikState.resetForm()
    }
  }
  const [validation, setValidation] = useState({
    isValidate: false,
    isDublicate: false
  })
  const handleChannelName = useCallback(
    (str: string) => {
      str &&
        getChannelListingAPI({ channelName: str, page: 1, limit: 10 })
          .then((res) => {
            if (res.status === 200) {
              setValidation({ ...validation, isDublicate: false })
            }
          })
          .catch((err) => {
            if (err?.data?.status === 409) {
              // setFieldError("channelName", err?.data?.message);
              setValidation({ ...validation, isDublicate: true })
            }
          })
    },
    [validation]
  )

  // useDebouncedEffect(
  //   () => handleChannelName(values.channelName),
  //   [values.channelName],
  //   500
  // )

  // useEffect(() => {
  //   modal && handleSerach(search)
  //   // eslint-disable-next-line
  // }, [modal])

  const handleValidate = () => {
    setValidation({ ...validation, isValidate: true })
    if (!validation.isDublicate) {
      handleSubmit()
    }
  }

  return (
    <>
      <Modal
        isOpen={modal}
        className="custom-popup-channel create-channel-pop"
        toggle={() => handleToggleModal(true)}
        style={{ maxWidth: '560px' }}
        centered
        external={
          <Link
            to="#"
            className="text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30 f-700"
            onClick={() => handleToggleModal(true)}
          >
            CLOSE
          </Link>
        }
      >
        <div className="custom-card-wrapper h-auto">
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">
              Create Channel
            </h5>
          </div>
          <Form>
            <FormGroup className="position-relative">
              <Input
                name="channelName"
                invalid={Boolean(
                  touched.channelName
                    ? validation.isDublicate
                      ? true
                      : errors?.channelName
                    : false
                )}
                onChange={(e) =>
                  handleTrim(
                    e.target.name,
                    e.target.value,
                    setFieldValue
                  )
                }
                onBlur={handleBlur}
                placeholder="Channel Name"
                type="text"
                value={values.channelName}
              />
              {touched?.channelName ? (
                validation.isDublicate ? (
                  <span className="text-danger">
                    Channel name already exist
                  </span>
                ) : (
                  errors?.channelName && (
                    <span className="text-danger">
                      {errors?.channelName}
                    </span>
                  )
                )
              ) : null}
            </FormGroup>
            <FormGroup className="position-relative">
              {/* <Select
                options={options}
                // onInputChange={(e: string) => {
                //   setSearch(e)
                // }}

                value={profile}
                onChange={(value) => {
                  setProfile(value)
                }}
              /> */}

              <Input
                name="organization"
                onBlur={handleBlur}
                placeholder="Channel Name"
                type="text"
                style={{ backgroundColor: ' #f2f2f2' }}
                // @ts-ignore
                value={organizationName}
                disabled
              />
              <p className="c-tx-ninth mb-20 small mt-2">
                Your Organization Name
              </p>
            </FormGroup>
            <FormGroup className="">
              <Input
                type="textarea"
                invalid={Boolean(errors?.message)}
                value={values.message}
                name="message"
                onChange={(e) =>
                  handleTrim(
                    e.target.name,
                    e.target.value,
                    setFieldValue
                  )
                }
                placeholder="Message (Optional)"
              />
              {errors?.message && (
                <span className="text-danger">{errors?.message}</span>
              )}
            </FormGroup>
            <Button
              type="button"
              onClick={handleValidate}
              className="custom-primary w-100"
            >
              Create Channel
            </Button>
          </Form>
        </div>
      </Modal>
      <InvitationModalComponent
        invitationmodal={invitationmodal}
        setInvitationmodal={setInvitationmodal}
        handleResend={handleSendOTP}
        requestName="Channel-create"
      />
    </>
  )
}

export default CreateChannelModalComponent
