import {
  faCopy,
  faEye,
  faEyeSlash
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal
} from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import { toastSuccess } from 'utils/commonFunctions'
import '../../styles/dashboard_layout/connectblock.scss'

const copy = <FontAwesomeIcon icon={faCopy} />
const eye = <FontAwesomeIcon icon={faEye} />
const eyeClose = <FontAwesomeIcon icon={faEyeSlash} />

const ConnectToChain = () => {
  const [modal, setModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const redirectTo = (url: string) => {
    window.open(url, '_blank')
  }

  const handleCopyTransaction = (text?: string) => {
    if (text) {
      window.navigator.clipboard.writeText(text).then(() => {
        toastSuccess('Copied to Clipboard')
      })
    }
  }

  const networkInfo = useSelector(
    (state: IRootReducer) => state.NetworkReducer
  )

  return (
    <div className="backdrop-block">
      <div className="title">
        <h5 className="c-tx-primary f-600 mb-10">
          Connect to Your Blockchain
        </h5>
        <p className="c-tx-ninth mb-10">
          Send transactions to the blockchain network using these end
          points along with valid application credentials.
        </p>
      </div>
      <ul className=" connectblock">
        <li className="element">
          <div className="block-1 px-1">
            <p className="c-tx-primary f-700 first-title">Peer</p>
            <p className="c-tx-ninth">
              https://peer1.edx0001-network.io-world.com
            </p>
          </div>
          <div
            className="icons cursor-pointer"
            onClick={() =>
              handleCopyTransaction(
                ` https://peer1.edx0001-network.io-world.com`
              )
            }
          >
            {copy}
          </div>
        </li>
        <li className="element">
          <div className="block-1 px-1">
            <p className="c-tx-primary f-700 first-title">
              Blockchain Explorer
            </p>
            <Link className="c-tx-ninth" to={'#'}>
              https://2fijqj3j5v.y92y1cbtc6.explore.io-world.com
            </Link>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div
              className="icons cursor-pointer"
              onClick={() => setModal(!modal)}
            >
              {eye}
            </div>
            <div
              className="icons cursor-pointer"
              onClick={() =>
                handleCopyTransaction(
                  ` https://2fijqj3j5v.y92y1cbtc6.explore.io-world.com`
                )
              }
            >
              {copy}
            </div>
          </div>
        </li>
        <Modal
          isOpen={modal}
          className="custom-popup-channel"
          toggle={() => {
            setModal(false)
          }}
          size="md"
          centered
        >
          <div className="custom-blockchain-explorer">
            <div className="d-flex h-100 w-100 flex-column">
              <div className="w-100 flex-grow-1 order-2 order-lg-1">
                <div className="title">
                  <h5 className="c-tx-primary f-700 mb-20 text-center">
                    Fabric Blockchain Explorer
                  </h5>
                </div>
                <Form className="form-wrapper">
                  <FormGroup
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Label
                      for="username"
                      className="mr-2"
                      style={{ width: '80px' }}
                    >
                      User Name
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="User Name"
                      type="text"
                      // value={networkInfo?.userName}
                      value="edx0001admin"
                      disabled
                      style={{
                        width: '70%',
                        backgroundColor: '#8080806b',
                        color: 'black'
                      }}
                    />
                    <div
                      className="btn btn-outline-secondary mr-10"
                      onClick={() =>
                        handleCopyTransaction('edx0001admin')
                      }
                    >
                      {copy}
                    </div>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Label
                      for="password"
                      className="mr-2"
                      style={{ width: '80px' }}
                    >
                      Password
                    </Label>
                    <Input
                      id="password"
                      name="password"
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      // value={networkInfo?.password}
                      value="edx0001@123"
                      disabled
                      style={{
                        width: '70%',
                        backgroundColor: '#8080806b',
                        color: 'black'
                      }}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        position: 'absolute',
                        top: '57%',
                        right: '16%',
                        zIndex: '100',
                        cursor: 'pointer'
                      }}
                    >
                      {showPassword ? eyeClose : eye}
                    </span>
                    <div
                      className="btn btn-outline-secondary mr-10"
                      onClick={() =>
                        handleCopyTransaction('edx0001@123')
                      }
                    >
                      {copy}
                    </div>
                  </FormGroup>
                </Form>
              </div>
              <div className="d-flex align-items-center justify-content-center order-1 order-lg-2">
                <div className="d-inline-flex">
                  <Button
                    className="custom-primary"
                    onClick={() =>
                      // redirectTo(`https://${networkInfo?.exploreUrl}`)
                      redirectTo(
                        `https://2fijqj3j5v.y92y1cbtc6.explore.io-world.com/#/login`
                      )
                    }
                  >
                    Open Web UI
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <li className="element">
          <div className="block-1 px-1">
            <p className="c-tx-primary f-700 first-title">
              Certificate Authority URL
            </p>
            <Link className="c-tx-ninth" to={'#'}>
              ca.edx0001-network.io-world.com
            </Link>
          </div>
          <div
            className="icons cursor-pointer"
            onClick={() =>
              handleCopyTransaction(
                'http://ca.edx0001-network.io-world.com/'
              )
            }
          >
            {copy}
          </div>
        </li>
        {/* <li className="element">
          <div className="block-1 px-1">
            <p className="c-tx-primary f-700 first-title">REST API Gateway</p>
            <p className="c-tx-ninth">{networkInfo?.restApiUrl}</p>
          </div>
        </li> */}
      </ul>
      {/* <div className="mt-20 link-wrapper">
        <Link to={"#"} className="custom-link">
          VIEW YOUR URLS
        </Link>
      </div> */}
    </div>
  )
}

export default memo(ConnectToChain)
