import {
  faDiscord,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EdexaLogo from "../../../assets/Images/edexa.svg";
import twitterIcon from "../../../assets/Images/twitter.svg";

const DummyHeader = () => {
  return (
    <div className="div-swap-header">
      <div className="swap-container">
        <div>
          <img
            src={EdexaLogo}
            loading="lazy"
            alt="Logo"
            className="image-edx-logo"
          />
        </div>
        <div className="div-block-87">
          <a
            href="https://twitter.com/edexablockchain"
            className="link-block-5 w-inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="html-embed-6 w-embed">
              <img src={twitterIcon} style={{ width: "20px" }} />
            </div>
          </a>
          <a
            href="https://discord.com/invite/TKBQS9tZJY"
            className="link-block-5 w-inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="html-embed-6 w-embed">
              <FontAwesomeIcon icon={faDiscord} />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/edexablockchain/"
            className="link-block-5 w-inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="html-embed-6 w-embed">
              <FontAwesomeIcon icon={faLinkedin} />
            </div>
          </a>
          <a
            href="https://www.youtube.com/channel/UC-CV8V9VzouIgXsuQVHibLw"
            className="link-block-5 w-inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="html-embed-6 w-embed">
              <FontAwesomeIcon icon={faYoutube} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DummyHeader;
