import {
  ChannelInvitationAPI,
  IChannelInvitationAPIResData
} from 'actions/channelInvitationAPI'
import { NoFoundData } from 'components/notFoundPages'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Input, Row, Table } from 'reactstrap'

import { setLoading } from 'store/loader/action'
import { IRootReducer } from 'store/root-reducer'
import { useWebsocket } from 'utils/WebSocketProvider'

import ReceivedInvitationsListComponent from '../receivedInvitations/ReceivedInvitationsListComponent'

interface IinvitationSocket {
  channelName: string
  channelId: string
  status: number
}

const ReceivedInvitationsTableHeader = [
  'NO.',
  'SENT BY',
  'CHANNEL NAME',
  'PARTICIPANTS',
  'DATE',
  'ACTIONS'
]

const ChannelInvitationComponent = () => {
  const dispatch = useDispatch()
  const socket = useWebsocket()

  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )

  const receivedParticipantData = [
    {
      sentBy: 'Michael Smith',
      Channel: ' FinanceFocus',
      Participants: 3,
      date: '07/02/2024',
      Action: 'Accepted'
    },
    {
      sentBy: 'Jessica Williams',
      Channel: 'TechTalksLive',
      Participants: 5,
      date: '07/02/2024',
      Action: 'Rejected'
    },
    {
      sentBy: 'John Doe',
      Channel: 'CareerCatalyst',
      Participants: 2,
      date: '07/02/2024',
      Action: 'Pending'
    }
  ]

  const [search, setSearch] = useState('')
  const [invitationData, setInvitationData] = useState<
    IChannelInvitationAPIResData[]
  >([])
  const [paginationConfig, setPaginationConfig] = useState({
    search: '',
    limit: 10,
    page: 1,
    totalCount: 0
  })

  const getInvitation = () => {
    dispatch(setLoading(true))
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    }
    if (paginationConfig.search) {
      params.search = paginationConfig.search
    }
    ChannelInvitationAPI(params).then((res) => {
      if (res.status === 200) {
        setInvitationData(res.data?.data)
        setPaginationConfig({
          ...paginationConfig,
          totalCount: res?.data?.count
        })
      }
      dispatch(setLoading(false))
    })
  }

  // useEffect(() => {
  //   getInvitation();
  //   // eslint-disable-next-line
  // }, [paginationConfig.page, paginationConfig.search]);

  const handleSerach = (str: string) => {
    setPaginationConfig({ ...paginationConfig, search: str })
  }

  const setInvitationStateBySocket = (
    response: IinvitationSocket
  ) => {
    setInvitationData((prvState) => {
      let newState = prvState.map(
        (item: IChannelInvitationAPIResData) => {
          if (
            item?.invitationChannelId.channelName ===
            response?.channelName
          ) {
            return {
              ...item,
              status: response?.status,
              totalParticipate:
                response.status === 1
                  ? item.totalParticipate + 1
                  : item.totalParticipate
            }
          }
          return item
        }
      )
      return newState
    })
  }

  useEffect(() => {
    if (socket && user?.id) {
      // @todo - ask love what does this socket do.
      socket?.on(
        `invitation_approval_${user?.id}`,
        (response: IinvitationSocket) => {
          setInvitationStateBySocket(response)
        }
      )
      // this socket is to increase the participent counts in channel once admin aprove requests.
      socket?.on(
        `Channel_invitation_${user?.id}`,
        (response: any) => {
          setInvitationData((prvState) => {
            const newState = prvState.map(
              (item: IChannelInvitationAPIResData) => {
                if (
                  item?.invitationChannelId?.id ===
                  response?.channelId
                ) {
                  return {
                    ...item,
                    totalParticipate: response?.totalParticipants
                  }
                }
                return item
              }
            )
            return newState
          })
        }
      )
      socket?.on(
        `Channel_participants_create_${user?.id}`,
        (data) => {
          if (paginationConfig.page === 1) {
            getInvitation()
          } else {
            setPaginationConfig({ ...paginationConfig, page: 1 })
          }
        }
      )
      return () => {
        socket?.off(`Channel_participants_create_${user?.id}`)
      }
    } // eslint-disable-next-line
  }, [socket, user?.id])

  useDebouncedEffect(() => handleSerach(search), [search], 500)

  return (
    <div className="backdrop-block">
      <div className="channel-wrapper">
        <div className="title d-sm-flex justify-content-between">
          <h5 className="c-tx-primary f-600">Received Invitations</h5>
          <Input
            className="select-component"
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            type="email"
          />
        </div>
        <div className="tbb">
          <div className="ct-table">
            <Table className="position-relative">
              <thead className="table-sticky">
                <tr>
                  {ReceivedInvitationsTableHeader.map((data, i) => (
                    <th
                      className={`${
                        data === 'ACTIONS' && 'action-th'
                      }`}
                      key={i}
                    >
                      {data}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {receivedParticipantData.length > 0 ? (
                  receivedParticipantData.map(
                    (data: any, i: number) => (
                      <ReceivedInvitationsListComponent
                        paginationConfig={paginationConfig}
                        index={i}
                        data={data}
                        key={i}
                        getInvitation={getInvitation}
                      />
                    )
                  )
                ) : (
                  <NoFoundData
                    columnCount={
                      ReceivedInvitationsTableHeader.length
                    }
                  />
                )}
              </tbody>
            </Table>
          </div>
          <div className="pagination-wrap">
            <Row>
              <Col md="12" className="d-flex justify-content-end">
                <Pagination
                  pageSize={paginationConfig.limit}
                  onChange={(e: number) =>
                    setPaginationConfig({
                      ...paginationConfig,
                      page: e
                    })
                  }
                  current={paginationConfig.page}
                  total={paginationConfig.totalCount}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelInvitationComponent
