import { referenceTemplateAPI } from 'actions/referenceTemplateAPI'
import { useFormik } from 'formik'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import {
  setTemplateData,
  setTemplateModal
} from 'store/templateReducer/action'
import { getLocalStorage } from 'utils/commonFunctions'
import { TEMPLATE_KEY } from 'utils/constants'

interface IDynamicFiledRender {
  formikState: any
  validationSchema: any
  templateData: any
}

const DynamicFiledRender: FC<IDynamicFiledRender> = ({
  formikState,
  validationSchema,
  templateData
}) => {
  const oldValue = getLocalStorage('channelName')

  const channelDataVal = oldValue?.map((obj: any) => ({
    id: 'chl_qw6tc845zk',
    name: obj
  }))
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [channelField, setChannelField] = useState('')
  const [options, setOptions] = useState<any[]>(channelDataVal)
  const [validation, setValidation] = useState(false)

  const referenceTemplateAction = useCallback(() => {
    let data: {
      module: string
      search?: string
    } = { module: 'Channel' }
    if (search) {
      data.search = search
    }

    if (channelDataVal?.length > 0) {
      const newOption = channelDataVal?.map(
        (data: { id: any; name: any }) => {
          return { label: data.name, value: data.id }
        }
      )
      setOptions(newOption)
    }
  }, [search])

  const templateState = useFormik({
    initialValues: formikState,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(setTemplateData(values))
      dispatch(setTemplateModal('ReviewConfimationTemplateModal'))
    }
  })

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue
  } = templateState
  useDebouncedEffect(() => referenceTemplateAction(), [search], 500)

  const handleValidation = () => {
    if (values?.channelId) {
      setChannelField('ChannelId is required')
    }
    setValidation(true)
    handleSubmit()
  }

  const TemplateReducer = useSelector(
    (state: IRootReducer) => state.TemplateReducer
  )

  useEffect(() => {
    if (TemplateReducer?.modal?.IInstallationTemplateModal) {
      if (Object.keys(TemplateReducer?.data).length !== 0) {
        Object.keys(TemplateReducer?.data).forEach(function (key) {
          setFieldValue(key, TemplateReducer?.data[key])
        })
      }
    } // eslint-disable-next-line
  }, [TemplateReducer.data])
  return (
    <>
      <Form className="form-wrapper">
        <Row>
          {Object.entries(formikState).map(([key], i) => (
            <React.Fragment key={i}>
              {templateData?.fields?.[i]?.type === 'dynamic' && (
                <Col md="12" lg="12" xl="12" key={i}>
                  <FormGroup className="">
                    <Label for="firstname">
                      {templateData?.fields?.[i]?.title}{' '}
                      {templateData?.fields?.[i]?.required && (
                        <span className="text-danger">*</span>
                      )}
                    </Label>
                    <Select
                      onInputChange={(e) => setSearch(e)}
                      options={options}
                      value={values?.[key]}
                      className={
                        validation
                          ? errors?.[key]
                            ? 'onErrror css-1s2u09g-control1'
                            : ' '
                          : ' '
                      }
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: '10px'
                        })
                      }}
                      onChange={(e: any) => {
                        setFieldValue(key, e)
                        if (values.channelId) {
                          setChannelField('')
                        }
                      }}
                    />
                    <FormFeedback>
                      {channelField !== '' ? channelField : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              )}

              {(templateData?.fields?.[i].type ===
                TEMPLATE_KEY.text ||
                templateData?.fields?.[i].type ===
                  TEMPLATE_KEY.number ||
                templateData?.fields?.[i].type === 'boolean' ||
                templateData?.fields?.[i].type ===
                  TEMPLATE_KEY.spclAlphaNumeric) && (
                <Col md="12" lg="6" xl="6" key={i}>
                  <FormGroup className="">
                    <Label for={templateData?.fields?.[i]?.title}>
                      {templateData?.fields?.[i]?.title}{' '}
                      {templateData?.fields?.[i]?.required && (
                        <span className="text-danger">*</span>
                      )}
                    </Label>
                    <Input
                      id={templateData?.fields?.[i]?.title}
                      name={`${key}`}
                      onChange={handleChange}
                      placeholder={`${templateData?.fields?.[i]?.placeholder}`}
                      type={templateData?.fields?.[i]?.type}
                      value={values?.[key]}
                      invalid={Boolean(
                        validation ? errors?.[key] : false
                      )}
                    />
                    <FormFeedback>{errors?.[key]}</FormFeedback>
                  </FormGroup>
                </Col>
              )}
            </React.Fragment>
          ))}
          <Col md="12" lg="12" xl="12" className="text-end">
            <Button
              type="button"
              className="custom-primary ext-small"
              onClick={() => handleValidation()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DynamicFiledRender
