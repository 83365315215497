import { faStar, faStar as faStarEmpty, faStarHalfAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIAndApplicationFeaturesModel from "components/models/APIFeaturesModel";
import ConfirmationModal from "components/models/ConfirmationModal";
import { SubscribedApplicationContext } from "pages/main/apiList/ApiList";
// import APIAndApplicationFeaturesModel from "component/model/APIFeaturesModel";
// import ConfirmationModal from "component/model/ConfirmationModal";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
// import { applicationRating, defualtAppLogo } from "shared/constants/constants";
// import { numberFormatter, toastSuccess } from "shared/functions/commonFunctions";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { setConfirmationModalVisibility, setConfirmationModelId } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import { subscribeFromAPI, unsubscribeFromAPI } from "utils/common/helper/unSubscribe";
import { numberFormatter, toastSuccess } from "utils/commonFunctions";
import { applicationRating, defualtAppLogo } from "utils/constants";
import "../../styles/api/api.scss";

const Filledstar = <FontAwesomeIcon icon={faStar} />;
// eslint-disable-next-line
const Check = <FontAwesomeIcon icon={faCheck} />;
const halfStar = <FontAwesomeIcon icon={faStarHalfAlt} />;
const faStarEmptyIcon = <FontAwesomeIcon icon={faStarEmpty} />;

const ApiCommon = ({ subscribe, allAPIData, getAPIDetails }: any) => {
  // getting subscribed applications data from context which is created in top parent component
  const myApplications = useContext(SubscribedApplicationContext);

  // check wheather the current api in the allAPI listing is in the list of subscribed api or not
  // 1. loop through subscribed api's
  // 2. check if current api id is present in subscribed api list
  // 3. set the falg as per result
  // ANCHOR - findSubscribed

  // to find that if the current api is already subscribed or not
  // works in outer scope
  // due to some reasons it was not working for subscribeAPIToggle - will debug later.
  const thisAPIIsSubscribed = () => myApplications?.find((item: any) => item?.appId === allAPIData?.appId);
  const navigate = useNavigate();
  // if no api data found then return from here

  const { description, features, id, isAvailable, logo, logoIcon, name } = allAPIData;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [featureModelVisibility, setFeatureModelVisibility] = useState(false);
  const toggleFeatureModel = () => setFeatureModelVisibility(!featureModelVisibility);
  const dispatch = useDispatch();
  const subscibeAPIToggle = (apiId: number) => {
    // check if user is trying to subscribe or unsubscribe
    // 1. if current api data have .subscribed falg [in case it rendered the mySubscribed list]
    // 2. if its the listing of all api then
    //      - first fint if this api present in subscribed list,
    //      - if yes then unsubscribe it,
    //      - otherwise subscribe it,
    // ref - findSubscribed

    // eslint-disable-next-line eqeqeq
    let thisApiIsInSubscribedList = myApplications.find((item: any) => item.id === apiId);
    let subscribed = allAPIData.subscribed === 1 || thisApiIsInSubscribedList ? true : false;
    dispatch(setLoading(true));
    if (subscribed) {
      // unsubscribe
      unsubscribeFromAPI({ applicationId: apiId, subscribe: 0 })
        .then((response: any) => {
          toastSuccess(response?.message);
          // refetch api data
          getAPIDetails();
          dispatch(setConfirmationModalVisibility(false));
        })
        .catch(() => {
          dispatch(setConfirmationModalVisibility(false));
        });
    } else {
      // subscribe
      subscribeFromAPI({ applicationId: apiId, subscribe: 1 }).then((response: any) => {
        toastSuccess(response?.message);
        // refetch api listing data
        getAPIDetails();
      });
    }
  };

  const [imgError, setImgError] = useState(false);
  if (!allAPIData) return <div></div>;
  const handleImg = () => setImgError(true);
  return (
    <>
      <div className={`api-wrapper mb-4 ${isAvailable ? "" : "pe-none opacity-75"}`}>
        <div className={`custum-badge ${isAvailable ? "" : "bg-secondary"}`}>{isAvailable ? "Available" : "Inaccessible"}</div>
        <div className="title-wrapper align-items-center">
          <div className="block-1">
            <img src={imgError ? defualtAppLogo : logoIcon ? logoIcon : logo} alt="appLogo" className="img-fluid" onError={handleImg} />
          </div>
          {/* star rating */}
          <div className="block-2">
            <h3 className="c-tx-third f-600 mb-2">{name}</h3>
            <div className="d-sm-flex my-1">
              {isAvailable ? (
                <ul className="d-inline-sm-flex d-flex star-list">
                  <li>
                    <p className="small c-tx-third mb-0">{Filledstar}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0">{Filledstar}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0">{Filledstar}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0">{Filledstar}</p>
                  </li>
                  {applicationRating?.find((item) => item.id === id)?.rating === 5 ? (
                    <li>
                      <p className="small c-tx-third mb-0  icon-d">{Filledstar}</p>
                    </li>
                  ) : (
                    <li>
                      <p className="small c-tx-third mb-0  icon-d">{halfStar}</p>
                    </li>
                  )}
                </ul>
              ) : (
                <ul className="d-inline-sm-flex d-flex star-list">
                  <li>
                    <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                  </li>
                  <li>
                    <p className="small c-tx-third mb-0  icon-d">{faStarEmptyIcon}</p>
                  </li>
                </ul>
              )}
              <div className="inline-flex ms-sm-2 mt-2 mt-sm-0">
                <p className="small c-tx-third mb-0">
                  <p className="small c-tx-third mb-0">
                    ({isAvailable ? applicationRating.find((item) => item.id === id)?.reviewCount : 0}) | Subscribed User :{" "}
                    {isAvailable ? numberFormatter(applicationRating.find((item) => item.id === id)?.review || 2200, 1) : 0}
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content  paragrah-content">
          <p className="c-tx-ninth mb-0">
            {description?.slice(0, 130)}
            {description?.length > 130 ? "..." : ""}
          </p>
        </div>
        {/* This section will show all the api features list */}
        <div className="points-wrapper content mt-0">
          <p className="c-tx-third f-600 mb-2">Features</p>
          <ul className="point-list">
            {Array.isArray(features) &&
              features?.slice(0, 2)?.map((feature: string, index: number) => {
                return (
                  <li key={index}>
                    <div className="icon">
                      <p className="c-tx-tenth mb-0">{Check}</p>
                    </div>
                    <p className="c-tx-tenth mb-0">
                      {feature?.slice(0, 30)}
                      {feature?.length > 30 ? "..." : ""}
                    </p>
                  </li>
                );
              })}
            <li>
              <a
                className="mb-0 readmore"
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  setFeatureModelVisibility(true);
                }}>
                Read More Features{" "}
              </a>
            </li>
          </ul>
        </div>

        {/* If api is enable than show the action buttons other wise don't show anything */}
        <div className={`ct-btn-group btn-block pt-0  ${subscribe === "enable" ? "" : "pb-0"}`}>
          <Button className="custom-primary-outline" onClick={() => navigate(`/apis/apidetails/${allAPIData.id}`)}>
            Api Details{" "}
          </Button>
          <Button className="custom-primary-outline" onClick={() => navigate(`/apis/explorer/${allAPIData.id}`)}>
            Api Explorer
          </Button>
        </div>
        {subscribe === "enable" ? (
          <div className="ct-btn-group">
            <Button
              className={`w-100 custom-primary${allAPIData.subscribed === 1 || thisAPIIsSubscribed() ? "-outline" : ""}`}
              onClick={() => {
                toastSuccess("Coming Soon")
                // if user is subscribed then show him confirmation before unsubscribing
                // set confirmation id
                // when user click on yes then unsubscribe user

                // eslint-disable-next-line eqeqeq
                // let thisApiIsSubscribed = myApplications.find((item: any) => item.id === allAPIData?.id);
                // if (allAPIData.subscribed === 1 || thisApiIsSubscribed) {
                //   dispatch(setConfirmationModalVisibility(true));
                //   dispatch(setConfirmationModelId(allAPIData?.id));
                //   return;
                // }
                // // if user is already not subscribed
                // // subscribe
                // subscibeAPIToggle(allAPIData?.id);
              }}>
              {`${allAPIData.subscribed === 1 || thisAPIIsSubscribed() ? "UNSUBSCRIBE" : "SUBSCRIBE"}`}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* this model is being used to show api informations and features in detail */}
      <APIAndApplicationFeaturesModel
        name={name}
        description={description}
        id={id}
        isAvailable={isAvailable}
        logo={logo}
        features={features}
        featureModelVisibility={featureModelVisibility}
        toggleFeatureModel={toggleFeatureModel}
      />
      {/* unsubscribe action confirmation */}
      <ConfirmationModal handleOnClickAction={subscibeAPIToggle} title="Are you sure you want to unsubscribe" />
    </>
  );
};

export default ApiCommon;
