import React, { FC } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import { items } from "utils/constants";

let settings = {
  autoplay: true,
  interval: 6000,
};

interface ICarouselItemComponent {
  activeIndex: any;
  setActiveIndex: any;
}

const CarouselItemComponent: FC<ICarouselItemComponent> = ({ activeIndex, setActiveIndex }) => {
  const next = () => {
    if (false) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (false) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: React.SetStateAction<number>) => {
    if (false) return;
    setActiveIndex(newIndex);
  };
  return (
    <div className="pre-login-slider h-100 d-none d-md-flex align-items-end align-self-end">
      <Carousel activeIndex={activeIndex} next={next} previous={previous} className={`pb-5 active-slide-${activeIndex}`} {...settings}>
        {items.map((item, index) => {
          return (
            <CarouselItem key={index}>
              <div className="text-white" key={index}>
                <div className="font-20 fw-normal mb-4">
                  {index < 10 ? "0" : ""}
                  {index + 1} / {index < 10 ? "0" : ""}
                  {items.length}
                </div>
                <div className="font-36 fw-normal mb-4" dangerouslySetInnerHTML={{ __html: item.mainTitle }} />
                <div className="font-16 fw-normal" dangerouslySetInnerHTML={{ __html: item.subTitle }}></div>
              </div>
            </CarouselItem>
          );
        })}
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} className="justify-content-start m-0" />
      </Carousel>
    </div>
  );
};

export default CarouselItemComponent;
