import { Api } from "components/api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const SubscribedAPIList = ({ listingData, getAPIDetails }: any) => {
  const [subscribedAPIData, setallSubscribedData] = useState([]);

  const [toggle, setToggle] = useState(false);

  // load only three apps on first mound
  // if user click on load all then render all the apps to dom
  useEffect(() => {
    // slice to only 3 values
    setallSubscribedData(listingData?.slice(0, 3));
  }, [listingData]);

  useEffect(() => {
    setallSubscribedData(toggle ? listingData : listingData?.slice(0, 3));
  }, [listingData, toggle]);

  return (
    <>
      <div className="list-title">
        <Row className="align-items-center justify-content-center">
          <Col md="6" sm="12" xs="12">
            <div className="title">
              <h1 className="c-tx-primary mb-md-0 f-700">Your Subscribed API's</h1>
            </div>
          </Col>
          <Col md="6" sm="12" xs="12">
            <div className="d-flex justify-content-md-end  align-items-center">
              <Link
                to="#"
                onClick={(event) => {
                  event.preventDefault();
                  // setallSubscribedData(listingData);
                  setToggle(!toggle);
                }}
                className="custom-link">
                {" "}
                VIEW ALL ({listingData?.length} SUBSCRIBED)
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        {subscribedAPIData &&
          subscribedAPIData?.map((apiItem: any, index: number) => {
            return (
              <Col xl="4" lg="6" md="6" sm="6" xs="12" key={index}>
                {/* @ts-ignore */}
                <Api subscribe="disable" allAPIData={apiItem} key={index + 1} getAPIDetails={getAPIDetails} />
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default SubscribedAPIList;
