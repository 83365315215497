import { useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import FooterComponent from "../layout/Footer";
import HeaderComponent from "../layout/Header";
import MobileFooter from "./MobileFooter";
import { ComingRouteV6, protectedRouteV6 } from "./Routes";

export const ProtectedRouteComponent = () => {
  const location = useLocation();
  const GlobalSettingReducer = useSelector(
    (state) => state.GlobalSettingReducer
  );

  /**
   * @routes_rendring_logic
   * render routes from an array
   */
  const renderProtectedRoutes = useRoutes(protectedRouteV6);
  const renderComingRoutes = useRoutes(ComingRouteV6);
  return (
    <>
      {/* hide header if its a unauthorized page */}
      {location.pathname !== "/dialog" && <HeaderComponent />}
      {renderProtectedRoutes}
      {/* <div className="main-content-layout">
        {GlobalSettingReducer.comingSoon ? renderComingRoutes : renderProtectedRoutes}
        <MobileFooter />
      </div> */}
      {location.pathname !== "/dialog" && <FooterComponent />}
    </>
  );
};
