import {
  faChevronLeft,
  faGreaterThan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { IRootReducer } from 'store/root-reducer'
import { toastSuccess } from 'utils/commonFunctions'

const GreaterArrow = <FontAwesomeIcon icon={faGreaterThan} />
const backArrow = <FontAwesomeIcon icon={faChevronLeft} />

const SidebarComponent = () => {
  const { isUniverseSdebarActive } = useSelector(
    (state: IRootReducer) => state.universeSidebarReducer
  )
  const history = useLocation()
  const navigate = useNavigate()
  const arry = [
    { path: '/universe/overview' },
    { path: '/universe/metrics-monitoring' },
    { path: '/universe/cloud-configurations' },
    { path: '/universe/setting' },
    { path: '/universe/logs' }
  ]

  const handleRoutes = () => {
    const demo = arry.find((data) => data.path === history.pathname)
    return demo ? false : true
  }

  return (
    <>
      <div
        className={`sidebar-wrapper ${
          isUniverseSdebarActive ? 'active' : ''
        }`}
      >
        <div className="position-relative">
          {handleRoutes() ? (
            <>
              <div className="menutoggle">{GreaterArrow}</div>
              <div className="c-px-20">
                <h5 className="title">MAIN NAVIGATION</h5>
              </div>
              <ul className="page-link_sidebar">
                <li className="sidebar_menu_dashboard">
                  <NavLink
                    to="/universe/dashboard"
                    className="c-px-20"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <g
                          id="Group_255"
                          data-name="Group 255"
                          transform="translate(-22 -19)"
                        >
                          <path
                            id="Path_378"
                            data-name="Path 378"
                            d="M163.77,93.332a.438.438,0,0,0-.438.438v5.25a.438.438,0,0,0,.438.438h5.25a.438.438,0,0,0,.438-.438V93.77a.438.438,0,0,0-.438-.438Zm7.875,0a.438.438,0,0,0-.438.438v5.25a.438.438,0,0,0,.438.438h5.25a.438.438,0,0,0,.438-.438V93.77a.438.438,0,0,0-.438-.438Zm-.438,8.313a.438.438,0,0,1,.438-.438h5.25a.438.438,0,0,1,.438.438v5.25a.438.438,0,0,1-.438.438h-5.25a.438.438,0,0,1-.438-.438Zm-7.437-.438a.438.438,0,0,0-.438.438v5.25a.438.438,0,0,0,.438.438h5.25a.438.438,0,0,0,.438-.438v-5.25a.438.438,0,0,0-.438-.438Z"
                            transform="translate(-141.332 -74.332)"
                            fill="#fff"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="link-name">Dashboard</div>
                  </NavLink>
                </li>

                <li
                  className="sidebar_menu_channel_management"
                  // onClick={() => toastSuccess('Coming Soon')}
                >
                  <NavLink
                    className="c-px-20"
                    to="/universe/channel-management"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.937"
                        height="14"
                        viewBox="0 0 13.937 14"
                      >
                        <g
                          id="Group_255"
                          data-name="Group 255"
                          transform="translate(-22 -19)"
                        >
                          <path
                            id="Path_382"
                            data-name="Path 382"
                            d="M103.454,29.262a1.974,1.974,0,0,0-1.936,1.6,3.029,3.029,0,0,1-2.649-2.453,1.974,1.974,0,0,0-.05-3.872V23.123a1.974,1.974,0,1,0-.708,0v1.417a1.974,1.974,0,0,0-.052,3.872,3.034,3.034,0,0,1-2.652,2.453,1.976,1.976,0,1,0,0,.708,3.745,3.745,0,0,0,3.051-2.125,3.745,3.745,0,0,0,3.048,2.125,1.974,1.974,0,1,0,1.943-2.314Z"
                            transform="translate(-69.487 -0.207)"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="link-name">
                      Channel Management
                    </div>
                  </NavLink>
                </li>
                <li
                  className="sidebar_menu_template_contracts"
                  // onClick={() => toastSuccess("Coming Soon")}
                >
                  <NavLink
                    to="/universe/template-contact"
                    className="c-px-20"
                  >
                    {/* <NavLink to="universe" className="c-px-20"> */}
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <g
                          id="Group_255"
                          data-name="Group 255"
                          transform="translate(-22 -19)"
                        >
                          <g
                            id="Group_256"
                            data-name="Group 256"
                            transform="translate(-320.999 -254.001)"
                          >
                            <path
                              id="Path_383"
                              data-name="Path 383"
                              d="M356.3,273H343.7a.661.661,0,0,0-.7.7v2.66a.151.151,0,0,0,.14.14h13.72a.151.151,0,0,0,.14-.14V273.7a.661.661,0,0,0-.7-.7Z"
                              fill="#fff"
                            />
                            <path
                              id="Path_384"
                              data-name="Path 384"
                              d="M356.859,277.9h-8.82a.151.151,0,0,0-.14.14v8.82a.151.151,0,0,0,.14.14h8.26a.661.661,0,0,0,.7-.7v-8.26a.151.151,0,0,0-.14-.14Z"
                              fill="#fff"
                            />
                            <path
                              id="Path_385"
                              data-name="Path 385"
                              d="M346.359,277.9h-3.22a.151.151,0,0,0-.14.14v8.26a.661.661,0,0,0,.7.7h2.66a.151.151,0,0,0,.14-.14v-8.82a.151.151,0,0,0-.14-.14Z"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="link-name">
                      Templates & Smart Contracts
                    </div>
                  </NavLink>
                </li>
              </ul>
            </>
          ) : (
            <>
              <div className="d-flex">
                <div className="c-px-20">
                  <h5 className="title">PEER NODE</h5>
                </div>
                <div className="c-px-20">
                  <h5
                    style={{ paddingLeft: '95px' }}
                    className="title cursor-pointer"
                    onClick={() => navigate('/universe/blockchain')}
                  >
                    {backArrow} Back
                  </h5>
                </div>
              </div>
              <ul className="page-link_sidebar">
                <li>
                  <NavLink
                    to="/universe/overview"
                    className="c-px-20"
                  >
                    <div className="icon"></div>
                    <div className="link-name">Overview</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/universe/metrics-monitoring"
                    className="c-px-20"
                  >
                    <div className="icon"></div>
                    <div className="link-name">
                      Metrics & Monitoring
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/universe/cloud-configurations"
                    className="c-px-20"
                  >
                    <div className="icon"></div>
                    <div className="link-name">
                      Cloud Configurations
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/universe/setting" className="c-px-20">
                    <div className="icon"></div>
                    <div className="link-name">Settings</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/universe/logs" className="c-px-20">
                    <div className="icon"></div>
                    <div className="link-name">Logs</div>
                  </NavLink>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(SidebarComponent)
