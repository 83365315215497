import { faRedoAlt, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLogsApi } from 'actions/logsApi'
import { useState } from 'react'
import ReactAnsi from 'react-ansi'
import { useDispatch } from 'react-redux'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip
} from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { getDecryptedLocalStorage } from 'utils/commonFunctions'
import { localStorageKeys } from 'utils/constants'
import LogInput from './Loginput'
import { data } from '../../utils/logsData.js'
const LogsComponent = ({ isState, setUpdate }: any) => {
  const [state, setState] = useState<any>(data)
  const [reset, setReset] = useState(false)
  const [openModal, setOpenModal] = useState(true)

  const [number, setNumber] = useState(24)

  const logData = getDecryptedLocalStorage(
    localStorageKeys.universeLogs
  )
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 1000,
    page: 1,
    totalCount: 0
  })
  const dispatch = useDispatch()
  const user = getDecryptedLocalStorage(localStorageKeys.universeLogs)

  const getAPI = (params: any) => {
    getLogsApi(logData.id, params).then((res) => {
      if (res.status === 200) {
        // @ts-ignore
        setState([...state, ...res.data])
        setReset(false)
      }
      dispatch(setLoading(false))
    })
  }

  const loadMore = (num: number) => {
    setNumber(num)
    dispatch(setLoading(true))
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page,
      sinceSeconds: num ? num * 60 * 600 : 24 * 60 * 600
    }
    getAPI(params)
  }

  const resetLog = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    })
    setReset(true)
    setNumber(24)
    dispatch(setLoading(true))
    let params: any = {
      limit: paginationConfig.limit,
      page: 1,
      sinceSeconds: 24 * 60 * 600
    }
    getAPI(params)
  }

  const handlePagiation = () => {
    dispatch(setLoading(true))
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    })
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page + 1,
      sinceSeconds: number * 60 * 600
    }
    getAPI(params)
  }

  return (
    <Col md="12" lg="12" xl="8" xxl="8">
      <div className="backdrop-block blockchain-wrapper h-100 overflow-hidden">
        {/* <div className="title d-flex justify-content-between mb-20">
          <h5 className="c-tx-primary  f-600 mb-10">
            {user.name || ''}
          </h5>
          <div className="d-flex align-items-center justify-content-center">
            <LogInput loadMore={loadMore} reset={reset} />

            <Button color="primary" onClick={() => handlePagiation()}>
              Load More
            </Button>

            <UncontrolledTooltip
              placement="right"
              target="UncontrolledTooltipExample"
            >
              Reset
            </UncontrolledTooltip>
            <FontAwesomeIcon
              icon={faRedoAlt}
              className={'fw-bold ml-10 cursor-pointer'}
              id="UncontrolledTooltipExample"
              onClick={() => resetLog()}
            />
          </div>
        </div> */}
        <div>
          <Modal
            className="custom-popup-channel"
            size="xl"
            toggle={() => setUpdate(false)}
            isOpen={isState}
            centered
          >
            <ModalHeader fullWidth style={{ height: '40px' }}>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <span>Logs</span>
                </div>
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      setUpdate(false)
                      // setFirstModal(false)
                    }}
                    className="cursor-pointer c-green r-25 position-absolute"
                  />
                </div>
              </div>
            </ModalHeader>
            <ReactAnsi
              log={state && state.map((obj: any) => obj.content)}
              logStyle={{ height: 800, backgroundColor: 'black' }}
            />
          </Modal>
        </div>
      </div>
    </Col>
  )
}

export default LogsComponent
