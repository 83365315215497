import { IgetChannelListingAPIResData } from 'actions/channelListingAPI'
import { ParticipantsChannelTableAPI } from 'actions/ParticipantsChannelTableAPI'
import { NoFoundData } from 'components/notFoundPages'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Col, Input, Modal, Row, Table } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { IRootReducer } from 'store/root-reducer'
import { getLocalStorage } from 'utils/commonFunctions'
import { useWebsocket } from 'utils/WebSocketProvider'
import { CreateChannelModalComponent } from '../createChannel'
import ShareChannelComponent from '../createChannel/ShareChannelModalComponent'
const ChannelManagementTableHeader = [
  'NO.',
  'NAME',
  'PARTICIPANTS',
  'CHANNEL HEIGHT',
  'VIEW'
]
export interface IPaginationConfig {
  search: string
  page: number
  limit: number
}

const sharedDetail = ['NO.', 'CHANNEL NAME', 'PARTICIPANTS', 'STATUS']

const ParticipantsChannelTable = () => {
  const dispatch = useDispatch()
  const socket = useWebsocket()
  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )
  const oldValue = getLocalStorage('sharedChannel')

  const [search, setSearch] = useState('')
  const [totalCount, setTotalCount] = useState(0)

  const [channelData, setChannelData] = useState<any>(oldValue)
  const [viewModal, setViewModal] = useState<any>(false)
  const [viewData, setViewData] = useState<any>()
  const [paginationConfig, setPaginationConfig] = useState({
    search: '',
    page: 1,
    limit: 10
  })
  const [modal, setModal] = useState(false)

  const GetChannelListingAPI = useCallback(() => {
    dispatch(setLoading(true))
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    }
    if (paginationConfig.search) {
      params.search = paginationConfig.search
    }
    ParticipantsChannelTableAPI(params).then((res: any) => {
      if (res.status === 200) {
        setTotalCount(res?.data?.count)
        setChannelData(res?.data?.data)
      }
      dispatch(setLoading(false))
    })
  }, [
    dispatch,
    paginationConfig.limit,
    paginationConfig.page,
    paginationConfig.search
  ])

  // useEffect(() => {
  //   GetChannelListingAPI();
  //   // eslint-disable-next-line
  // }, [paginationConfig.page, paginationConfig.search]);

  // const handleSerach = useCallback(
  //   (str: string) => {
  //     setPaginationConfig({ ...paginationConfig, search: str });
  //   },
  //   [paginationConfig]
  // );

  // useEffect(() => {
  //   if (user?.id) {
  //     socket?.on(`Channel_shared_${user?.id}`, (data: any) => {
  //       if (paginationConfig.page === 1) {
  //         GetChannelListingAPI();
  //       } else {
  //         setPaginationConfig({ ...paginationConfig, page: 1 });
  //       }
  //     });
  //   } // eslint-disable-next-line
  // }, [user?.id]);

  // useDebouncedEffect(() => handleSerach(search), [search], 500);
  function getStatusColor(action: string) {
    switch (action) {
      case 'Pending':
        return 'orange'
      case 'Accepted':
        return 'green'
      case 'Rejected':
        return 'red'
      default:
        return 'black'
    }
  }

  function getStatus(value: any) {
    switch (value) {
      case 'John':
        return 'Pending'
      case 'Jessica':
        return 'Rejected'
      case 'Michael':
        return 'Accepted'
      default:
        return ''
    }
  }

  return (
    <div className="backdrop-block">
      <div className="channel-wrapper">
        <div className="title d-sm-flex justify-content-between">
          <h5 className="c-tx-primary f-600">Shared Channels</h5>
          <Input
            className="select-component"
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            type="email"
          />
        </div>
        <div className="tbb">
          <div className="ct-table">
            <Table className="position-relative">
              <thead className="table-sticky">
                <tr>
                  {ChannelManagementTableHeader.map(
                    (data: string, i: number) => (
                      <th key={i}>{data}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {channelData.length > 0 ? (
                  channelData.map((data: any, i: number) => (
                    <tr key={i}>
                      <td>
                        {paginationConfig.limit *
                          paginationConfig.page +
                          (i + 1) -
                          paginationConfig.limit}
                      </td>
                      <td>{data?.channelName}</td>
                      <td>{data?.participants?.length}</td>
                      <td>{data?.channelheight}</td>

                      <td>
                        <div className="btn-wrapper_block">
                          <>
                            <Button
                              className="custom-primary key mr-10"
                              onClick={() => {
                                setViewModal(true)
                                setViewData(data)
                                // setStatus("1");
                                // setInvitationmodal(!invitationmodal);
                              }}
                            >
                              View
                            </Button>
                          </>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoFoundData
                    columnCount={ChannelManagementTableHeader.length}
                  />
                )}
              </tbody>
            </Table>
          </div>
          <div className="pagination-wrap">
            <Row>
              <Col md="4">
                <Link
                  to="#"
                  className="custom-link"
                  onClick={() => setModal(!modal)}
                >
                  Share Channel
                </Link>
              </Col>
              <Col md="8" className="d-flex justify-content-end">
                <Pagination
                  pageSize={paginationConfig.limit}
                  onChange={(e: any) =>
                    setPaginationConfig({
                      ...paginationConfig,
                      page: e
                    })
                  }
                  current={paginationConfig.page}
                  total={totalCount}
                />
              </Col>
            </Row>
          </div>
        </div>
        <ShareChannelComponent modal={modal} setModal={setModal} />
      </div>

      <Modal
        isOpen={viewModal}
        className="custom-popup-channel"
        size="xs"
        centered
        toggle={() => {
          setViewModal(false)
        }}
        external={
          <Link
            to="#"
            className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
            onClick={() => {
              setViewModal(false)
            }}
          >
            CLOSE
          </Link>
        }
      >
        {' '}
        <div className="erc-template-wrapper w-100">
          <div className="title">
            <h3 className="c-tx-primary text-center f-700 mb-20">
              Shared Details
            </h3>
          </div>
          <div className="tbb">
            <div className="ct-table">
              <Table className="position-relative">
                <thead className="table-sticky">
                  <tr>
                    {sharedDetail.map((data: string, i: number) => (
                      <th key={i}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {viewData?.participants?.map(
                    (data: any, i: number) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{viewData?.channelName}</td>
                        <td>{data?.label}</td>
                        <td
                          style={{
                            color: getStatusColor(
                              getStatus(data?.value)
                            )
                          }}
                        >
                          {getStatus(data?.value)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default memo(ParticipantsChannelTable)
