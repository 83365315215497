import { faTimesCircle as close } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreateChannelAPI } from 'actions/channelCreateAPI'
import { getChannelListingAPI } from 'actions/channelListingAPI'
import { SendOTPAPI } from 'actions/sendOtpAPI'
import {
  IUserSearchAPI,
  IUserSearchAPIData,
  UserSearchAPI
} from 'actions/userSearchAPI'
import { useFormik } from 'formik'
import { useDebouncedEffect } from 'hooks/UseDebouncedEffect'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button, Form, FormGroup, Input, Modal } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import {
  getLocalStorage,
  handleTrim,
  toastSuccess
} from 'utils/commonFunctions'
import { makeFullS3ObjectUrl } from 'utils/makeFullS3ObjectUrl'

import * as Yup from 'yup'
import InvitationModalComponent from '../receivedInvitations/InvitationModalComponent'

const User = makeFullS3ObjectUrl('user-image.png')
const closeIcon = <FontAwesomeIcon icon={close} />

interface ICreateChannelModalComponent {
  modal: boolean
  setModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface IUserInterface {
  value: string
  label: string
}

const ShareChannelComponent: FC<ICreateChannelModalComponent> = ({
  setModal,
  modal
}) => {
  const valParticipant = [
    {
      label: ' John@gmail.com',
      value: 'John'
    },
    {
      label: 'Michael@gmail.com',
      value: 'Michael'
    },
    {
      label: 'Jessica@gmail.com',
      value: 'Jessica'
    }
  ]
  const [profile, setProfile] = useState<any>([])
  const [options, setOptions] = useState<any>(valParticipant)
  const oldValue = getLocalStorage('channelName')
  const channelCode = oldValue.map((obj) => ({
    label: obj,
    value: obj
  }))

  const [channelOptions, setChannelOptions] = useState<any>()
  const [errorData, setErrorData] = useState<any>('')

  const createChannelAPI = () => {
    if (channelOptions && profile?.length > 0) {
      const data = {
        channelName: channelOptions?.label,
        participants: profile,
        channelheight: 0
      }
      const oldValue = getLocalStorage('sharedChannel')
      if (oldValue?.length > 0) {
        const mergedValue = [...oldValue, data]
        localStorage.setItem(
          'sharedChannel',
          JSON.stringify(mergedValue)
        )
      } else {
        localStorage.setItem('sharedChannel', JSON.stringify([data]))
      }
      setModal(false)
      setProfile([])
      setChannelOptions({})
      toastSuccess('Channel shared successfully')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else {
      setErrorData('Please Select Options')
    }
    // Merge old and new values

    // Store merged value back in local storage
  }

  useEffect(() => {
    if (channelOptions?.label && profile?.length > 0) {
      setErrorData('')
    }
  }, [profile])

  const FomrikState = useFormik({
    initialValues: {
      channelName: '',
      participants: [],
      message: ''
    },
    validationSchema: Yup.object({
      channelName: Yup.string()
        .required('Channel Name is required')
        .matches(/^\S+$/, 'Channel Name not allow to space')
        .min(3, 'Channel Name must be at least 3 characters')
        .max(50, 'Channel Name must be at most 50 characters')
    }),
    onSubmit: (values) => createChannelAPI()
  })

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleBlur,
    touched
  } = FomrikState

  const handleSerach = (e: string) => {
    if (modal) {
      let params: {
        search?: string
        // module: string;
        limit: number
        page: number
      } = {
        limit: 20,
        page: 1
        // module: "Channel"
      }
      if (e) {
        params.search = e
      }
      UserSearchAPI(params).then((res: IUserSearchAPI) => {
        if (res.status === 200) {
          const newoption = res?.data?.data?.map(
            (data: IUserSearchAPIData) => {
              return {
                label: data?.name || data?.email,
                value: data?.userId
              }
            }
          )
          setOptions(newoption)
        }
      })
    }
  }

  // userSerach use debounc
  // useDebouncedEffect(() => handleSerach(search), [search], 500);

  // filter out the userId
  const handleUserId = useCallback(
    (data) => {
      const userIds = data.map((item: any) => item.value)
      setFieldValue('participants', userIds)
    },
    [setFieldValue]
  )

  const handleToggleModal = (reset?: boolean) => {
    setModal(!modal)
    setValidation({ isDublicate: false, isValidate: false })
    if (reset) {
      setProfile([])
      FomrikState.resetForm()
    }
  }
  const [validation, setValidation] = useState({
    isValidate: false,
    isDublicate: false
  })
  const handleChannelName = useCallback(
    (str: string) => {
      str &&
        getChannelListingAPI({ channelName: str, page: 1, limit: 10 })
          .then((res) => {
            if (res.status === 200) {
              setValidation({ ...validation, isDublicate: false })
            }
          })
          .catch((err) => {
            if (err?.data?.status === 409) {
              // setFieldError("channelName", err?.data?.message);
              setValidation({ ...validation, isDublicate: true })
            }
          })
    },
    [validation]
  )

  return (
    <>
      <Modal
        isOpen={modal}
        className="custom-popup-channel create-channel-pop"
        toggle={() => handleToggleModal(true)}
        style={{ maxWidth: '560px' }}
        centered
        external={
          <Link
            to="#"
            className="text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30 f-700"
            onClick={() => handleToggleModal(true)}
          >
            CLOSE
          </Link>
        }
      >
        <div className="custom-card-wrapper h-auto">
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">
              Share Channel
            </h5>
          </div>
          <Form>
            <FormGroup className="position-relative">
              <Select
                options={channelCode}
                // onInputChange={(e: string) => {
                //   setSearch(e)
                // }}

                value={channelOptions}
                onChange={(value) => {
                  setChannelOptions(value)
                }}
              />
              <p className="c-tx-ninth mb-20 small mt-2">
                Select Channel Name
              </p>
            </FormGroup>
            <FormGroup className="position-relative">
              <Select
                options={options}
                // onInputChange={(e: string) => {
                //   setSearch(e)
                // }}
                isMulti
                value={profile}
                onChange={(value) => {
                  setProfile(value)
                }}
              />
              <p className="c-tx-ninth mb-20 small mt-2">
                You can select multiple participants
              </p>
            </FormGroup>
            <FormGroup className="">
              <Input
                type="textarea"
                invalid={Boolean(errors?.message)}
                value={values.message}
                name="message"
                onChange={(e) =>
                  handleTrim(
                    e.target.name,
                    e.target.value,
                    setFieldValue
                  )
                }
                placeholder="Message (Optional)"
              />
              {errorData && (
                <span className="text-danger">{errorData}</span>
              )}
            </FormGroup>
            <Button
              type="button"
              onClick={createChannelAPI}
              className="custom-primary w-100"
            >
              Share
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default ShareChannelComponent
