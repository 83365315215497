import ConnectToChain from "components/dashboard/ConnectToChain";
import Documentation from "components/dashboard/Documentation";
import LogsComponent from "components/logs/LogsComponent";
import useComingSoonHook from "hooks/UseComingSoonHook";
import { Col, Row } from "reactstrap";
import "../../../styles/PeerNode/peerNode.scss";

const Logs = () => {
  useComingSoonHook();
  return (
    <div className="peer-node-overview-wrapper">
      <div className="page-title">
        <Row>
          <Col md="6">
            <h5 className="c-tx-primary f-700 mb-20">Logs</h5>
          </Col>
        </Row>
      </div>
      <Row className="mb-20">
        <LogsComponent />
        <Col md="12" lg="12" xl="4" xxl="4">
          <Row>
            <Col md="6" lg="6" xl="12" xxl="12">
              <ConnectToChain />
            </Col>
            <Col md="6" lg="6" xl="12" xxl="12">
              <Documentation />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Logs;
