import { chainCodeAPI } from 'actions/chainCodeAPI'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Button, Modal, ModalBody } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { IRootReducer } from 'store/root-reducer'
import {
  closeTemplateModal,
  setTemplateData,
  setTemplateModal,
  setTemplateSubmit
} from 'store/templateReducer/action'
import { toastSuccess } from 'utils/commonFunctions'
import Success from '../../assets/Images/check.png'
import Reject from '../../assets/Images/decline.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { LogsComponent } from 'components/logs'
import { ThankYou } from 'components/ThankyouPage'
const steps721: any = [
  {
    name: 'installchaincode-peer0-erc721test-1-n2tbr',
    status: Success
  },
  {
    name: 'installchaincode-peer1-erc721test-1-vns7d',
    status: Success
  },
  {
    name: 'approvechaincode-peer0-erc721test-1-1-xtgqj',
    status: Success
  },
  {
    name: 'approvechaincode-peer1-erc721test-1-1-pcnft',
    status: Success
  },
  { name: 'commitchaincode-erc721test-1-1-755t4', status: Success }
  // {
  //   name: 'invokechaincode-peer1-erc721test-1-dwtqn',
  //   status: Success
  // }
]
const ReviewConfirmationTemplateModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(
    (state: IRootReducer) => state.TemplateReducer.modal
  )
  const [openModal, setOpenModal] = useState(false)
  const [isState, setState] = useState(false)

  const [component, setComponent] = useState(false)
  const { data, templateGetData, id, submit } = useSelector(
    (state: IRootReducer) => state.TemplateReducer
  )
  const handleSubmit = useCallback(async () => {
    dispatch(setLoading(true))
    let newData = { ...data }
    await Object.keys({ ...data }).forEach((key) => {
      if (typeof newData[key] === 'object') {
        newData[key] = newData[key]?.value
      }
    })

    chainCodeAPI({
      ...newData,
      templateId: id
    }).then((res: any) => {
      if (res.status === 201) {
        toastSuccess(res?.message)
        dispatch(setTemplateModal('SuccessTemplateModal'))
        dispatch(setTemplateSubmit(false))
        dispatch(setTemplateData({}))
      }
      dispatch(setLoading(false))
    })
  }, [data, dispatch, id])

  useEffect(() => {
    if (submit) {
      handleSubmit()
    } //eslint-disable-next-line
  }, [submit])

  useEffect(() => {
    setTimeout(() => {
      if (component) {
        setComponent(false)
        setOpenModal(true)
      }
    }, 5000)
  }, [component])

  return (
    <>
      <Modal
        isOpen={modal.ReviewConfimationTemplateModal}
        className="custom-popup-channel"
        size="xs"
        centered
        external={
          <Link
            to="#"
            className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
            onClick={() => {
              dispatch(setTemplateData({}))
              dispatch(
                closeTemplateModal('ReviewConfimationTemplateModal')
              )
            }}
          >
            CLOSE
          </Link>
        }
      >
        {' '}
        <div className="erc-template-wrapper w-100">
          <div className="title">
            <h3 className="c-tx-primary text-center f-700 mb-20">
              Are you sure you want to submit ?
            </h3>
          </div>
          <div className="d-flex justify-content-center">
            <h5 className="mb-20 c-tx-primary f-600">
              {id !== 'template_vfrnzlvvhh'
                ? 'Template ERC20 '
                : 'Template ERC721'}
            </h5>
          </div>
          <div className="d-flex justify-content-center">
            <ul className="list-inforation" style={{ width: '55%' }}>
              <li>
                <div className="label_bl">
                  <p className="c-tx-primary f-700">Channel Name :</p>
                </div>
                <div className="ct_2">
                  <p className="c-tx-primary">
                    {data?.channelId?.label}
                  </p>
                </div>
              </li>
              <li>
                <div className="label_bl">
                  <p className="c-tx-primary f-700">Token Name :</p>
                </div>
                <div className="ct_2">
                  <p className="c-tx-primary">{data?.tokenName}</p>
                </div>
              </li>
              <li>
                <div className="label_bl">
                  <p className="c-tx-primary f-700">Token Symbol :</p>
                </div>
                <div className="ct_2">
                  <p className="c-tx-primary">{data?.tokenSymbol}</p>
                </div>
              </li>
              {data?.decimal && (
                <li>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">Decimal :</p>
                  </div>
                  <div className="ct_2">
                    <p className="c-tx-primary">{data?.decimal}</p>
                  </div>
                </li>
              )}
              {data?.tokenURI && (
                <li>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">Token URL :</p>
                  </div>
                  <div className="ct_2">
                    <p className="c-tx-primary">{data?.tokenURI}</p>
                  </div>
                </li>
              )}
              {data?.totalSupply && (
                <li>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">
                      Token Supply :
                    </p>
                  </div>
                  <div className="ct_2">
                    <p className="c-tx-primary">
                      {data?.totalSupply}
                    </p>
                  </div>
                </li>
              )}
            </ul>
          </div>
          {/* <p className="c-tx-ninth text-center mb-20">
            Would you like to submit ?
          </p> */}
          <div className="btn-wrapper text-center">
            {/* <Button
              className="custom-primary-outline me-3 mb-3 mb-sm-0 new-btn"
              onClick={() =>
                dispatch(setTemplateModal('ReviewTemplateModal'))
              }
            >
              YES, I WANTS TO REVIEW
            </Button> */}
            <Button
              className="custom-primary new-btn"
              onClick={() => {
                dispatch(setTemplateData({}))
                dispatch(
                  closeTemplateModal('ReviewConfimationTemplateModal')
                )
                setComponent(true)
                // toastSuccess('Installtion Successfull')
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      {component && (
        <Modal
          isOpen={component}
          className="custom-stepper-popup"
          size="xl"
          toggle={() => {
            setComponent(false)
          }}
          centered
          external={
            <Link
              to="/"
              className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
              onClick={(e) => {
                e.preventDefault()
                setComponent(false)
              }}
            >
              CLOSE
            </Link>
          }
        >
          <div
            style={{ backgroundColor: 'white', borderRadius: '10px' }}
          >
            <ThankYou />
          </div>
        </Modal>
      )}
      {/* stepper modal */}
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        centered
        size="md"
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setOpenModal(!openModal)}
              className="cursor-pointer c-green r-25 position-absolute"
            />
          </div>
          <h2 className="fw-600 font-18 color-black1 text-center mb-0">
            Steps for Smart Contracts
          </h2>
          <br />
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Logs</th>
                </tr>
              </thead>
              <tbody>
                {steps721?.map((step: any, index: any) => (
                  <tr key={index}>
                    <td>{step.name}</td>
                    <td>
                      <img
                        width={30}
                        height={30}
                        src={step.status}
                        alt="Status Gif"
                      />
                    </td>
                    <td>
                      <span
                        onClick={() => setState(true)}
                        style={{
                          cursor: 'pointer',
                          color: '#073d83'
                        }}
                      >
                        See The logs
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
      {isState && (
        <LogsComponent
          setFirstModal={setOpenModal}
          isState={isState}
          setUpdate={setState}
        />
      )}
    </>
  )
}

export default memo(ReviewConfirmationTemplateModal)
