import { getChannelListingAPI } from "actions/channelListingAPI";
import { getPeerListAPI } from "actions/peerListAPI";
import { getBlockProcessGraphApiInfo } from "actions/universeActions/myBlockChain/blockProcessAPI";
import { TitleComponent } from "components/titleComponent";
import useComingSoonHook from "hooks/UseComingSoonHook";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { setChannelDataToStoreInRedux } from "store/channelData/action";
import { setLoading } from "store/loader/action";
import { setLoadDiffOfCPUUsage, setLoadDiffOfMemoryUsage, setLoadDiffPeer, setLoadDiffTransaction, setPeerData, setSearchOfPeersListForDropdown } from "store/peerData/action";
import { IRootReducer } from "store/root-reducer";
import { toastError } from "utils/commonFunctions";
import "../../../../src/styles/PeerNode/peerNodeChart.scss";
import "../../../styles/pages/myblockchain/myblockchain.scss";
import { CertificateAuthoritiesListComponent } from "../certificateAuthorities";
import { CPUUsage, MemoryUsage, PeerNodeChart } from "../charts";
import TransactionCountGraph from "../charts/TransactionCount";
import { OrderManagementListComponent } from "../orderManagement";
import { PeerManagement } from "../peerManagement";

const MyBlockChain = () => {
  const [blockProcessGraphData, setBlockProcessGraphData] = useState([]);
  const [totalPeerCount, setTotalPeerCount] = useState(0);
  const [storePeerList, setStorePeerList] = useState<any>();
  const [storePeerId, setStorePeerId] = useState("");
  const [storeChannelId, setStoreChannelId] = useState("");
  const [increasePageNumber, setIncreasePageNumber] = useState(1);
  const [channelData, setChannelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchedValueOfPeer = useSelector((state: IRootReducer) => state.peerReducer.searchedPeer);
  const dispatch = useDispatch();

  const paginationConfig = {
    search: "",
    page: 1,
    limit: 10
  };

  useEffect(() => {
    if (getChannelDataFromRedux.length < 1) {
      let params: any = {
        limit: paginationConfig.limit,
        page: paginationConfig.page
      };
      if (paginationConfig.search) {
        params.search = paginationConfig.search;
      }
      dispatch(setLoading(true));
      getChannelListingAPI(params).then((res: any) => {
        if (res.status === 200) {
          setChannelData(res.data?.data);
          dispatch(setChannelDataToStoreInRedux(res.data?.data));
        }
        dispatch(setLoading(false));
      });
    }
    dispatch(setLoadDiffPeer(true));
  }, []);

  const [storeSelectedTimeAndItsValue, setStoreSelectedTimeAndItsValue] = useState<any>({});
  const [storeDropdownCHangedValue, setStoreDropdownCHangedValue] = useState<string>();

  const sendDropdownSelectionToMyBCComponent = (changedPeerVal: string) => {
    setStoreDropdownCHangedValue(changedPeerVal);
  };

  const sendSelectPastTimeFilterToMyBlockChain = (milliSecondsAsPerSelection: number, valueAsPerTimeSelection: number) => {
    setStoreSelectedTimeAndItsValue({
      milliSecondsAsPerSelection,
      valueAsPerTimeSelection,
      startTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000
    });
  };

  const getChannelDataFromRedux = useSelector((state: IRootReducer) => state?.channelDataReducer?.storeChannelData);

  const callFunctionTosendSelectPeerToMyBlockChain = (id: string) => {
    setStorePeerId(id);
  };

  const callFunctionTosendSelectChannelToMyBlockChain = (id: string) => {
    setStoreChannelId(id);
  };

  useEffect(() => {
    if (getChannelDataFromRedux?.length > 0 || channelData?.length > 0) {
      setIsLoading(true);
      let parameter: any = {};
      parameter = {
        storeSelectedTimeAndItsValue,
        storeChannelId: storeChannelId || (getChannelDataFromRedux?.length > 0 && getChannelDataFromRedux[0]?.id),
        storePeerId,
        storeDropdownCHangedValue
      };
      getBlockProcessGraphApiInfo(parameter)
        .then((response: any) => {
          setBlockProcessGraphData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [storeSelectedTimeAndItsValue, storeDropdownCHangedValue, getChannelDataFromRedux, storePeerId, storeChannelId]);

  const sendPeerListToGraphs = (peerListFromChildComp: any) => {
    setStorePeerList(peerListFromChildComp);
  };

  let params = { limit: 6, page: increasePageNumber };
  // useEffect(() => {
  //   if (totalPeerCount > params.page * params.limit) {
  //     setIncreasePageNumber((prev) => prev + 1);
  //   }
  //   getPeerListAPI(params, searchedValueOfPeer)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const newoption = res?.data?.data?.map((data: any) => {
  //           return { label: data?.name, value: data?.id };
  //         });
  //         dispatch(setSearchOfPeersListForDropdown(newoption));

  //         setTotalPeerCount(res?.data?.count);
  //         // @ts-ignore
  //         dispatch(setPeerData(res?.data?.data));
  //       }
  //     })
  //     .catch((err) => toastError(err));

  //   return () => {
  //     dispatch(setLoadDiffTransaction(false));
  //     dispatch(setLoadDiffOfCPUUsage(false));
  //     dispatch(setLoadDiffOfMemoryUsage(false));
  //   };
  // }, []);

  useComingSoonHook();

  return (
    <div className="my-block-chain-wrapper">
      <TitleComponent title="My BlockChain" />
      <Row>
        <Col md="6" lg="6" xl="6" xxl="4">
          <PeerManagement />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <OrderManagementListComponent />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <CertificateAuthoritiesListComponent />
        </Col>
      </Row>

      <TitleComponent title="Metrics & Monitoring" />
      <Row>
        <Col md="6" lg="6" xl="6" xxl="6">
          <PeerNodeChart
            blockProcessGraphData={blockProcessGraphData}
            nameOfGraph="block-process"
            sendSelectPastTimeFilterToMyBlockChain={sendSelectPastTimeFilterToMyBlockChain}
            callFunctionTosendSelectPeerToMyBlockChain={callFunctionTosendSelectPeerToMyBlockChain}
            callFunctionTosendSelectChannelToMyBlockChain={callFunctionTosendSelectChannelToMyBlockChain}
            sendDropdownSelectionToMyBCComponent={sendDropdownSelectionToMyBCComponent}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="6">
          <TransactionCountGraph />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="6">
          <CPUUsage />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="6">
          <MemoryUsage />
        </Col>
      </Row>
    </div>
  );
};

export default MyBlockChain;
