import { IgetTemplateListAPIData } from 'actions/getTemplateListAPI'
import BadgeBlock from 'components/badgeBlocks/BadgeBlock'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import {
  setTemplateIdModal,
  setTemplateModal
} from 'store/templateReducer/action'
import {
  handleUniverseStatus,
  toastSuccess
} from 'utils/commonFunctions'
import { makeFullS3ObjectUrl } from 'utils/makeFullS3ObjectUrl'
import '../../styles/template/template.scss'

const logo = makeFullS3ObjectUrl('edexa-logo.png')
interface ITemplateBlock {
  data: IgetTemplateListAPIData
  type: string
}

const TemplateBlock: FC<ITemplateBlock> = ({ data, type }) => {
  const dispatch = useDispatch()
  const [drp, setdrp] = useState(false)
  const toggle = () => {
    setdrp(!drp)
  }

  const handleClick = () => {
    dispatch(setTemplateIdModal(data.id))
    dispatch(setTemplateModal('IInstallationTemplateModal'))
  }

  return (
    <>
      <div
        className={`templateblock  ${
          (data.disable && 'op-down') ||
          (type === 'template' && 'cursor-pointer')
        }`}
        onClick={toggle}
      >
        <div className="block-1">
          <div className="badge-bl">
            <BadgeBlock name="Template" />
          </div>
          <img src={logo} className="img-fluid" alt="logo" />
        </div>
        <div className="block-2">
          <div className="bl_1 text-truncate">
            <h6 className="c-tx-primary f-700 mb-0 text-truncate">
              {data.name}
            </h6>
            <p className="c-tx-ninth">Tokenization</p>
          </div>
          <div className="bl_2">
            {type === 'chainCode' || (
              <div className="align-items-center">
                <Dropdown isOpen={drp} toggle={toggle}>
                  <DropdownToggle className="tlg" tag={'a'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="4"
                      viewBox="0 0 17 4"
                    >
                      <path
                        id="Path_330"
                        data-name="Path 330"
                        d="M8.624,6.781a1.953,1.953,0,0,0-1.906-2,1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2A1.953,1.953,0,0,0,8.624,6.781Zm4.689-2a1.953,1.953,0,0,0-1.906,2,1.908,1.908,0,1,0,3.811,0A1.953,1.953,0,0,0,13.313,4.781Zm-13.189,0a1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2,1.953,1.953,0,0,0,1.906-2A1.953,1.953,0,0,0,.124,4.781Z"
                        transform="translate(1.781 -4.781)"
                        fill="#868686"
                      />
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    {type === 'folder' && (
                      <DropdownItem
                        onClick={() => toastSuccess('Coming Soon')}
                      >
                        Remove
                      </DropdownItem>
                    )}{' '}
                    {type === 'template' && (
                      <>
                        <DropdownItem onClick={handleClick}>
                          Install
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateBlock
