import { IPaginationConfig } from 'pages/main/channelManagement/ChannelManagementTable'
import { CreateChannelModalComponent } from 'pages/main/createChannel'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

interface IPagination {
  paginationConfig: IPaginationConfig
  setPaginationConfig: React.Dispatch<
    React.SetStateAction<{
      search: string
      page: number
      limit: number
    }>
  >
  totalCount: number
}

const Pagination1: FC<IPagination> = ({
  paginationConfig,
  setPaginationConfig,
  totalCount
}) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <div className="pagination-wrap">
        <Row>
          <Col md="4">
            <Link
              to="#"
              className="custom-link"
              onClick={() => setModal(!modal)}
            >
              Create New Channel
            </Link>
          </Col>
          <Col md="8" className="d-flex justify-content-end">
            <Pagination
              pageSize={paginationConfig.limit}
              onChange={(e: any) =>
                setPaginationConfig({ ...paginationConfig, page: e })
              }
              current={paginationConfig.page}
              total={totalCount}
            />
          </Col>
        </Row>
        <CreateChannelModalComponent
          modal={modal}
          setModal={setModal}
        />
      </div>
    </>
  )
}

export default Pagination1
