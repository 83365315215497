import { IChannelInvitationAPIResData } from 'actions/channelInvitationAPI'
import { invitationAcceptedRejectedAPI } from 'actions/invitionRejectionAPI'
import { SendOTPAPI } from 'actions/sendOtpAPI'
import { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { getFormattedDate } from 'utils/commonFunctions'

import InvitationModalComponent from './InvitationModalComponent'

interface IReceivedInvitationsListComponent {
  data: any
  index: number
  paginationConfig: {
    search: string
    limit: number
    page: number
    totalCount: number
  }
  getInvitation: () => void
}

const ReceivedInvitationsListComponent: FC<
  IReceivedInvitationsListComponent
> = ({ data, index, paginationConfig, getInvitation }) => {
  const dispatch = useDispatch()

  const [invitationmodal, setInvitationmodal] = useState(false)
  const [statusData, seStatusData] = useState('')
  const ChannelInvitationAction = (status: string) => {
    dispatch(setLoading(true))
    invitationAcceptedRejectedAPI({
      id: data?.id,
      data: { status: status }
    }).then((res: any) => {
      if (res.status === 200) {
        seStatusData(status)
        // getInvitation();
        // setInvitationmodal(false);
        // toastSuccess(res.message);
        setInvitationmodal(!invitationmodal)
      }
      dispatch(setLoading(false))
    })
  }

  const handleResend = () => {
    dispatch(setLoading(true))
    SendOTPAPI(
      {
        id: data?.id,
        data: { status: statusData }
      },
      { requestType: 'Action', module: 'Channel' }
    ).then((res: any) => {
      dispatch(setLoading(false))
    })
  }

  function getStatusColor(action: string) {
    switch (action) {
      case 'Pending':
        return 'orange'
      case 'Accepted':
        return 'green'
      case 'Rejected':
        return 'red'
      default:
        return 'black' // or any default color you prefer
    }
  }

  const { limit, page } = paginationConfig
  return (
    <>
      <tr>
        <td>{limit * page + (index + 1) - limit}</td>
        <td>{data?.sentBy}</td>
        <td>{data?.Channel}</td>
        <td>{data?.Participants}</td>
        <td>{getFormattedDate(data?.date)}</td>
        <td style={{ color: getStatusColor(data?.Action) }}>
          {data?.Action}
          {/* <div className="btn-wrapper_block">
            {data?.status === 0 && (
              <>
                <Button
                  className="custom-primary key mr-10"
                  onClick={() => {
                    ChannelInvitationAction('1')
                    // setStatus("1");
                    // setInvitationmodal(!invitationmodal);
                  }}
                >
                  Accept
                </Button>
                <Button
                  className="custom-primary key"
                  onClick={() => {
                    ChannelInvitationAction('2')
                    // setStatus("2");
                    // setInvitationmodal(!invitationmodal);
                  }}
                >
                  Reject
                </Button>
              </>
            )}
            {data?.status === 1 && (
              <Button className="custom-primary key w-100">
                ACCEPTED
              </Button>
            )}
            {data?.status === 2 && (
              <Button className="custom-primary key w-100">
                Rejected
              </Button>
            )}
            {data?.status === 3 && (
              <Button className="custom-primary key w-100">
                In-Progress
              </Button>
            )}
          </div> */}
        </td>
      </tr>
      <InvitationModalComponent
        handleAction={getInvitation}
        handleResend={handleResend}
        invitationmodal={invitationmodal}
        setInvitationmodal={setInvitationmodal}
        requestName="Channel-update"
      />
    </>
  )
}

export default memo(ReceivedInvitationsListComponent)
