// @ts-nocheck
import spinnerImage from '../../assets/Images/spinner.svg'
const ThankYou = (props: any) => {
  return (
    <>
      <div className="p-4">
        <div className="user-management-popup confirm-popup-wrapper position-relative">
          <div className="content-wrapper flex-column ">
            <img src={spinnerImage} />
            <h1 className="h1 f-600">Thank you</h1>
            <p className="c-tx-ninth text-center mb-20 mt-20">
              We've received your request, chaincode installation is
              in progress. Please come back in few hours.
              <br /> mean time you can check our
              <a
                href=" https://edexa.network"
                target="_blank"
                rel="noreferrer"
                className="custom-light-link text-lowercase f-700 c-tx-secondary"
              >
                {' '}
                website
              </a>{' '}
              and
              <a
                href="https://edexa.network/terms-and-conditions"
                className="custom-light-link c-tx-secondary text-lowercase f-700"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                terms and conditions{' '}
              </a>
              to get verified.
            </p>
            <p className="c-tx-ninth text-center mb-20 mt-20">
              {' '}
              for further inquiries, please contact{' '}
              <a
                href="mailto:helpdesk@edexa.com?subject=Support Request"
                target="_blank"
                rel="noreferrer"
                className="custom-light-link c-tx-secondary text-lowercase f-700"
              >
                {' '}
                helpdesk@edexa.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default ThankYou
