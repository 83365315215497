/* eslint-disable react-hooks/exhaustive-deps */
import { getMemoryUsageGraphApiInfo } from "actions/universeActions/myBlockChain/blockProcessAPI";
// import { HighchartsReact } from 'highcharts-react-official'
// import Highcharts from 'highcharts/highstock'
import useComingSoonHook from "hooks/UseComingSoonHook";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Col, Row, Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { getDecryptedLocalStorage } from "utils/commonFunctions";
import "../../../../src/styles/pages/myblockchain/myblockchain.scss";
import "../../../styles/PeerNode/peerNodeChart.scss";
import {
  localStorageKeys,
  timingForMetricGraphArray,
} from "../../../utils/constants";

const MetricMonitoringMemoryUsage = () => {
  const logData = getDecryptedLocalStorage(localStorageKeys.universeLogs);
  const currentDateInMilliseconds = Date.now();

  const [storeSelectedTimeAndItsValue, setStoreSelectedTimeAndItsValue] =
    useState<any>({});
  const [memoryUsageGraphData, setMemoryUsageGraphData] = useState([]);
  const [selectPastTimeFilter, setSelectPastTimeFilter] = useState(
    timingForMetricGraphArray.length > 0 && timingForMetricGraphArray[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const getChannelDataFromRedux = useSelector(
    (state: IRootReducer) => state?.channelDataReducer?.storeChannelData
  );

  const sendSelectPastTimeFilterToMyBlockChain = (
    milliSecondsAsPerSelection: number,
    valueAsPerTimeSelection: number
  ) => {
    setStoreSelectedTimeAndItsValue({
      milliSecondsAsPerSelection,
      valueAsPerTimeSelection,
      startTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000,
    });
  };

  const callFunctionTosendSelectPastTimeFilterToMyBlockChain = (
    millisecondsOfSelectedTime: any
  ) => {
    let convertToNumberMillisecondsOfSelectedTime = parseInt(
      millisecondsOfSelectedTime
    );

    if (millisecondsOfSelectedTime === 300000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 900000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 1800000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        7
      );
    } else if (millisecondsOfSelectedTime === 3600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        14
      );
    } else if (millisecondsOfSelectedTime === 7200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        28
      );
    } else if (millisecondsOfSelectedTime === 86400000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        345
      );
    }
  };

  // const changeTheValueFormat = memoryUsageGraphData?.map((singleData: any) => {
  //   singleData.value = nFormatter(singleData.value, 1);
  //   return singleData;
  // });

  // // Format the data values
  // const formattedData = changeTheValueFormat.map((d) => {
  //   let value = parseInt(d.value);

  //   if (d.value.includes("M")) {
  //     value *= 1000000;
  //   } else if (d.value.includes("k")) {
  //     value *= 1000;
  //   } else if (d.value.includes("G")) {
  //     value *= 1000000000;
  //   }

  //   return {
  //     timestamp: moment(d.timestamp).format("hh:mm"),
  //     value: value,
  //     peerId: d.peerId
  //   };
  // });

  const maxValues =
    memoryUsageGraphData.flatMap((channel: any) =>
      channel?.values?.map((time: any) => new Date(time?.timestamp * 1000))
    )?.length || 0;

  const optionsData = {
    time: {
      useUTC: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    subtitle: {
      text: "This node memory (RAM) utilization over the past 24 hours.",
      align: "left",
      verticalAlign: "top",
      y: 0,
    },
    chart: {
      zoomType: "x",
    },
    xAxis: {
      type: "datetime",
      min: memoryUsageGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[0],
      max: memoryUsageGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[maxValues - 1],
      dateTimeLabelFormats: {
        millisecond: "%H:%M:%S",
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e of %b",
      },
      title: {
        text: "Timestamp",
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 4,
          },
        },
        marker: {
          enabled: false,
        },
      },
      series: {
        pointStart: memoryUsageGraphData.flatMap((channel: any) =>
          channel?.values?.map((time: any) => time?.timestamp * 1000)
        )?.[0],
        pointInterval: 14000,
      },
    },
    // tooltip: {
    //   formatter: function (this: Highcharts.TooltipFormatterContextObject) {
    //     const dataIndex = this?.point.index;
    //     const value: any = this?.y;
    //     const timestamp =
    //       new Date(this?.point?.x).toLocaleString() || "Unknown Time Stamp";
    //     const peerName =
    //       memoryUsageGraphData.flatMap((channel: any) =>
    //         channel?.values?.map((name: any) => name?.peerName)
    //       )?.[dataIndex] || "Unknown Peer Name";

    //     let tooltipContent = `<b>Value:</b> ${value}<br>`;
    //     tooltipContent += `<b>Peer Name:</b> ${peerName}<br>`;
    //     tooltipContent += `<b>Timestamp:</b> ${timestamp}<br>`;

    //     return tooltipContent;
    //   },
    // },
    series: [
      {
        type: "line",
        name: "",
        showInLegend: false,
        data:
          memoryUsageGraphData.length > 0
            ? memoryUsageGraphData
                .flatMap((data: any) =>
                  data?.values?.filter(
                    (peer: any) => peer?.peerName === logData?.name
                  )
                )
                .map((val: any) => parseInt(val?.value))
            : null,
      },
    ],
    navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };

  useEffect(() => {
    setIsLoading(true);
    getMemoryUsageGraphApiInfo(storeSelectedTimeAndItsValue, logData?.id)
      .then((response: any) => {
        setMemoryUsageGraphData(response?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, [storeSelectedTimeAndItsValue, getChannelDataFromRedux]);

  useComingSoonHook();
  return (
    <>
      <div
        className="backdrop-block"
        style={{ minHeight: "495px", maxHeight: "495px" }}
      >
        <>
          <Row>
            <div className="d-flex justify-content-between">
              <Col md="3">
                <div className="title">
                  <h6 className="c-tx-primary f-600 mb-10">
                    Memory &nbsp;
                    {isLoading && (
                      <div className="loader-center-metric-monitoring">
                        <Spinner
                          style={{
                            marginTop: "30%",
                            marginLeft: "60%",
                          }}
                          size={"sm"}
                        />
                      </div>
                    )}
                  </h6>
                </div>
              </Col>

              <Col md="2">
                <div className="title extra-css">
                  <Select
                    className="peer-drop-down"
                    options={timingForMetricGraphArray}
                    value={selectPastTimeFilter}
                    onChange={(e: any) => {
                      setIsLoading(true);
                      setSelectPastTimeFilter(e);
                      callFunctionTosendSelectPastTimeFilterToMyBlockChain(
                        e.value
                      );
                    }}
                  />
                </div>
              </Col>
            </div>
          </Row>
        </>

        {memoryUsageGraphData.length > 0 && !isLoading ? (
          <Row>
            <Col
              md="12"
              style={{
                visibility: `${isLoading ? "hidden" : "visible"}`,
              }}
            >
              <div className="hide-highcharts-tag">
                {/* <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsData}
                /> */}
              </div>
            </Col>
          </Row>
        ) : (
          memoryUsageGraphData.length === 0 &&
          !isLoading && (
            <p
              style={{
                marginTop: "20%",
                marginLeft: "40%",
              }}
            >
              NO DATA FOUND
            </p>
          )
        )}
      </div>
    </>
  );
};

export default MetricMonitoringMemoryUsage;
