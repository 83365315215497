import classnames from 'classnames'
import * as _crypto from 'crypto'
import { useFormik } from 'formik'
import './login.css'
import React, { useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import {
  faLock,
  faUnlock,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GoogleButtonLogin from './GoogleButtonLogin'
import MetaFunction from './MetaFunction'
import CarouselItemComponent from './CarouselItemComponent'
import {
  encryptString,
  toastError,
  toastSuccess
} from 'utils/commonFunctions'
import { onUserLoggedIn } from 'store/auth/action'
import { useDispatch } from 'react-redux'

const Login = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [socialTokenId, setSocialTokenId] = useState('')
  const [socialLoginRegisterEmail, setSocialLoginRegisterEmail] =
    useState('')

  const location: any = useLocation()
  const interval: any = useRef(null)
  const navigate = useNavigate()

  const setInitialView = () => {
    let view = 'login'
    if (location?.pathname.includes('register')) view = 'register'
    return view
  }

  const [activeTab, setActiveTab] = useState(setInitialView())
  const [imgError, setImgError] = useState(false)
  const [loginStep, setloginStep] = useState(1) // 1: login-cred, 2: step-Verify,  3:stop-verify, 4:try-another-way, 5: otp
  const [resendOTP, setResendOTP] = useState(false)
  const [authOption, setAuthOption] = useState('mailId')
  const [tryAnotherWay, setTryAnoterWay] = useState(false)
  const [getStartedActive, setgetStartedActive] = useState(false)
  const [qrSection, setqrSection] = useState(false)

  const [otpExpireTime, setOtpExpireTime] = useState({
    status: false,
    time: {
      seconds: 0,
      minutes: 0
    }
  })
  const [passwordType, setPasswordType] = useState({
    login: true,
    register: true
  })
  const [userInfo, setUserInfo] = useState({
    userId: '',
    email: '',
    profilePic: '',
    deviceName: '',
    deviceType: '',
    phone: '',
    deviceCategory: ''
  })

  const [visible, setVisible] = useState<any>(false)

  const dispatch = useDispatch()

  const toggle = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const loginForm = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: (values) => {
      if (!values?.email || !values?.password) {
        toastError('Email or Password is Missing')
      }
      if (values?.email && values?.password) {
        localStorage.setItem('loggedIn', 'true')
        localStorage.setItem('email', values?.email)
        dispatch(onUserLoggedIn())
        navigate('/dialog')
      }
    }
  })
  const { email, password } = loginForm.values
  const { handleChange, handleSubmit, errors } = loginForm

  const handleLogin = (values: any) => {
    let deviceName = ''
    let ua = navigator.userAgent.toLowerCase()
    let isAndroid = ua.indexOf('android') > -1
    let isIphone = ua.indexOf('iphone') > -1
    if (isAndroid) {
      deviceName = 'Android'
    } else if (isIphone) {
      deviceName = 'iPhone'
    } else {
      // @ToDo depricated features of navigator
      if (navigator.appVersion.indexOf('Win') !== -1)
        deviceName = 'Windows'
      if (navigator.appVersion.indexOf('Mac') !== -1)
        deviceName = 'MacOS'
      if (navigator.appVersion.indexOf('X11') !== -1)
        deviceName = 'UNIX'
      if (navigator.appVersion.indexOf('Linux') !== -1)
        deviceName = 'Linux'
    }
    values.deviceName = deviceName
    values.deviceType = '2'
    values.deviceToken = 'null'
    values.deviceId = ''
  }

  const responseGoogle = (response: any) => {
    if (response && response.profileObj) {
      localStorage.setItem('loggedIn', 'true')
      localStorage.setItem('email', response?.profileObj?.email)
      navigate('/dialog')
    }
  }

  return (
    <>
      <MetaFunction meta={'Login'} />
      {/* <div className="video-fx">
        <video autoPlay muted loop id="myVideo" className="w-100 h-100">
          <source src="https://edexa-global-beta.s3.ap-south-1.amazonaws.com/assets/edx-universe.mp4" type="video/mp4"></source>{" "}
        </video>
      </div> */}
      <div
        className={'d-flex align-items-center pre-login-page z-index'}
        style={{
          background: `url(https://account-files-bucket.s3.ap-south-1.amazonaws.com/banner/login.webp) center`
        }}
      >
        <header className="position-absolute top-0 left-0 w-100 pre-login-header z-index">
          <img
            src="https://account-files-bucket.s3.ap-south-1.amazonaws.com/accounts/assets/images/edexa.svg"
            alt=""
            className="mw-100 h-auto"
          />
        </header>
        <div
          className="pre-login-container d-flex align-items-center justify-content-center justify-content-md-end w-100"
          id="loginScreen"
        >
          <CarouselItemComponent
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <div className="login-signup-tabs bg-white rounded-10 overflow-hidden mw-100 main-form-section">
            {/* <Nav tabs>
              <NavItem className="w-50 text-center">
                <NavLink
                  className={classnames({
                    'cursor-pointer': true,
                    'py-3': true,
                    'color-black1': true,
                    'active-color-black1': true,
                    'font-16': true,
                    'bg-gray1': true,
                    'bg-white active': activeTab === 'login'
                  })}
                  onClick={() => {
                    toggle('login')
                  }}
                >
                  Login
                </NavLink>
              </NavItem>
              <NavItem className="w-50 text-center">
                <NavLink
                  className={classnames({
                    'cursor-pointer': true,
                    'py-3': true,
                    'color-black1': true,
                    'active-color-black1': true,
                    'font-16': true,
                    'bg-gray1': true,
                    'bg-white active': activeTab === 'register'
                  })}
                  onClick={() => {
                    setloginStep(1)
                    toggle('register')
                    setgetStartedActive(false)
                  }}
                >
                  Register
                </NavLink>
              </NavItem>
            </Nav> */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="login">
                {loginStep === 1 && (
                  <Row className="logo-mk">
                    <Col sm="12">
                      <div className="tab-header">
                        <div className="font-30 fw-bold color-black1 text-center mb-1 title">
                          Login
                        </div>
                        <p className="login-sub-title">
                          Continue with edeXa
                        </p>
                        {/* <Tooltip
                          isOpen={tooltipOpen}
                          placement="right"
                          target="TooltipExample"
                          toggle={() => {
                            setTooltipOpen(!tooltipOpen);
                          }}
                        >
                          Sample Tooltip Text...
                        </Tooltip> */}
                      </div>
                      <form className="tab-form">
                        <div className="form-group-wrap">
                          <InputGroup>
                            <Input
                              type="email"
                              placeholder="Email"
                              className="font-14 fw-normal color-black2 bg-white icon-right custom-input"
                              name="email"
                              value={email}
                              onChange={handleChange}
                              onKeyPress={(e) => {
                                e.key.toLowerCase() === 'enter' &&
                                  activeTab === 'login' &&
                                  handleSubmit()
                              }}
                              invalid={Boolean(
                                loginForm.touched.email &&
                                  errors.email
                              )}
                              style={{
                                borderTopRightRadius: '5px',
                                borderBottomRightRadius: '5px'
                              }}
                            />
                            <InputGroupText
                              style={{
                                position: 'absolute',
                                zIndex: '100',
                                right: '0',
                                height: '90%',
                                margin: '2px',
                                backgroundColor: 'transparent',
                                borderTop: 'transparent',
                                borderBottom: 'transparent',
                                borderRight: 'transparent'
                              }}
                            >
                              <FontAwesomeIcon icon={faUser} />
                            </InputGroupText>
                            <FormFeedback invalid={true}>
                              {loginForm.touched.email &&
                                errors.email}
                            </FormFeedback>
                          </InputGroup>
                          <InputGroup className="z-index">
                            <Input
                              name="password"
                              value={password}
                              type={visible ? 'text' : 'password'}
                              placeholder="Password"
                              className="font-14 fw-normal color-black2 bg-white icon-right custom-input"
                              onChange={handleChange}
                              onKeyPress={(e) => {
                                e.key.toLowerCase() === 'enter' &&
                                  activeTab === 'login' &&
                                  handleSubmit()
                              }}
                              invalid={Boolean(
                                loginForm.touched.password &&
                                  errors.password
                              )}
                              style={{
                                borderTopRightRadius: '5px',
                                borderBottomRightRadius: '5px'
                              }}
                            />
                            <InputGroupText
                              onClick={(e) => {
                                setVisible(!visible)
                                e.preventDefault()
                              }}
                              style={{
                                position: 'absolute',
                                cursor: 'pointer',
                                zIndex: '100',
                                right: '0',
                                height: '90%',
                                margin: '2px',
                                backgroundColor: 'transparent',
                                borderTop: 'transparent',
                                borderBottom: 'transparent',
                                borderRight: 'transparent'
                              }}
                            >
                              <FontAwesomeIcon
                                icon={visible ? faUnlock : faLock}
                              />
                            </InputGroupText>
                            <FormFeedback invalid={true}>
                              {loginForm.touched.password &&
                                errors.password}
                            </FormFeedback>
                          </InputGroup>
                        </div>
                        <Row className="mb-3 mt-3">
                          <Col xs="6">
                            <FormGroup
                              check
                              className="custom-checkbox d-flex"
                            >
                              <Label check className="d-flex">
                                <Input
                                  type="checkbox"
                                  className="me-2 font-20 mt-0"
                                />{' '}
                                <span className="login-sab-title-small">
                                  Remember Me
                                </span>
                              </Label>
                            </FormGroup>
                          </Col>
                          {/* <Col
                            xs="6"
                            className="text-right d-flex justify-content-end"
                          >
                            <Link
                              to="/forgot-password"
                              className="link-qr-code mt-1"
                            >
                              Forgot Password?
                            </Link>
                          </Col> */}
                        </Row>
                        <Button
                          className="font-14 w-100 py-2 h-50px color-white bg-blue blue-bg-btn"
                          type="button"
                          onClick={() => handleSubmit()}
                        >
                          Login
                        </Button>
                        <div className="separator text-center w-60 mx-auto d-flex justify-content-center my-4">
                          <span className="font-14 fw-normal color-light-grey d-inline-block bg-white px-4 position-relative">
                            Or
                          </span>
                        </div>

                        {/* Google Button Login Component Start  */}
                        <GoogleButtonLogin
                          responseGoogle={responseGoogle}
                          label="Login with Google"
                        />
                        {/* Google Button Login Component End  */}

                        {/* Google Modal Login Component Start  */}

                        {/* Google Modal Login Component Start  */}

                        {/* <p className="login-button-title">
                          Don’t have an account?{' '}
                          <Link
                            to="/"
                            className="link-qr-code mt-2"
                            onClick={() => {
                              toggle('register')
                            }}
                          >
                            Sign Up
                          </Link>
                        </p> */}
                        {/* <p className="font-14 color-light-grey text-center mt-2">
                          <Link
                            to="#"
                            className="link-qr-code"
                            onClick={() => {
                              setloginStep(12)
                              setqrSection(true)
                            }}
                          >
                            Login with QR Code
                          </Link>
                        </p> */}
                      </form>
                    </Col>
                  </Row>
                )}

                {loginStep === 3 && (
                  <Row className="logo-mk">
                    <Col sm="12">
                      <div className="tab-header text-center">
                        <div className="font-30 fw-bold color-black1 text-center mb-1 title">
                          Login
                        </div>
                        <div className="font-30 fw-bold color-black1 text-center steps-title mb-1">
                          Sign-in stopped
                        </div>
                        <p className="font-16 fw-normal color-black2 text-center email-account rounded-15 d-inline-flex align-items-center px-2 mb-50">
                          <i className="user d-inline-block me-1">
                            <img
                              src="https://account-files-bucket.s3.ap-south-1.amazonaws.com/banner/login.webp"
                              onError={() => setImgError(true)}
                              alt="invited-user"
                              className="mw-100 rounded-100"
                            />{' '}
                          </i>
                          {userInfo.email}
                        </p>
                      </div>
                      <form className="tab-form">
                        <div className="mb-80">
                          <p className="login-sab-title-small mt-2 mb-25">
                            Your login has been denied by the owner,
                            so we cannot let you in.
                          </p>
                        </div>
                        <Button
                          className="font-14 w-100 py-2 h-50px color-white bg-blue blue-bg-btn"
                          type="button"
                          onClick={() => setloginStep(1)}
                        >
                          Try Again
                        </Button>
                      </form>
                    </Col>
                  </Row>
                )}

                {loginStep === 2 && (
                  <Row className="logo-mk">
                    <Col sm="12">
                      <div className="tab-header text-center">
                        <div className="font-30 fw-bold color-black1 text-center mb-1">
                          2 Step Verification
                        </div>
                        <p className="login-sub-title">
                          This extra step shows that it's really you
                          trying to sign in
                        </p>
                      </div>
                      <div className="text-center custom-spacing">
                        <p className="login-sub-title email-account rounded-15 d-inline-flex align-items-center px-2 mb-50">
                          <i className="user d-inline-block me-1">
                            <img
                              src="https://account-files-bucket.s3.ap-south-1.amazonaws.com/banner/login.webp"
                              onError={() => setImgError(true)}
                              alt="invited-user"
                              className="mw-100"
                            />{' '}
                          </i>
                          {userInfo['email']}
                        </p>
                      </div>
                      <p className="font-18 fw-bold color-black1 text-center">
                        <span className="w-50px h-50px bg-gray1 d-inline-flex align-items-center justify-content-center me-3">
                          <i className="fas fa-mobile-alt color-light-grey"></i>
                        </span>
                        Check your{' '}
                        {tryAnotherWay
                          ? authOption === 'mailId'
                            ? 'Email'
                            : 'Phone'
                          : ''}{' '}
                        {tryAnotherWay
                          ? authOption === 'mailId'
                            ? userInfo.email
                            : userInfo.phone
                          : userInfo.deviceName}
                      </p>
                      <form className="tab-form">
                        <div className="mb-80">
                          <p className="login-sab-title-small mt-2 mb-25">
                            edeXa sent a notification to your &nbsp;
                            {tryAnotherWay
                              ? authOption === 'mailId'
                                ? userInfo.email
                                : userInfo.phone
                              : userInfo.deviceName}
                            . Tap{' '}
                            <strong className="color-black2">
                              yes
                            </strong>{' '}
                            on the notification to verify that it's
                            you.
                          </p>
                        </div>
                        <div className="d-flex justify-content-between footer-links">
                          {resendOTP ? (
                            <span
                              className="font-14 fw-bold color-blue cursor-pointer "
                              onClick={() => {}}
                            >
                              Resend it
                            </span>
                          ) : (
                            <span className="font-14 disable fw-bold color-blue cursor-pointer ">
                              Resend it ({otpExpireTime.time.minutes}{' '}
                              : {otpExpireTime.time.seconds}){' '}
                            </span>
                          )}
                          <button
                            className="font-14 fw-bold bg-white color-blue "
                            onClick={() => {
                              clearInterval(interval.current)
                              setOtpExpireTime({
                                status: false,
                                time: {
                                  seconds: 0,
                                  minutes: 0
                                }
                              })
                              setloginStep(4)
                            }}
                          >
                            Try another way
                          </button>
                        </div>
                      </form>
                    </Col>
                  </Row>
                )}

                {loginStep === 4 && (
                  <Row className="logo-mk">
                    <Col sm="12">
                      <div className="tab-header text-center">
                        <div className="font-30 fw-bold color-black1 text-center mb-1">
                          Verify its really you
                        </div>
                        <p className="login-sub-title">
                          Choose an option to which you wants to
                          receive notification. edeXa will send a
                          notification to your selected option. Tap{' '}
                          <b>yes its me</b> on the notification to
                          continue.
                        </p>
                      </div>
                      <form className="tab-form">
                        <InputGroup>
                          <FormGroup
                            check
                            className="custom-radio"
                            onClick={() => setAuthOption('mailId')}
                          >
                            <Label
                              check
                              className="position-relative font-14 fw-normal color-black2 d-flex align-items-center"
                            >
                              <Input
                                type="radio"
                                name="otp"
                                className="me-2 font-20 mt-0"
                                checked={authOption === 'mailId'}
                              />
                              <span className="outer-circle"></span>
                              {userInfo.email}
                            </Label>
                          </FormGroup>
                        </InputGroup>
                        <InputGroup>
                          <FormGroup
                            check
                            className="custom-radio"
                            onClick={() => setAuthOption('mobile')}
                          >
                            <Label
                              check
                              className="position-relative font-14 fw-normal color-black2 d-flex align-items-center"
                            >
                              <Input
                                type="radio"
                                name="otp"
                                className="me-2 font-20 mt-0"
                                checked={authOption === 'mobile'}
                              />
                              <span className="outer-circle"></span>
                              {userInfo.phone}
                            </Label>
                          </FormGroup>
                        </InputGroup>
                        <Button
                          className="font-14 w-100 py-2 h-50px color-white bg-blue blue-bg-btn mt-2 mb-30"
                          type="button"
                        >
                          Send
                        </Button>
                        <p className="login-sab-title-small mt-2">
                          Cannot access your email and mobile number?{' '}
                          <span className="d-block">
                            Contact&nbsp;
                            <a
                              href="mailto:helpdesk@edexa.com"
                              className="fw-bold color-blue active-color-black1"
                            >
                              Link
                            </a>
                          </span>
                        </p>
                      </form>
                    </Col>
                  </Row>
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
