import { getChannelListingAPI } from "actions/channelListingAPI";
import { getPeerListAPI } from "actions/peerListAPI";
import { getTransactionCountGraphApiInfo } from "actions/universeActions/myBlockChain/blockProcessAPI";
// import { HighchartsReact } from "highcharts-react-official";
// import Highcharts from "highcharts/highstock";
import useComingSoonHook from "hooks/UseComingSoonHook";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, Form, FormGroup, Row, Spinner } from "reactstrap";
import { setChannelDataToStoreInRedux } from "store/channelData/action";
import { setLoading } from "store/loader/action";
import {
  setLoadDiffOfCPUUsage,
  setLoadDiffOfMemoryUsage,
  setLoadDiffPeer,
  setLoadDiffTransaction,
  setPeerData,
  setSearchOfPeer,
  setSearchOfPeersListForDropdown,
} from "store/peerData/action";
import { IRootReducer } from "store/root-reducer";
import { toastError } from "utils/commonFunctions";
import "../../../../src/styles/pages/myblockchain/myblockchain.scss";
import { timingForGraphArray } from "../../../utils/constants";

const TransactionCountGraph = () => {
  const [totalPeerCount, setTotalPeerCount] = useState(0);
  const [increasePageNumber, setIncreasePageNumber] = useState(1);
  const [profile, setProfile] = useState<any>([]);
  const [storeChannelId, setStoreChannelId] = useState({});
  const [channelData, setChannelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [storeSelectedTimeAndItsValue, setStoreSelectedTimeAndItsValue] =
    useState<any>({});
  const [transactionCountGraphData, setTransactionCountGraphData] =
    useState<any>([]);
  const [selectPastTimeFilter, setSelectPastTimeFilter] = useState(
    timingForGraphArray.length > 0 && timingForGraphArray[0]
  );

  const options = useSelector(
    (state: IRootReducer) => state.peerReducer.searchedPeerListForDropdown
  );
  const peerListing = useSelector(
    (state: IRootReducer) => state.peerReducer.data
  );
  const searchedValueOfPeer = useSelector(
    (state: IRootReducer) => state.peerReducer.searchedPeer
  );
  const getChannelDataFromRedux = useSelector(
    (state: IRootReducer) => state?.channelDataReducer?.storeChannelData
  );
  const peerId = useSelector((state: IRootReducer) => state.peerReducer.peerId);

  const currentDateInMilliseconds = Date.now();
  const dispatch = useDispatch();

  const paginationConfig = {
    search: "",
    page: 1,
    limit: 10,
  };

  const sendSelectPastTimeFilterToMyBlockChain = (
    milliSecondsAsPerSelection: number,
    valueAsPerTimeSelection: number
  ) => {
    setStoreSelectedTimeAndItsValue({
      milliSecondsAsPerSelection,
      valueAsPerTimeSelection,
      startTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000,
    });
  };

  const callFunctionTosendSelectPastTimeFilterToMyBlockChain = (
    millisecondsOfSelectedTime: any
  ) => {
    let convertToNumberMillisecondsOfSelectedTime = parseInt(
      millisecondsOfSelectedTime
    );

    if (millisecondsOfSelectedTime === 300000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 900000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        3
      );
    } else if (millisecondsOfSelectedTime === 1800000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        7
      );
    } else if (millisecondsOfSelectedTime === 3600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        14
      );
    } else if (millisecondsOfSelectedTime === 7200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        28
      );
    } else if (millisecondsOfSelectedTime === 21600000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        86
      );
    } else if (millisecondsOfSelectedTime === 43200000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        172
      );
    } else if (millisecondsOfSelectedTime === 86400000) {
      sendSelectPastTimeFilterToMyBlockChain(
        (currentDateInMilliseconds -
          24 * 60 * 60 * 1000 -
          convertToNumberMillisecondsOfSelectedTime) /
          1000,
        345
      );
    }
  };

  let params = { limit: 6, page: increasePageNumber };

  useEffect(() => {
    setProfile({ label: options[0].label });
    if (getChannelDataFromRedux?.length > 0) {
      setStoreChannelId({
        label: getChannelDataFromRedux[0].channelName,
        value: getChannelDataFromRedux[0]?.id,
      });
    }
  }, [options]);

  useEffect(() => {
    if (getChannelDataFromRedux.length < 1) {
      let params: any = {
        limit: paginationConfig.limit,
        page: paginationConfig.page,
      };
      if (paginationConfig.search) {
        params.search = paginationConfig.search;
      }
      dispatch(setLoading(true));
      getChannelListingAPI(params).then((res: any) => {
        if (res.status === 200) {
          setChannelData(res.data?.data);
          dispatch(setChannelDataToStoreInRedux(res.data?.data));
        }
        dispatch(setLoading(false));
      });
    }
    dispatch(setLoadDiffPeer(true));
  }, []);

  useEffect(() => {
    if (
      (peerListing?.length > 0 && getChannelDataFromRedux.length > 0) ||
      channelData.length > 0
    ) {
      setIsLoading(true);
      let param: any = {};
      param = {
        storeSelectedTimeAndItsValue,
        storeChannelId: storeChannelId,
        storePeerId: profile.value,
        peerId,
      };
      getTransactionCountGraphApiInfo(param)
        .then((response: any) => {
          setTransactionCountGraphData(response?.data);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }
  }, [peerId, storeSelectedTimeAndItsValue, profile, storeChannelId]);

  // useEffect(() => {
  //   if (totalPeerCount > params.page * params.limit) {
  //     setIncreasePageNumber((prev) => prev + 1);
  //   }

  //   getPeerListAPI(params, searchedValueOfPeer)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const newoption = res?.data?.data?.map((data: any) => {
  //           return { label: data?.name, value: data?.id };
  //         });
  //         dispatch(setSearchOfPeersListForDropdown(newoption));
  //         setTotalPeerCount(res?.data?.count);
  //         // @ts-ignore
  //         dispatch(setPeerData(res?.data?.data));
  //       }
  //     })
  //     .catch((err) => toastError(err));

  //   return () => {
  //     dispatch(setLoadDiffTransaction(false));
  //     dispatch(setLoadDiffOfCPUUsage(false));
  //     dispatch(setLoadDiffOfMemoryUsage(false));
  //   };
  // }, []);

  useComingSoonHook();

  const uniquePeerNames = Array.from(
    new Set(
      transactionCountGraphData
        .map((obj: any) =>
          obj.values
            .filter((data: any) => data)
            .map((name: any) => name.peerName)
        )
        .flat()
    )
  );

  const maxValues =
    transactionCountGraphData.flatMap((channel: any) =>
      channel?.values?.map((time: any) => new Date(time?.timestamp * 1000))
    )?.length || 0;

  const optionsData = {
    time: {
      useUTC: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    chart: {
      zoomType: "x",
    },
    subtitle: {
      text:
        transactionCountGraphData?.length > 0
          ? getSubtitleTextTime(transactionCountGraphData)
          : null,
      align: "right",
      y: 10,
      style: {
        fontSize: "12px",
      },
    },
    xAxis: {
      type: "datetime",
      min: transactionCountGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[0],
      max: transactionCountGraphData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[maxValues - 1],
      dateTimeLabelFormats: {
        millisecond: "%H:%M:%S",
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e of %b",
      },
      title: {
        text: "Timestamp",
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 4,
          },
        },
        marker: {
          enabled: false,
        },
      },
      series: {
        pointStart: transactionCountGraphData.flatMap((channel: any) =>
          channel?.values?.map((time: any) => time?.timestamp * 1000)
        )?.[0],
        pointInterval: 14000,
      },
    },
    // tooltip: {
    //   formatter: function (this: Highcharts.TooltipFormatterContextObject) {
    //     const dataIndex = this?.point.index;
    //     const value: any = this?.y;
    //     const timestamp =
    //       new Date(this?.point?.x).toLocaleString() || "Unknown Time Stamp";
    //     const peerName =
    //       transactionCountGraphData.flatMap((channel: any) =>
    //         channel?.values?.map((name: any) => name.peerName)
    //       )?.[dataIndex] || "Unknown Peer Name";

    //     let tooltipContent = `<b>Value:</b> ${value}<br>`;
    //     tooltipContent += `<b>Peer Name:</b> ${peerName}<br>`;
    //     tooltipContent += `<b>Timestamp:</b> ${timestamp}<br>`;
    //     return tooltipContent;
    //   },
    // },
    series: uniquePeerNames.map((peerName) => ({
      type: "line",
      name: peerName,
      data:
        transactionCountGraphData.length > 0
          ? transactionCountGraphData
              .flatMap((data: any) =>
                data?.values?.filter((peer: any) => peer?.peerName === peerName)
              )
              .map((val: any) => val?.value)
          : null,
    })),
    navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };

  function getSubtitleTextTime(data: any) {
    const uniqueDates = Array.from(
      new Set(
        data.map((obj: any) => moment(obj.key * 1000).format("DD/MM/YYYY"))
      )
    );
    if (uniqueDates.length === 2) {
      return `Dates: ${uniqueDates[0]} and ${uniqueDates[1]}`;
    } else if (uniqueDates.length === 1) {
      return `Date: ${uniqueDates[0]}`;
    } else {
      return "Unknown Date";
    }
  }

  return (
    <>
      <div
        className="backdrop-block"
        style={{ minHeight: "495px", maxHeight: "495px" }}
      >
        <>
          <Row>
            <div className="d-flex">
              <Col md="3">
                <div className="title">
                  <h6 className="c-tx-primary f-600 mb-10">
                    Transaction Count &nbsp;
                    {isLoading && (
                      <div className="loader-center">
                        <Spinner
                          style={{
                            marginTop: "35%",
                            marginLeft: "50%",
                          }}
                          size={"sm"}
                        />
                      </div>
                    )}
                  </h6>
                </div>
              </Col>
              <Col md="2">
                <div className="title extra-css">
                  <Select
                    className="peer-drop-down"
                    //@ts-ignore
                    defaultValue={
                      timingForGraphArray?.length && {
                        label: timingForGraphArray[0].label,
                        value: timingForGraphArray[0].value,
                      }
                    }
                    value={selectPastTimeFilter}
                    options={timingForGraphArray}
                    onChange={(value: any) => {
                      setIsLoading(true);
                      setSelectPastTimeFilter(value);
                      callFunctionTosendSelectPastTimeFilterToMyBlockChain(
                        value.value
                      );
                    }}
                  />
                </div>
              </Col>
              <Col md="3" className="ml-10">
                <Form>
                  <FormGroup>
                    <Select
                      className="peer-drop-down"
                      options={options}
                      onInputChange={(e: string) => {
                        dispatch(setSearchOfPeer(e));
                      }}
                      value={profile}
                      onChange={(value) => {
                        setProfile(value);
                        setIsLoading(true);
                      }}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col md="3" className="ml-40">
                <Select
                  className="peer-drop-down"
                  defaultValue={
                    getChannelDataFromRedux?.length > 0 && {
                      label: getChannelDataFromRedux[0].channelName,
                      value: getChannelDataFromRedux[0]?.id,
                    }
                  }
                  value={storeChannelId}
                  options={getChannelDataFromRedux.map((single: any) => {
                    return {
                      label: single?.channelName,
                      value: single?.id,
                    };
                  })}
                  onChange={(e: any) => {
                    setStoreChannelId(e);
                    setIsLoading(true);
                  }}
                />
              </Col>
            </div>
          </Row>
        </>

        {transactionCountGraphData.length > 0 && !isLoading ? (
          <Row>
            <Col
              md="12"
              style={{
                visibility: `${isLoading ? "hidden" : "visible"}`,
              }}
            >
              <div className="hide-highcharts-tag">
                {/* <HighchartsReact highcharts={Highcharts} options={optionsData} /> */}
              </div>
            </Col>
          </Row>
        ) : (
          transactionCountGraphData.length === 0 &&
          !isLoading && (
            <p
              style={{
                marginTop: "20%",
                marginLeft: "40%",
              }}
            >
              NO DATA FOUND
            </p>
          )
        )}
      </div>
    </>
  );
};

export default TransactionCountGraph;
